@font-face {
  font-family: GT Walsheim Pro;
  src: local("GT Walsheim Pro Regular"), local("GTWalsheimProRegular"), url(../fonts/GTWalsheimProRegular.woff2) format("woff2"), url(../fonts/GTWalsheimProRegular.woff) format("woff"), url(../fonts/GTWalsheimProRegular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: GT Walsheim Pro;
  src: local("GT Walsheim Pro Bold"), local("GTWalsheimProBold"), url(../fonts/GTWalsheimProBold.woff2) format("woff2"), url(../fonts/GTWalsheimProBold.woff) format("woff"), url(../fonts/GTWalsheimProBold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
.bg-primary {
  background-color: #009ebb;
  background-color: #009ebb !important;
  color: #88edff;
}
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #007388;
}
a.bg-primary:hover {
  background-color: #007388 !important;
}
.bg-primary .text-muted {
  color: #55e5ff;
}
.bg-primary .text-darken {
  color: #007388;
}
.bg-primary.light {
  background-color: #00b8da !important;
}
.bg-primary.lighter {
  background-color: #00c9ee !important;
}
.bg-primary.dark {
  background-color: #00849c !important;
}
.bg-primary.darker {
  background-color: #007388 !important;
}
.bg-primary .open > a,
.bg-primary .open > a:hover,
.bg-primary .open > a:focus {
  color: #fff;
}
.bg-success {
  background-color: #3DA323;
  background-color: #3DA323 !important;
  color: #b4eca6;
}
a.bg-success:hover,
a.bg-success:focus {
  background-color: #2d791a;
}
a.bg-success:hover {
  background-color: #2d791a !important;
}
.bg-success .text-muted {
  color: #8aef70;
}
.bg-success .text-darken {
  color: #2d791a;
}
.bg-success.light {
  background-color: #46bc28 !important;
}
.bg-success.lighter {
  background-color: #4dcd2c !important;
}
.bg-success.dark {
  background-color: #348a1e !important;
}
.bg-success.darker {
  background-color: #2d791a !important;
}
.bg-success .open > a,
.bg-success .open > a:hover,
.bg-success .open > a:focus {
  color: #fff;
}
.bg-info {
  background-color: #33c3e0;
  background-color: #33c3e0 !important;
  color: #e4f7fb;
}
a.bg-info:hover,
a.bg-info:focus {
  background-color: #1ea7c2;
}
a.bg-info:hover {
  background-color: #1ea7c2 !important;
}
.bg-info .text-muted {
  color: #b2eefa;
}
.bg-info .text-darken {
  color: #1ea7c2;
}
.bg-info.light {
  background-color: #4ecbe4 !important;
}
.bg-info.lighter {
  background-color: #5fd0e7 !important;
}
.bg-info.dark {
  background-color: #20b6d4 !important;
}
.bg-info.darker {
  background-color: #1ea7c2 !important;
}
.bg-info .open > a,
.bg-info .open > a:hover,
.bg-info .open > a:focus {
  color: #fff;
}
.bg-warning {
  background-color: #FFB200;
  background-color: #FFB200 !important;
  color: #fff0cc;
}
a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #cc8e00;
}
a.bg-warning:hover {
  background-color: #cc8e00 !important;
}
.bg-warning .text-muted {
  color: #ffe099;
}
.bg-warning .text-darken {
  color: #cc8e00;
}
.bg-warning.light {
  background-color: #ffbb1f !important;
}
.bg-warning.lighter {
  background-color: #ffc133 !important;
}
.bg-warning.dark {
  background-color: #e09d00 !important;
}
.bg-warning.darker {
  background-color: #cc8e00 !important;
}
.bg-warning .open > a,
.bg-warning .open > a:hover,
.bg-warning .open > a:focus {
  color: #fff;
}
.bg-danger {
  background-color: #FF3932;
  background-color: #FF3932 !important;
  color: #fffefe;
}
a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #fe0900;
}
a.bg-danger:hover {
  background-color: #fe0900 !important;
}
.bg-danger .text-muted {
  color: #ffcdcb;
}
.bg-danger .text-darken {
  color: #fe0900;
}
.bg-danger.light {
  background-color: #ff5751 !important;
}
.bg-danger.lighter {
  background-color: #ff6a65 !important;
}
.bg-danger.dark {
  background-color: #ff1b13 !important;
}
.bg-danger.darker {
  background-color: #fe0900 !important;
}
.bg-danger .open > a,
.bg-danger .open > a:hover,
.bg-danger .open > a:focus {
  color: #fff;
}
.bg-dark {
  background-color: #414042;
  background-color: #414042 !important;
  color: #a7a6a8;
}
a.bg-dark:hover,
a.bg-dark:focus {
  background-color: #272728;
}
a.bg-dark:hover {
  background-color: #272728 !important;
}
.bg-dark .text-muted {
  color: #8d7ba0;
}
.bg-dark .text-darken {
  color: #272728;
}
.bg-dark.light {
  background-color: #504f52 !important;
}
.bg-dark.lighter {
  background-color: #5a595c !important;
}
.bg-dark.dark {
  background-color: #323132 !important;
}
.bg-dark.darker {
  background-color: #272728 !important;
}
.bg-dark .open > a,
.bg-dark .open > a:hover,
.bg-dark .open > a:focus {
  color: #fff;
}
.bg-light {
  background-color: #FAFAFA;
  color: #666;
}
a.bg-light:hover {
  background-color: #d3d5d7 !important;
}
.bg-light .text-muted {
  color: #999;
}
.bg-light .text-darken {
  color: #222;
}
.bg-light.light {
  background-color: #FEFEFE;
}
.bg-light.lighter {
  background-color: #FFF;
}
.bg-light.dark {
  background-color: #F2F2F2;
}
.bg-light.darker {
  background-color: #E7E7E7;
}
.bg-white {
  background-color: #FAFAFA;
  color: #666;
  background-color: #FFF !important;
}
a.bg-white:hover {
  background-color: #ededed !important;
}
.bg-white .text-muted {
  color: #999;
}
.bg-white .text-darken {
  color: #222;
}
.bg-white.light {
  background-color: #FEFEFE;
}
.bg-white.lighter {
  background-color: #FFF;
}
.bg-white.dark {
  background-color: #F2F2F2;
}
.bg-white.darker {
  background-color: #E7E7E7;
}
/* BADGES CONTEXTUALS
 * linked labels get darker on :hover
 */
.badge-default {
  background-color: #cfcfd0;
}
.badge-default[href]:hover,
.badge-default[href]:focus {
  background-color: #b5b5b7;
}
.badge-primary {
  background-color: #009ebb;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: #007388;
}
.badge-success {
  background-color: #3DA323;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: #2d791a;
}
.badge-info {
  background-color: #33c3e0;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: #1ea7c2;
}
.badge-warning {
  background-color: #FFB200;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: #cc8e00;
}
.badge-danger {
  background-color: #FF3932;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: #fe0900;
}
.input-box-shadow-inset {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
/*!
 * Bootstrap v3.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-default.disabled,
.btn-primary.disabled,
.btn-success.disabled,
.btn-info.disabled,
.btn-warning.disabled,
.btn-danger.disabled,
.btn-default[disabled],
.btn-primary[disabled],
.btn-success[disabled],
.btn-info[disabled],
.btn-warning[disabled],
.btn-danger[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-danger {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
  text-shadow: none;
}
.btn:active,
.btn.active {
  background-image: none;
}
.btn-default {
  background-image: -webkit-linear-gradient(top, #fff 0%, #e0e0e0 100%);
  background-image: -o-linear-gradient(top, #fff 0%, #e0e0e0 100%);
  background-image: linear-gradient(to bottom, #fff 0%, #e0e0e0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  text-shadow: 0 1px 0 #fff;
  border-color: #ccc;
}
.btn-default:hover,
.btn-default:focus {
  background-color: #e0e0e0;
  background-position: 0 -15px;
}
.btn-default:active,
.btn-default.active {
  background-color: #e0e0e0;
  border-color: #dbdbdb;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #e0e0e0;
  background-image: none;
}
.btn-primary {
  background-image: -webkit-linear-gradient(top, #009ebb 0%, #006a7e 100%);
  background-image: -o-linear-gradient(top, #009ebb 0%, #006a7e 100%);
  background-image: linear-gradient(to bottom, #009ebb 0%, #006a7e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff009ebb', endColorstr='#ff006a7e', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #006274;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #006a7e;
  background-position: 0 -15px;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #006a7e;
  border-color: #006274;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #006a7e;
  background-image: none;
}
.btn-success {
  background-image: -webkit-linear-gradient(top, #3DA323 0%, #2a7118 100%);
  background-image: -o-linear-gradient(top, #3DA323 0%, #2a7118 100%);
  background-image: linear-gradient(to bottom, #3DA323 0%, #2a7118 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3da323', endColorstr='#ff2a7118', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #276816;
}
.btn-success:hover,
.btn-success:focus {
  background-color: #2a7118;
  background-position: 0 -15px;
}
.btn-success:active,
.btn-success.active {
  background-color: #2a7118;
  border-color: #276816;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #2a7118;
  background-image: none;
}
.btn-info {
  background-image: -webkit-linear-gradient(top, #33c3e0 0%, #1c9fba 100%);
  background-image: -o-linear-gradient(top, #33c3e0 0%, #1c9fba 100%);
  background-image: linear-gradient(to bottom, #33c3e0 0%, #1c9fba 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff33c3e0', endColorstr='#ff1c9fba', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #1b98b1;
}
.btn-info:hover,
.btn-info:focus {
  background-color: #1c9fba;
  background-position: 0 -15px;
}
.btn-info:active,
.btn-info.active {
  background-color: #1c9fba;
  border-color: #1b98b1;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #1c9fba;
  background-image: none;
}
.btn-warning {
  background-image: -webkit-linear-gradient(top, #FFB200 0%, #c28700 100%);
  background-image: -o-linear-gradient(top, #FFB200 0%, #c28700 100%);
  background-image: linear-gradient(to bottom, #FFB200 0%, #c28700 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffb200', endColorstr='#ffc28700', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #b88000;
}
.btn-warning:hover,
.btn-warning:focus {
  background-color: #c28700;
  background-position: 0 -15px;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #c28700;
  border-color: #b88000;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #c28700;
  background-image: none;
}
.btn-danger {
  background-image: -webkit-linear-gradient(top, #FF3932 0%, #f40800 100%);
  background-image: -o-linear-gradient(top, #FF3932 0%, #f40800 100%);
  background-image: linear-gradient(to bottom, #FF3932 0%, #f40800 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff3932', endColorstr='#fff40800', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  border-color: #ea0800;
}
.btn-danger:hover,
.btn-danger:focus {
  background-color: #f40800;
  background-position: 0 -15px;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #f40800;
  border-color: #ea0800;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #f40800;
  background-image: none;
}
.thumbnail,
.img-thumbnail {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
  background-color: #e8e8e8;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-image: -webkit-linear-gradient(top, #009ebb 0%, #0088a2 100%);
  background-image: -o-linear-gradient(top, #009ebb 0%, #0088a2 100%);
  background-image: linear-gradient(to bottom, #009ebb 0%, #0088a2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff009ebb', endColorstr='#ff0088a2', GradientType=0);
  background-repeat: repeat-x;
  background-color: #0088a2;
}
.navbar-default {
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(top, #ffffff 0%, #f8f8f8 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f8f8f8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff8f8f8', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075);
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
  background-image: -o-linear-gradient(top, #dbdbdb 0%, #e2e2e2 100%);
  background-image: linear-gradient(to bottom, #dbdbdb 0%, #e2e2e2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdbdbdb', endColorstr='#ffe2e2e2', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.075);
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
.navbar-inverse {
  background-image: -webkit-linear-gradient(top, #3c3c3c 0%, #222 100%);
  background-image: -o-linear-gradient(top, #3c3c3c 0%, #222 100%);
  background-image: linear-gradient(to bottom, #3c3c3c 0%, #222 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border-radius: 4px;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: -o-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: linear-gradient(to bottom, #080808 0%, #0f0f0f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff080808', endColorstr='#ff0f0f0f', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-image: -webkit-linear-gradient(top, #009ebb 0%, #0088a2 100%);
    background-image: -o-linear-gradient(top, #009ebb 0%, #0088a2 100%);
    background-image: linear-gradient(to bottom, #009ebb 0%, #0088a2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff009ebb', endColorstr='#ff0088a2', GradientType=0);
    background-repeat: repeat-x;
  }
}
.alert {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.alert-success {
  background-image: -webkit-linear-gradient(top, #dff0d8 0%, #c8e5bc 100%);
  background-image: -o-linear-gradient(top, #dff0d8 0%, #c8e5bc 100%);
  background-image: linear-gradient(to bottom, #dff0d8 0%, #c8e5bc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffc8e5bc', GradientType=0);
  background-repeat: repeat-x;
  border-color: #b2dba1;
}
.alert-info {
  background-image: -webkit-linear-gradient(top, #d9edf7 0%, #b9def0 100%);
  background-image: -o-linear-gradient(top, #d9edf7 0%, #b9def0 100%);
  background-image: linear-gradient(to bottom, #d9edf7 0%, #b9def0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
  background-repeat: repeat-x;
  border-color: #9acfea;
}
.alert-warning {
  background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #f8efc0 100%);
  background-image: -o-linear-gradient(top, #fcf8e3 0%, #f8efc0 100%);
  background-image: linear-gradient(to bottom, #fcf8e3 0%, #f8efc0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fff8efc0', GradientType=0);
  background-repeat: repeat-x;
  border-color: #f5e79e;
}
.alert-danger {
  background-image: -webkit-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
  background-image: -o-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
  background-image: linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
  background-repeat: repeat-x;
  border-color: #dca7a7;
}
.progress {
  background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar {
  background-image: -webkit-linear-gradient(top, #009ebb 0%, #007388 100%);
  background-image: -o-linear-gradient(top, #009ebb 0%, #007388 100%);
  background-image: linear-gradient(to bottom, #009ebb 0%, #007388 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff009ebb', endColorstr='#ff007388', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-success {
  background-image: -webkit-linear-gradient(top, #3DA323 0%, #2d791a 100%);
  background-image: -o-linear-gradient(top, #3DA323 0%, #2d791a 100%);
  background-image: linear-gradient(to bottom, #3DA323 0%, #2d791a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3da323', endColorstr='#ff2d791a', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-info {
  background-image: -webkit-linear-gradient(top, #33c3e0 0%, #1ea7c2 100%);
  background-image: -o-linear-gradient(top, #33c3e0 0%, #1ea7c2 100%);
  background-image: linear-gradient(to bottom, #33c3e0 0%, #1ea7c2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff33c3e0', endColorstr='#ff1ea7c2', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-warning {
  background-image: -webkit-linear-gradient(top, #FFB200 0%, #cc8e00 100%);
  background-image: -o-linear-gradient(top, #FFB200 0%, #cc8e00 100%);
  background-image: linear-gradient(to bottom, #FFB200 0%, #cc8e00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffb200', endColorstr='#ffcc8e00', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-danger {
  background-image: -webkit-linear-gradient(top, #FF3932 0%, #fe0900 100%);
  background-image: -o-linear-gradient(top, #FF3932 0%, #fe0900 100%);
  background-image: linear-gradient(to bottom, #FF3932 0%, #fe0900 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff3932', endColorstr='#fffe0900', GradientType=0);
  background-repeat: repeat-x;
}
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.list-group {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 #007388;
  background-image: -webkit-linear-gradient(top, #009ebb 0%, #007e95 100%);
  background-image: -o-linear-gradient(top, #009ebb 0%, #007e95 100%);
  background-image: linear-gradient(to bottom, #009ebb 0%, #007e95 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff009ebb', endColorstr='#ff007e95', GradientType=0);
  background-repeat: repeat-x;
  border-color: #007e95;
}
.list-group-item.active .badge,
.list-group-item.active:hover .badge,
.list-group-item.active:focus .badge {
  text-shadow: none;
}
.panel {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.panel-default > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
}
.panel-primary > .panel-heading {
  background-image: -webkit-linear-gradient(top, #009ebb 0%, #0088a2 100%);
  background-image: -o-linear-gradient(top, #009ebb 0%, #0088a2 100%);
  background-image: linear-gradient(to bottom, #009ebb 0%, #0088a2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff009ebb', endColorstr='#ff0088a2', GradientType=0);
  background-repeat: repeat-x;
}
.panel-success > .panel-heading {
  background-image: -webkit-linear-gradient(top, #3DA323 0%, #358e1e 100%);
  background-image: -o-linear-gradient(top, #3DA323 0%, #358e1e 100%);
  background-image: linear-gradient(to bottom, #3DA323 0%, #358e1e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3da323', endColorstr='#ff358e1e', GradientType=0);
  background-repeat: repeat-x;
}
.panel-info > .panel-heading {
  background-image: -webkit-linear-gradient(top, #33c3e0 0%, #21bad9 100%);
  background-image: -o-linear-gradient(top, #33c3e0 0%, #21bad9 100%);
  background-image: linear-gradient(to bottom, #33c3e0 0%, #21bad9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff33c3e0', endColorstr='#ff21bad9', GradientType=0);
  background-repeat: repeat-x;
}
.panel-warning > .panel-heading {
  background-image: -webkit-linear-gradient(top, #FFB200 0%, #e6a000 100%);
  background-image: -o-linear-gradient(top, #FFB200 0%, #e6a000 100%);
  background-image: linear-gradient(to bottom, #FFB200 0%, #e6a000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffb200', endColorstr='#ffe6a000', GradientType=0);
  background-repeat: repeat-x;
}
.panel-danger > .panel-heading {
  background-image: -webkit-linear-gradient(top, #FF3932 0%, #ff2018 100%);
  background-image: -o-linear-gradient(top, #FF3932 0%, #ff2018 100%);
  background-image: linear-gradient(to bottom, #FF3932 0%, #ff2018 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff3932', endColorstr='#ffff2018', GradientType=0);
  background-repeat: repeat-x;
}
.well {
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
  border-color: #dcdcdc;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
}
html {
  min-height: 100%;
  position: relative;
}
body {
  color: #666;
}
body.admin-dashboard {
  background: url(../img/admin/bg-dark.png) repeat;
}
body.marketing {
  background: #e6e7e8;
  -ms-overflow-style: scrollbar;
}
.admin,
.marketing {
  margin-bottom: 180px;
}
.admin.support-page,
.marketing.support-page {
  height: auto;
}
.admin.support-page .workspace .main-content,
.marketing.support-page .workspace .main-content {
  margin: 80px 0 180px;
}
.admin .admin-content,
.marketing .admin-content,
.admin .marketing-content,
.marketing .marketing-content {
  padding: 80px 0 0 0;
  position: relative;
  z-index: 1003;
}
.admin colgroup col.meta,
.marketing colgroup col.meta {
  background-color: #e6e7e8;
}
.admin .btn-gray,
.marketing .btn-gray {
  color: #cfcfd0;
}
.admin .btn-disabled,
.marketing .btn-disabled {
  pointer-events: auto;
  cursor: default;
}
.admin a.btn.disabled,
.marketing a.btn.disabled {
  pointer-events: auto;
}
.m-top-10 {
  margin-top: 10px;
}
.m-bottom-10 {
  margin-bottom: 10px;
}
.m-left-neg-10 {
  margin-left: -10px;
}
.m-right-neg-10 {
  margin-right: -10px;
}
.m-left-10 {
  margin-left: 10px;
}
.m-right-10 {
  margin-right: 10px;
}
.m-top-15 {
  margin-top: 15px;
}
.m-bottom-15 {
  margin-bottom: 15px;
}
.m-left-neg-15 {
  margin-left: -15px;
}
.m-right-neg-15 {
  margin-right: -15px;
}
.m-left-15 {
  margin-left: 15px;
}
.m-right-15 {
  margin-right: 15px;
}
.m-top-20 {
  margin-top: 20px;
}
.m-bottom-20 {
  margin-bottom: 20px;
}
.m-left-neg-20 {
  margin-left: -20px;
}
.m-right-neg-20 {
  margin-right: -20px;
}
.m-left-20 {
  margin-left: 20px;
}
.m-right-20 {
  margin-right: 20px;
}
.m-top-25 {
  margin-top: 25px;
}
.m-bottom-25 {
  margin-bottom: 25px;
}
.m-left-neg-25 {
  margin-left: -25px;
}
.m-right-neg-25 {
  margin-right: -25px;
}
.m-left-25 {
  margin-left: 25px;
}
.m-right-25 {
  margin-right: 25px;
}
.m-top-30 {
  margin-top: 30px;
}
.m-bottom-30 {
  margin-bottom: 30px;
}
.m-left-neg-30 {
  margin-left: -30px;
}
.m-right-neg-30 {
  margin-right: -30px;
}
.m-left-30 {
  margin-left: 30px;
}
.m-right-30 {
  margin-right: 30px;
}
.m-top-35 {
  margin-top: 35px;
}
.m-bottom-35 {
  margin-bottom: 35px;
}
.m-left-neg-35 {
  margin-left: -35px;
}
.m-right-neg-35 {
  margin-right: -35px;
}
.m-left-35 {
  margin-left: 35px;
}
.m-right-35 {
  margin-right: 35px;
}
.m-top-40 {
  margin-top: 40px;
}
.m-bottom-40 {
  margin-bottom: 40px;
}
.m-left-neg-40 {
  margin-left: -40px;
}
.m-right-neg-40 {
  margin-right: -40px;
}
.m-left-40 {
  margin-left: 40px;
}
.m-right-40 {
  margin-right: 40px;
}
.m-top-1m {
  margin-top: 1em;
}
img.circle-cut {
  border-radius: 50%;
}
.marketing {
  margin-bottom: 0;
}
.tablet-up,
.desktop-only {
  display: none;
}
@media (min-width: 768px) {
  .tablet-up {
    display: block;
  }
}
@media (min-width: 992px) {
  .desktop-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #b1d133;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
.editor {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.editor .bold-font,
.editor b,
.editor strong {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.editor .light-font {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
}
.editor .editor-font {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.editor h1,
.editor h2,
.editor h3,
.editor h4,
.editor h5,
.editor h6,
.editor .h1,
.editor .h2,
.editor .h3,
.editor .h4,
.editor .h5,
.editor .h6 {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.3em;
  color: #44565e;
}
.editor h1.white,
.editor h2.white,
.editor h3.white,
.editor h4.white,
.editor h5.white,
.editor h6.white,
.editor .h1.white,
.editor .h2.white,
.editor .h3.white,
.editor .h4.white,
.editor .h5.white,
.editor .h6.white {
  color: #ffffff;
}
.admin .navbar .dropdown-menu,
.marketing .navbar .dropdown-menu {
  min-width: 0;
}
.admin .navbar .dropdown-menu > li,
.marketing .navbar .dropdown-menu > li {
  margin: 0 -30px;
  border-bottom: 1px solid #cfcfd0;
}
.admin .navbar .dropdown-menu > li > a,
.marketing .navbar .dropdown-menu > li > a {
  padding: 10px 30px;
}
.admin .navbar .dropdown-menu > li > a .icon,
.marketing .navbar .dropdown-menu > li > a .icon {
  color: #009ebb;
  font-size: 1.3em;
  margin-right: 0.3em;
}
.admin .navbar .dropdown-menu > li > a:hover .icon,
.marketing .navbar .dropdown-menu > li > a:hover .icon {
  color: #ffffff;
}
.admin .navbar .dropdown-menu > li.hilite,
.marketing .navbar .dropdown-menu > li.hilite {
  font-style: italic;
}
.admin .navbar .dropdown-menu .divider,
.marketing .navbar .dropdown-menu .divider {
  margin: 0.5em -30px;
  border-bottom: 0;
  height: 0;
}
.admin .navbar .dropdown-menu .form-submit,
.marketing .navbar .dropdown-menu .form-submit {
  margin: 30px -30px 0;
}
.admin .user.dropdown-menu,
.marketing .user.dropdown-menu {
  padding-bottom: 0;
}
.admin .login.dropdown-menu,
.marketing .login.dropdown-menu {
  padding-bottom: 0;
}
.admin .login.dropdown-menu > li,
.marketing .login.dropdown-menu > li {
  margin: 0;
}
@media (min-width: 768px) {
  .admin .navbar .dropdown-menu,
  .marketing .navbar .dropdown-menu {
    min-width: 338px;
    max-height: 476px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.admin .navbar,
.marketing .navbar {
  border-radius: 0;
  margin-bottom: 0;
  z-index: 1039;
}
.admin .navbar .navbar-header,
.marketing .navbar .navbar-header {
  position: relative;
  z-index: 2;
}
.admin .navbar .navbar-header .navbar-brand,
.marketing .navbar .navbar-header .navbar-brand {
  display: block;
  height: 60px;
  padding: 0;
}
.admin .navbar .navbar-header .navbar-brand img.logo,
.marketing .navbar .navbar-header .navbar-brand img.logo {
  height: 60px;
  margin: 0 5px 0 20px;
  padding: 16px 0;
  position: relative;
}
.admin .navbar .collapse,
.marketing .navbar .collapse {
  position: relative;
  z-index: 1;
}
.admin .navbar .navbar-collapse,
.marketing .navbar .navbar-collapse {
  border: none;
}
.admin .navbar .dropdown-menu header .title,
.marketing .navbar .dropdown-menu header .title {
  color: #ffffff;
}
.admin .navbar .navbar-nav,
.marketing .navbar .navbar-nav {
  margin-bottom: 0;
}
.admin .navbar .navbar-nav li > a,
.marketing .navbar .navbar-nav li > a {
  text-shadow: none;
}
.admin .navbar-inverse,
.marketing .navbar-inverse {
  background: #414042;
  /* old browsers */
  background: -webkit-linear-gradient(#414042, #343335);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(#414042, #343335);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border: none;
}
.admin .navbar-inverse .notice-bar,
.marketing .navbar-inverse .notice-bar {
  position: relative;
  padding: 10px;
  background-color: #ffc133;
  border-bottom: 3px solid #cc8e00;
  text-align: center;
}
.admin .navbar-inverse .notice-bar .btn-close-notice,
.marketing .navbar-inverse .notice-bar .btn-close-notice {
  position: absolute;
  right: 16px;
  top: 16px;
}
.admin .navbar-inverse .notice-bar .notice-title,
.marketing .navbar-inverse .notice-bar .notice-title {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
}
.admin .navbar-inverse .notice-bar > p,
.marketing .navbar-inverse .notice-bar > p {
  font-size: 1em;
}
.admin .navbar-inverse .notice-bar-environment,
.marketing .navbar-inverse .notice-bar-environment {
  position: relative;
  padding: 10px;
  background-color: #ff6a65;
  border-bottom: 3px solid #fe0900;
  text-align: center;
  color: #e6e7e8;
}
.admin .navbar-inverse .notice-bar-environment .btn-close-notice,
.marketing .navbar-inverse .notice-bar-environment .btn-close-notice {
  position: absolute;
  right: 16px;
  top: 16px;
}
.admin .navbar-inverse .notice-bar-environment .notice-title,
.marketing .navbar-inverse .notice-bar-environment .notice-title {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
}
.admin .navbar-inverse .notice-bar-environment > p,
.marketing .navbar-inverse .notice-bar-environment > p {
  font-size: 1em;
}
.admin .navbar-inverse .info-bar,
.marketing .navbar-inverse .info-bar {
  background: #44565e;
  color: #e6e7e8;
  font-size: 0.8em;
  height: 20px;
  letter-spacing: 0.3em;
  line-height: 20px;
  padding: 0 10px 0 20px;
}
.admin .navbar-inverse .info-bar .sys-message,
.marketing .navbar-inverse .info-bar .sys-message {
  float: left;
}
.admin .navbar-inverse .info-bar .login-message,
.marketing .navbar-inverse .info-bar .login-message {
  float: right;
}
.admin .navbar-inverse .info-bar .cta,
.marketing .navbar-inverse .info-bar .cta {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.admin .navbar-inverse .info-bar .cta a,
.marketing .navbar-inverse .info-bar .cta a,
.admin .navbar-inverse .info-bar .contact-phone a,
.marketing .navbar-inverse .info-bar .contact-phone a,
.admin .navbar-inverse .info-bar .contact-email a,
.marketing .navbar-inverse .info-bar .contact-email a {
  color: #e6e7e8;
  padding: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
}
.admin .navbar-inverse .info-bar .cta a:hover,
.marketing .navbar-inverse .info-bar .cta a:hover,
.admin .navbar-inverse .info-bar .contact-phone a:hover,
.marketing .navbar-inverse .info-bar .contact-phone a:hover,
.admin .navbar-inverse .info-bar .contact-email a:hover,
.marketing .navbar-inverse .info-bar .contact-email a:hover,
.admin .navbar-inverse .info-bar .cta a:active,
.marketing .navbar-inverse .info-bar .cta a:active,
.admin .navbar-inverse .info-bar .contact-phone a:active,
.marketing .navbar-inverse .info-bar .contact-phone a:active,
.admin .navbar-inverse .info-bar .contact-email a:active,
.marketing .navbar-inverse .info-bar .contact-email a:active {
  color: #ffffff;
}
.admin .navbar-inverse .info-bar .contact-phone a,
.marketing .navbar-inverse .info-bar .contact-phone a {
  border-right: 1px solid #e6e7e8;
}
@media (min-width: 768px) {
  .admin .navbar,
  .marketing .navbar {
    margin-bottom: 0;
  }
  .admin .navbar .navbar-nav,
  .marketing .navbar .navbar-nav {
    height: 60px;
  }
  .admin .navbar .navbar-nav.main-nav > li > a,
  .marketing .navbar .navbar-nav.main-nav > li > a {
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    background: none;
    border: none;
    border-left: 1px solid #333;
    box-shadow: none;
    color: #ffffff;
    font-size: 1em;
    letter-spacing: 0.2em;
    line-height: 60px;
    padding: 0 20px;
    height: 60px;
    position: relative;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
  .admin .navbar .navbar-nav.main-nav > li > a .icon,
  .marketing .navbar .navbar-nav.main-nav > li > a .icon {
    color: #fff;
    font-size: 1.1em;
    margin-right: 0.3em;
  }
  .admin .navbar .navbar-nav.main-nav > li.active,
  .marketing .navbar .navbar-nav.main-nav > li.active {
    border-bottom: 5px solid #b1d133;
  }
  .admin .navbar .navbar-nav.main-nav > li.active .icon,
  .marketing .navbar .navbar-nav.main-nav > li.active .icon {
    color: #b1d133;
  }
  .admin .navbar .navbar-nav.main-nav > li.mutate:hover,
  .marketing .navbar .navbar-nav.main-nav > li.mutate:hover {
    border-bottom: 5px solid #b1d133;
  }
  .admin .navbar .navbar-nav.main-nav > li.blue > a,
  .marketing .navbar .navbar-nav.main-nav > li.blue > a {
    color: #009ebb;
  }
  .admin .navbar .navbar-nav.nav-style-2 a span,
  .marketing .navbar .navbar-nav.nav-style-2 a span {
    position: relative;
    width: auto;
    display: inline-block;
  }
  .admin .navbar .navbar-nav.nav-style-2 a span:after,
  .marketing .navbar .navbar-nav.nav-style-2 a span:after {
    position: absolute;
    opacity: 0;
    top: 75%;
    left: 0;
    height: 4px;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    content: "";
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(10px);
  }
  .admin .navbar .navbar-nav.nav-style-2 li.active,
  .marketing .navbar .navbar-nav.nav-style-2 li.active {
    border-bottom: 0;
    height: auto;
  }
  .admin .navbar .navbar-nav.nav-style-2 li.active span:after,
  .marketing .navbar .navbar-nav.nav-style-2 li.active span:after,
  .admin .navbar .navbar-nav.nav-style-2 a:hover span:after,
  .marketing .navbar .navbar-nav.nav-style-2 a:hover span:after {
    background: #b1d133;
    opacity: 1;
    transform: translateY(0px);
  }
  .admin .navbar .navbar-nav.nav-style-2.blue > a,
  .marketing .navbar .navbar-nav.nav-style-2.blue > a {
    color: #009ebb;
  }
  .admin .navbar .navbar-nav .badge,
  .marketing .navbar .navbar-nav .badge {
    background-color: #009ebb;
    font-size: 1em;
    letter-spacing: normal;
    text-shadow: none;
  }
  .admin .navbar .navbar-collapse > .navbar-nav,
  .marketing .navbar .navbar-collapse > .navbar-nav {
    margin-left: 2em;
  }
  .admin .navbar .navbar-right,
  .marketing .navbar .navbar-right {
    margin: 0;
    position: relative;
  }
  .admin .navbar .user-nav,
  .marketing .navbar .user-nav {
    background-color: #009ebb;
  }
  .admin .navbar .user-nav .user-menu,
  .marketing .navbar .user-nav .user-menu {
    color: #ffffff;
    background-color: #009ebb;
    border-color: transparent;
    box-shadow: none !important;
    height: 60px;
    line-height: 60px;
    outline: none;
    padding: 0;
    text-align: center;
    width: 60px;
    -webkit-transition: background-color 0.2s ease;
    -o-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
  }
  .admin .navbar .user-nav .user-menu:focus,
  .marketing .navbar .user-nav .user-menu:focus,
  .admin .navbar .user-nav .user-menu.focus,
  .marketing .navbar .user-nav .user-menu.focus {
    color: #ffffff;
    background-color: #007388;
    border-color: rgba(0, 0, 0, 0);
  }
  .admin .navbar .user-nav .user-menu:hover,
  .marketing .navbar .user-nav .user-menu:hover {
    color: #ffffff;
    background-color: #007388;
    border-color: rgba(0, 0, 0, 0);
  }
  .admin .navbar .user-nav .user-menu:active,
  .marketing .navbar .user-nav .user-menu:active,
  .admin .navbar .user-nav .user-menu.active,
  .marketing .navbar .user-nav .user-menu.active,
  .open > .dropdown-toggle.admin .navbar .user-nav .user-menu,
  .open > .dropdown-toggle.marketing .navbar .user-nav .user-menu {
    color: #ffffff;
    background-color: #007388;
    background-image: none;
    border-color: rgba(0, 0, 0, 0);
  }
  .admin .navbar .user-nav .user-menu:active:hover,
  .marketing .navbar .user-nav .user-menu:active:hover,
  .admin .navbar .user-nav .user-menu.active:hover,
  .marketing .navbar .user-nav .user-menu.active:hover,
  .open > .dropdown-toggle.admin .navbar .user-nav .user-menu:hover,
  .open > .dropdown-toggle.marketing .navbar .user-nav .user-menu:hover,
  .admin .navbar .user-nav .user-menu:active:focus,
  .marketing .navbar .user-nav .user-menu:active:focus,
  .admin .navbar .user-nav .user-menu.active:focus,
  .marketing .navbar .user-nav .user-menu.active:focus,
  .open > .dropdown-toggle.admin .navbar .user-nav .user-menu:focus,
  .open > .dropdown-toggle.marketing .navbar .user-nav .user-menu:focus,
  .admin .navbar .user-nav .user-menu:active.focus,
  .marketing .navbar .user-nav .user-menu:active.focus,
  .admin .navbar .user-nav .user-menu.active.focus,
  .marketing .navbar .user-nav .user-menu.active.focus,
  .open > .dropdown-toggle.admin .navbar .user-nav .user-menu.focus,
  .open > .dropdown-toggle.marketing .navbar .user-nav .user-menu.focus {
    color: #ffffff;
    background-color: #005564;
    border-color: rgba(0, 0, 0, 0);
  }
  .admin .navbar .user-nav .user-menu.disabled:hover,
  .marketing .navbar .user-nav .user-menu.disabled:hover,
  .admin .navbar .user-nav .user-menu[disabled]:hover,
  .marketing .navbar .user-nav .user-menu[disabled]:hover,
  fieldset[disabled] .admin .navbar .user-nav .user-menu:hover,
  fieldset[disabled] .marketing .navbar .user-nav .user-menu:hover,
  .admin .navbar .user-nav .user-menu.disabled:focus,
  .marketing .navbar .user-nav .user-menu.disabled:focus,
  .admin .navbar .user-nav .user-menu[disabled]:focus,
  .marketing .navbar .user-nav .user-menu[disabled]:focus,
  fieldset[disabled] .admin .navbar .user-nav .user-menu:focus,
  fieldset[disabled] .marketing .navbar .user-nav .user-menu:focus,
  .admin .navbar .user-nav .user-menu.disabled.focus,
  .marketing .navbar .user-nav .user-menu.disabled.focus,
  .admin .navbar .user-nav .user-menu[disabled].focus,
  .marketing .navbar .user-nav .user-menu[disabled].focus,
  fieldset[disabled] .admin .navbar .user-nav .user-menu.focus,
  fieldset[disabled] .marketing .navbar .user-nav .user-menu.focus {
    background-color: #009ebb;
    border-color: transparent;
  }
  .admin .navbar .user-nav .user-menu .badge,
  .marketing .navbar .user-nav .user-menu .badge {
    color: #009ebb;
    background-color: #ffffff;
  }
  .admin .navbar .user-nav .user-menu:focus,
  .marketing .navbar .user-nav .user-menu:focus,
  .admin .navbar .user-nav .user-menu.focus,
  .marketing .navbar .user-nav .user-menu.focus {
    background-color: #33c3e0;
  }
  .admin .navbar .user-nav .user-menu:hover,
  .marketing .navbar .user-nav .user-menu:hover {
    background-color: #33c3e0;
  }
  .admin .navbar .user-nav .user-menu:active,
  .marketing .navbar .user-nav .user-menu:active,
  .admin .navbar .user-nav .user-menu.active,
  .marketing .navbar .user-nav .user-menu.active {
    background-color: #33c3e0;
  }
  .admin .navbar .user-nav .user-menu:active:hover,
  .marketing .navbar .user-nav .user-menu:active:hover,
  .admin .navbar .user-nav .user-menu.active:hover,
  .marketing .navbar .user-nav .user-menu.active:hover,
  .admin .navbar .user-nav .user-menu:active:focus,
  .marketing .navbar .user-nav .user-menu:active:focus,
  .admin .navbar .user-nav .user-menu.active:focus,
  .marketing .navbar .user-nav .user-menu.active:focus,
  .admin .navbar .user-nav .user-menu:active.focus,
  .marketing .navbar .user-nav .user-menu:active.focus,
  .admin .navbar .user-nav .user-menu.active.focus,
  .marketing .navbar .user-nav .user-menu.active.focus {
    background-color: #33c3e0;
  }
  .admin .navbar .user-nav .user-menu span,
  .marketing .navbar .user-nav .user-menu span,
  .admin .navbar .user-nav .user-menu .fa,
  .marketing .navbar .user-nav .user-menu .fa {
    font-size: 32px;
    line-height: 60px;
  }
  .admin .navbar .user-nav .open > a,
  .marketing .navbar .user-nav .open > a {
    background: none;
  }
  .admin .navbar .dropdown-menu header .title,
  .marketing .navbar .dropdown-menu header .title {
    color: #333;
  }
  .admin .navbar .site-purpose,
  .marketing .navbar .site-purpose {
    display: none;
  }
  .admin .navbar-inverse .navbar-nav > .open .dropdown-menu,
  .marketing .navbar-inverse .navbar-nav > .open .dropdown-menu {
    background: #ffffff;
  }
}
.admin .content-wrapper .alert,
.marketing .content-wrapper .alert {
  border-radius: 0;
  margin: 0 -15px;
  text-align: right;
}
.admin .content-wrapper .alert.alert-fixed,
.marketing .content-wrapper .alert.alert-fixed {
  transition: all 0.3s ease;
  left: 60px;
  margin: 0;
  position: fixed;
  right: 0;
  z-index: 350;
}
.admin.sidebar-open .content-wrapper .alert.alert-fixed,
.marketing.sidebar-open .content-wrapper .alert.alert-fixed {
  left: 380px;
}
.admin .workspace .panel-default,
.marketing .workspace .panel-default {
  -webkit-box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 0;
  color: #333;
  min-height: 440px;
  margin: 0 auto 25px;
  position: relative;
}
.admin .workspace .panel-default .panel-heading,
.marketing .workspace .panel-default .panel-heading {
  background: none;
  border: none;
  padding: 20px 40px;
}
.admin .workspace .panel-default .panel-heading.lg-heading,
.marketing .workspace .panel-default .panel-heading.lg-heading {
  padding: 20px 20px 10px;
}
.admin .workspace .panel-default .panel-heading .panel-title,
.marketing .workspace .panel-default .panel-heading .panel-title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.2em;
  letter-spacing: normal;
  margin: 0 0 0.5em;
}
.admin .workspace .panel-default .panel-heading .panel-subtitle,
.marketing .workspace .panel-default .panel-heading .panel-subtitle {
  font-size: 1em;
  letter-spacing: normal;
  margin: 0;
}
.admin .workspace .panel-default .panel-body,
.marketing .workspace .panel-default .panel-body {
  clear: both;
  padding: 0 20px 20px;
}
.admin .workspace .panel-default .panel-footer,
.marketing .workspace .panel-default .panel-footer {
  background: none;
  border: none;
  bottom: 0;
  left: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}
.admin .workspace .panel-default .panel-footer.fluid,
.marketing .workspace .panel-default .panel-footer.fluid {
  padding: 0 40px 1em;
  position: relative;
}
.admin .workspace .panel-default.header-only,
.marketing .workspace .panel-default.header-only,
.admin .workspace .panel-default.footer-only,
.marketing .workspace .panel-default.footer-only {
  min-height: 0;
}
.admin .workspace .panel-default.footer-only,
.marketing .workspace .panel-default.footer-only {
  height: 170px;
}
.admin .workspace .panel-default.footer-only .form-submit .btn,
.marketing .workspace .panel-default.footer-only .form-submit .btn {
  height: 170px;
}
.admin .workspace .panel-default.footer-only .form-submit .icon,
.marketing .workspace .panel-default.footer-only .form-submit .icon,
.admin .workspace .panel-default.footer-only .form-submit .title,
.marketing .workspace .panel-default.footer-only .form-submit .title {
  height: 170px;
  line-height: 170px;
}
.marketing .workspace .panel-default {
  max-width: 738px;
}
.marketing .workspace .panel-default .panel-body {
  padding-bottom: 90px;
}
.marketing .marketing-content .front-cta h1 {
  font-size: 84px;
}
.marketing .marketing-content .front-cta p {
  font-size: 24px;
  padding: 2em;
}
.marketing .marketing-content .panel-default h1,
.marketing .marketing-content .panel-default h2,
.marketing .marketing-content .panel-default h3,
.marketing .marketing-content .panel-default h4 {
  letter-spacing: normal;
}
.text-light {
  color: #ffffff;
}
.fixed {
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.list-spacing-3 li + li {
  padding-top: 3px;
}
.list-spacing-5 li + li {
  padding-top: 5px;
}
.list-spacing-8 li + li {
  padding-top: 8px;
}
.list-spacing-10 li + li {
  padding-top: 10px;
}
.list-spacing-15 li + li {
  padding-top: 15px;
}
.list-divide-items {
  margin: 0;
  padding-left: 10px;
  list-style: none;
}
.list-divide-items li + li {
  margin-top: 13px;
  padding-top: 13px;
  border-top: 1px solid #EEE;
}
.fixed-height .panel-body {
  overflow: auto;
}
.hover-height {
  overflow: hidden;
  transition: all 0.2s ease;
}
.mh-20 {
  max-height: 20px;
}
.mh-25 {
  max-height: 25px;
}
.mh-30 {
  max-height: 30px;
}
.mh-35 {
  max-height: 35px;
}
.mh-40 {
  max-height: 40px;
}
.mh-50 {
  max-height: 50px;
}
.mh-100 {
  max-height: 100px;
}
.mh-200 {
  max-height: 200px;
}
.mh-300 {
  max-height: 300px;
}
.mh-400 {
  max-height: 400px;
}
.mh-500 {
  max-height: 500px;
}
.mh-600 {
  max-height: 600px;
}
.mh-700 {
  max-height: 700px;
}
.mh-800 {
  max-height: 800px;
}
.mh-900 {
  max-height: 900px;
}
.mh-1000 {
  max-height: 1000px;
}
.h-20 {
  height: 20px;
}
.h-25 {
  height: 25px;
}
.h-30 {
  height: 30px;
}
.h-35 {
  height: 35px;
}
.h-40 {
  height: 40px;
}
.h-50 {
  height: 50px;
}
.h-80 {
  height: 80px;
}
.h-100 {
  height: 100px;
}
.h-120 {
  height: 120px;
}
.h-150 {
  height: 150px;
}
.h-170 {
  height: 170px;
}
.h-200 {
  height: 200px;
}
.h-300 {
  height: 300px;
}
.h-400 {
  height: 400px;
}
.h-500 {
  height: 500px;
}
.h-600 {
  height: 600px;
}
.h-700 {
  height: 700px;
}
.h-800 {
  height: 800px;
}
.h-900 {
  height: 900px;
}
.h-1000 {
  height: 1000px;
}
.t-center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bg-none {
  background-color: transparent;
}
.no-boxshadow {
  box-shadow: none;
}
.h-a {
  height: auto;
}
.of-a {
  overflow: auto;
}
.of-h {
  overflow: hidden;
}
.of-v {
  overflow: visible;
}
.of-x-a {
  overflow-x: auto;
}
.of-x-h {
  overflow-x: hidden;
}
.of-x-v {
  overflow-x: visible;
}
.of-y-a {
  overflow-y: auto;
}
.of-y-h {
  overflow-y: hidden;
}
.of-y-v {
  overflow-y: visible;
}
.center-column {
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.va-t {
  vertical-align: top;
}
.va-m {
  vertical-align: middle;
}
.va-b {
  vertical-align: bottom;
}
.va-s {
  vertical-align: super;
}
.posr {
  position: relative;
}
.ib,
.inline-object {
  display: inline-block;
}
.cursor {
  cursor: pointer;
}
.option-disabled {
  opacity: 0.6;
}
.input-unstyled,
.input-unstyled:hover,
.input-unstyled:focus {
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
}
.panel-group.table-layout > .panel + .panel {
  border-left: 0;
}
.panel-group.table-layout + .panel-group.table-layout > .panel {
  border-top: 0;
}
.row.table-layout {
  margin-left: 0;
  margin-right: 0;
}
.table-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
}
.table-layout > div,
.table-layout > aside,
.table-layout > section {
  vertical-align: middle;
  display: table-cell;
  float: none;
}
@media (max-width: 1300px) {
  .table-layout.table-clear-md,
  .table-layout.table-clear-md > div,
  .table-layout.table-clear-md > aside,
  .table-layout.table-clear-md > section {
    display: block;
    float: none;
  }
}
@media (max-width: 992px) {
  .table-layout.table-clear-sm,
  .table-layout.table-clear-sm > div,
  .table-layout.table-clear-sm > aside,
  .table-layout.table-clear-sm > section {
    display: block;
    float: none;
  }
}
@media (max-width: 767px) {
  .table-layout.table-clear-xs,
  .table-layout.table-clear-xs > div,
  .table-layout.table-clear-xs > aside,
  .table-layout.table-clear-xs > section {
    display: block;
    float: none;
  }
}
.text-uppercase {
  text-transform: uppercase;
}
.fsn {
  font-style: normal;
}
.fsi {
  font-style: italic;
}
.fso {
  font-style: oblique;
}
.fw200 {
  font-weight: 200;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw600 {
  font-weight: 600;
}
.fs3 {
  font-size: 3px;
}
.fs4 {
  font-size: 4px;
}
.fs5 {
  font-size: 5px;
}
.fs6 {
  font-size: 6px;
}
.fs7 {
  font-size: 7px;
}
.fs8 {
  font-size: 8px;
}
.fs9 {
  font-size: 9px;
}
.fs10 {
  font-size: 10px;
}
.fs11 {
  font-size: 11px;
}
.fs12 {
  font-size: 12px;
}
.fs13 {
  font-size: 13px;
}
.fs14 {
  font-size: 14px;
}
.fs15 {
  font-size: 15px;
}
.fs16 {
  font-size: 16px;
}
.fs17 {
  font-size: 17px;
}
.fs18 {
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.fs22 {
  font-size: 22px;
}
.fs24 {
  font-size: 24px;
}
.fs26 {
  font-size: 26px;
}
.fs28 {
  font-size: 28px;
}
.fs30 {
  font-size: 30px;
}
.fs35 {
  font-size: 35px;
}
.fs40 {
  font-size: 40px;
}
.fs45 {
  font-size: 45px;
}
.fs50 {
  font-size: 50px;
}
.fs60 {
  font-size: 60px;
}
.fs70 {
  font-size: 70px;
}
.fs80 {
  font-size: 80px;
}
.fs90 {
  font-size: 90px;
}
.fs100 {
  font-size: 100px;
}
.pn {
  padding: 0;
}
.p1 {
  padding: 1px;
}
.p2 {
  padding: 2px;
}
.p3 {
  padding: 3px;
}
.p4 {
  padding: 4px;
}
.p5 {
  padding: 5px;
}
.p6 {
  padding: 6px;
}
.p7 {
  padding: 7px;
}
.p8 {
  padding: 8px;
}
.p10 {
  padding: 10px;
}
.p12 {
  padding: 12px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p25 {
  padding: 25px;
}
.p30 {
  padding: 30px;
}
.p35 {
  padding: 35px;
}
.p40 {
  padding: 40px;
}
.p50 {
  padding: 50px;
}
.ptn {
  padding-top: 0;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt25 {
  padding-top: 25px;
}
.pt30 {
  padding-top: 30px;
}
.pt35 {
  padding-top: 35px;
}
.pt40 {
  padding-top: 40px;
}
.pt50 {
  padding-top: 50px;
}
.prn {
  padding-right: 0;
}
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}
.pr25 {
  padding-right: 25px;
}
.pr30 {
  padding-right: 30px;
}
.pr35 {
  padding-right: 35px;
}
.pr40 {
  padding-right: 40px;
}
.pr50 {
  padding-right: 50px;
}
.pbn {
  padding-bottom: 0;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb25 {
  padding-bottom: 25px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb35 {
  padding-bottom: 35px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb50 {
  padding-bottom: 50px;
}
.pln {
  padding-left: 0;
}
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pl25 {
  padding-left: 25px;
}
.pl30 {
  padding-left: 30px;
}
.pl35 {
  padding-left: 35px;
}
.pl40 {
  padding-left: 40px;
}
.pl50 {
  padding-left: 50px;
}
.pv5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pv8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pv10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pv20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pv25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.pv30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pv40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pv50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.ph5 {
  padding-left: 5px;
  padding-right: 5px;
}
.ph8 {
  padding-left: 8px;
  padding-right: 8px;
}
.ph10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ph15 {
  padding-left: 15px;
  padding-right: 15px;
}
.ph20 {
  padding-left: 20px;
  padding-right: 20px;
}
.ph25 {
  padding-left: 25px;
  padding-right: 25px;
}
.ph30 {
  padding-left: 30px;
  padding-right: 30px;
}
.ph40 {
  padding-left: 40px;
  padding-right: 40px;
}
.ph50 {
  padding-left: 50px;
  padding-right: 50px;
}
/* responsive padding helpers */
@media (min-width: 1140px) {
  .ptn-lg {
    padding-top: 0;
  }
  .prn-lg {
    padding-right: 0;
  }
  .pbn-lg {
    padding-bottom: 0;
  }
  .pln-lg {
    padding-left: 0;
  }
  .pt5-lg {
    padding-top: 5px;
  }
  .pr5-lg {
    padding-right: 5px;
  }
  .pb5-lg {
    padding-bottom: 5px;
  }
  .pl5-lg {
    padding-left: 5px;
  }
}
@media (min-width: 992px) {
  .ptn-md {
    padding-top: 0;
  }
  .prn-md {
    padding-right: 0;
  }
  .pbn-md {
    padding-bottom: 0;
  }
  .pln-md {
    padding-left: 0;
  }
  .pt5-md {
    padding-top: 5px;
  }
  .pr5-md {
    padding-right: 5px;
  }
  .pb5-md {
    padding-bottom: 5px;
  }
  .pl5-md {
    padding-left: 5px;
  }
}
.mauto {
  margin-left: auto;
  margin-right: auto;
}
.mn {
  margin: 0;
}
.m1 {
  margin: 1px;
}
.m2 {
  margin: 2px;
}
.m3 {
  margin: 3px;
}
.m4 {
  margin: 4px;
}
.m5 {
  margin: 5px;
}
.m8 {
  margin: 8px;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px;
}
.m20 {
  margin: 20px;
}
.m25 {
  margin: 25px;
}
.m30 {
  margin: 30px;
}
.m35 {
  margin: 35px;
}
.m40 {
  margin: 40px;
}
.m50 {
  margin: 50px;
}
.mtn {
  margin-top: 0;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}
.mt35 {
  margin-top: 35px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}
.mt70 {
  margin-top: 70px;
}
.mrn {
  margin-right: 0;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}
.mr25 {
  margin-right: 25px;
}
.mr30 {
  margin-right: 30px;
}
.mr35 {
  margin-right: 35px;
}
.mr40 {
  margin-right: 40px;
}
.mr50 {
  margin-right: 50px;
}
.mbn {
  margin-bottom: 0;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb70 {
  margin-bottom: 70px;
}
.mln {
  margin-left: 0;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml25 {
  margin-left: 25px;
}
.ml30 {
  margin-left: 30px;
}
.ml35 {
  margin-left: 35px;
}
.ml40 {
  margin-left: 40px;
}
.ml50 {
  margin-left: 50px;
}
.mv5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mv10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mv15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mv20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mv25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.mv30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mv40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mv50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mv70 {
  margin-top: 70px;
  margin-bottom: 70px;
}
.mh5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mh10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mh15 {
  margin-left: 15px;
  margin-right: 15px;
}
.mh20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mh25 {
  margin-left: 25px;
  margin-right: 25px;
}
.mh30 {
  margin-left: 30px;
  margin-right: 30px;
}
.mh40 {
  margin-left: 40px;
  margin-right: 40px;
}
.mh50 {
  margin-left: 50px;
  margin-right: 50px;
}
.mh70 {
  margin-left: 70px;
  margin-right: 70px;
}
.mtn5 {
  margin-top: -5px;
}
.mtn10 {
  margin-top: -10px;
}
.mtn15 {
  margin-top: -15px;
}
.mtn20 {
  margin-top: -20px;
}
.mtn30 {
  margin-top: -30px;
}
.mrn5 {
  margin-right: -5px;
}
.mrn10 {
  margin-right: -10px;
}
.mrn15 {
  margin-right: -15px;
}
.mrn20 {
  margin-right: -20px;
}
.mrn30 {
  margin-right: -30px;
}
.mbn5 {
  margin-bottom: -5px;
}
.mbn10 {
  margin-bottom: -10px;
}
.mbn15 {
  margin-bottom: -15px;
}
.mbn20 {
  margin-bottom: -20px;
}
.mbn30 {
  margin-bottom: -30px;
}
.mln5 {
  margin-left: -5px;
}
.mln10 {
  margin-left: -10px;
}
.mln15 {
  margin-left: -15px;
}
.mln20 {
  margin-left: -20px;
}
.mln30 {
  margin-left: -30px;
}
.mvn5 {
  margin-top: -5px;
  margin-bottom: -5px;
}
.mvn10 {
  margin-top: -10px;
  margin-bottom: -10px;
}
.mvn15 {
  margin-top: -15px;
  margin-bottom: -15px;
}
.mvn20 {
  margin-top: -20px;
  margin-bottom: -20px;
}
.mvn30 {
  margin-top: -30px;
  margin-bottom: -30px;
}
.mhn5 {
  margin-left: -5px;
  margin-right: -5px;
}
.mhn10 {
  margin-left: -10px;
  margin-right: -10px;
}
.mhn15 {
  margin-left: -15px;
  margin-right: -15px;
}
.mhn20 {
  margin-left: -20px;
  margin-right: -20px;
}
.mhn30 {
  margin-left: -30px;
  margin-right: -30px;
}
.lh0 {
  line-height: 0;
}
.lh5 {
  line-height: 5px;
}
.lh10 {
  line-height: 10px;
}
.lh15 {
  line-height: 15px;
}
.lh20 {
  line-height: 20px;
}
.lh25 {
  line-height: 25px;
}
.lh30 {
  line-height: 30px;
}
.lh35 {
  line-height: 35px;
}
.lh40 {
  line-height: 40px;
}
.br-l-n {
  border-left: none;
}
.br-r-n {
  border-right: none;
}
.br-t-n {
  border-top: none;
}
.br-b-n {
  border-bottom: none;
}
.br-n {
  border: none;
}
.br-v-n {
  border-top: none;
  border-bottom: none;
}
.br-h-n {
  border-left: none;
  border-right: none;
}
.br-a {
  border: 1px solid #e6e7e8;
}
.br-l {
  border-left: 1px solid #e6e7e8;
}
.br-r {
  border-right: 1px solid #e6e7e8;
}
.br-t {
  border-top: 1px solid #e6e7e8;
}
.br-b {
  border-bottom: 1px solid #e6e7e8;
}
.br-dashed {
  border-style: dotted;
}
.br0 {
  border-radius: 0px;
}
.br1 {
  border-radius: 1px;
}
.br2 {
  border-radius: 2px;
}
.br3 {
  border-radius: 3px;
}
.br4 {
  border-radius: 4px;
}
.br6 {
  border-radius: 6px;
}
.br8 {
  border-radius: 8px;
}
.br12 {
  border-radius: 12px;
}
.br24 {
  border-radius: 24px;
}
.br64 {
  border-radius: 64px;
}
.bw1 {
  border-width: 1px;
}
.bw2 {
  border-width: 2px;
}
.bw3 {
  border-width: 3px;
}
.bw4 {
  border-width: 4px;
}
.bw5 {
  border-width: 5px;
}
.bw8 {
  border-width: 8px;
}
.bw10 {
  border-width: 10px;
}
.bw15 {
  border-width: 15px;
}
.br-white {
  border-color: #FFF;
}
.br-lighter {
  border-color: #e6e7e8;
}
.br-light {
  border-color: #cfcfd0;
}
.br-grey {
  border-color: #959595;
}
.br-greyer {
  border-color: #e6e7e8;
}
.br-dark {
  border-color: #414042 !important;
}
.br-dark-light {
  border-color: #5a595c !important;
}
.br-primary {
  border-color: #009ebb !important;
}
.br-primary-light {
  border-color: #00c9ee !important;
}
.br-info {
  border-color: #33c3e0 !important;
}
.br-info-light {
  border-color: #5fd0e7 !important;
}
.br-success {
  border-color: #3DA323 !important;
}
.br-success-light {
  border-color: #4dcd2c !important;
}
.br-warning {
  border-color: #FFB200 !important;
}
.br-warning-light {
  border-color: #ffc133 !important;
}
.br-danger {
  border-color: #FF3932 !important;
}
.br-danger-light {
  border-color: #ff6a65 !important;
}
.br-tp-top {
  border-top-color: rgba(0, 0, 0, 0.1);
}
.br-tp-right {
  border-right-color: rgba(0, 0, 0, 0.1);
}
.br-tp-left {
  border-left-color: rgba(0, 0, 0, 0.1);
}
.fluid-width {
  width: 100%;
}
.mw10 {
  max-width: 10px;
}
.mw20 {
  max-width: 20px;
}
.mw30 {
  max-width: 30px;
}
.mw35 {
  max-width: 35px;
}
.mw40 {
  max-width: 40px;
}
.mw45 {
  max-width: 45px;
}
.mw50 {
  max-width: 50px;
}
.mw60 {
  max-width: 60px;
}
.mw80 {
  max-width: 80px;
}
.mw100 {
  max-width: 100px;
}
.mw140 {
  max-width: 140px;
}
.mw160 {
  max-width: 160px;
}
.mw180 {
  max-width: 180px;
}
.mw200 {
  max-width: 200px;
}
.mw240 {
  max-width: 240px;
}
.mw280 {
  max-width: 280px;
}
.mw320 {
  max-width: 320px;
}
.mw400 {
  max-width: 400px;
}
.mw450 {
  max-width: 450px;
}
.mw500 {
  max-width: 500px;
}
.mw600 {
  max-width: 600px;
}
.mw700 {
  max-width: 700px;
}
.mw800 {
  max-width: 800px;
}
.mw900 {
  max-width: 900px;
}
.mw1000 {
  max-width: 1000px;
}
.mw1100 {
  max-width: 1100px;
}
.mw1200 {
  max-width: 1200px;
}
.w20 {
  width: 20px;
}
.w30 {
  width: 30px;
}
.w40 {
  width: 40px;
}
.w50 {
  width: 50px;
}
.w75 {
  width: 75px;
}
.w100 {
  width: 100px;
}
.w125 {
  width: 125px;
}
.w150 {
  width: 150px;
}
.w175 {
  width: 175px;
}
.w200 {
  width: 200px;
}
.w225 {
  width: 225px;
}
.w250 {
  width: 250px;
}
.w300 {
  width: 300px;
}
.w320 {
  width: 320px;
}
.w350 {
  width: 350px;
}
.w400 {
  width: 400px;
}
.w450 {
  width: 450px;
}
.mnw20 {
  min-width: 20px;
}
.mnw30 {
  min-width: 30px;
}
.mnw40 {
  min-width: 40px;
}
.mnw50 {
  min-width: 50px;
}
.mnw75 {
  min-width: 75px;
}
.mnw100 {
  min-width: 100px;
}
.mnw125 {
  min-width: 125px;
}
.mnw150 {
  min-width: 150px;
}
.mnw175 {
  min-width: 175px;
}
.mnw200 {
  min-width: 200px;
}
.mnw250 {
  min-width: 250px;
}
.mnw300 {
  min-width: 300px;
}
.mnw500 {
  min-width: 500px;
}
.mnw700 {
  min-width: 700px;
}
.mnw1000 {
  min-width: 1000px;
}
.push {
  width: 100%;
  padding: 25px 0;
}
.admin-footer {
  transition: padding 0.3s ease;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  background: #414042;
  /* old browsers */
  background: -webkit-linear-gradient(#414042, #343335);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(#414042, #343335);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  bottom: 0;
  color: #ffffff;
  font-size: 0.85em;
  left: 0;
  letter-spacing: 0.2em;
  min-width: 380px;
  padding-left: 60px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  z-index: 1001;
}
.admin-footer p {
  margin: 0;
}
.admin-footer .footer-content {
  padding: 25px 0;
  position: relative;
  z-index: 1020;
}
.admin-footer .footer-content .row {
  max-width: 550px;
}
.admin-footer .footer-content .col-wrapper {
  margin-left: 18%;
}
.admin-footer .footer-content h4 {
  color: #009ebb;
  font-size: inherit;
  margin: 0 0 1.5em;
}
.admin-footer .footer-content ul {
  font-size: inherit;
  list-style-type: none;
  padding: 0;
}
.admin-footer .footer-content ul li {
  margin: 0 0 1.2em;
}
.admin-footer .footer-content ul li a {
  color: #ffffff;
}
.admin-footer .footer-center {
  border-left: 1px solid #333;
  border-right: 1px solid #333;
}
.admin-footer .try-it {
  overflow: auto;
}
@media (min-width: 768px) {
  .admin-footer .try-it {
    position: relative;
    top: -32px;
    margin: auto;
    max-width: 380px;
    z-index: 1021;
  }
}
.admin-footer.with-ghost {
  background: #414042;
  /* old browsers */
  background: url(../img/admin/marketing/mkt-footer-ghost.png) 50px 100% no-repeat, -webkit-linear-gradient(#414042, #343335);
  background: url(../img/admin/marketing/mkt-footer-ghost.png) 50px 100% no-repeat, -o-linear-gradient(#414042, #343335);
  background: url(../img/admin/marketing/mkt-footer-ghost.png) 50px 100% no-repeat, linear-gradient(#414042, #343335);
  /* Chrome 10-25, Safari 5.1-6 */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.admin-footer .logo,
.admin-footer .wordmark {
  max-width: 100%;
  display: inline-block;
}
.admin-footer .wordmark {
  width: 166px;
}
.admin-footer .logo {
  width: 45px;
}
.admin-footer .built-for {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: none;
  font-size: 1.8125em;
  line-height: 32px;
  margin-top: 1em;
  display: inline-block;
}
@media (min-width: 768px) {
  .admin-footer .built-for {
    margin-top: 0;
  }
  .admin-footer .built-for:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 67px;
    background-color: #959595;
    left: -5px;
    top: -16px;
  }
}
.built-for-container {
  text-align: center;
}
@media (min-width: 768px) {
  .built-for-container {
    text-align: left;
  }
}
.logo-area {
  text-align: center;
}
.copyright-footer {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background: #44565e;
  bottom: 0;
  height: 30px;
}
.copyright-footer a {
  color: #ffffff;
}
.copyright-footer p {
  line-height: 30px;
}
.footer-content > .row,
.copyright-footer > .row {
  margin: 0 auto;
  max-width: 900px;
}
.credit-row {
  text-align: center;
}
.credit-row.row {
  margin-bottom: 18px;
}
.copyright-info.row {
  text-align: center;
}
.copyright-info.row p {
  font-weight: normal;
}
.admin.sidebar-open ~ .admin-footer {
  padding-left: 315px;
}
.marketing-footer {
  background: #414042 url(../img/admin/marketing/architech-footer-logo-transparency.png) center 25px no-repeat;
  color: #ffffff;
  font-size: 14px;
  padding: 25px 25px 15px;
}
@media (min-width: 768px) {
  .marketing-footer {
    padding: 25px 30px 18px;
  }
}
@media (min-width: 992px) {
  .marketing-footer {
    background-position: center 25px;
    padding-bottom: 20px;
  }
}
.marketing-footer .logo.row {
  padding-top: 45px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .marketing-footer .logo.row {
    padding-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .marketing-footer .logo.row {
    padding-bottom: 5px;
  }
}
.marketing-footer .brand {
  display: inline-block;
  left: 50%;
  padding-top: 3px;
  position: relative;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media (min-width: 768px) {
  .marketing-footer .brand {
    float: right;
    left: auto;
    transform: none;
  }
}
.marketing-footer .brand .logo {
  max-width: 48px;
}
.marketing-footer .brand .wordmark {
  max-width: 168px;
}
.marketing-footer .slogan {
  display: none;
}
@media (min-width: 768px) {
  .marketing-footer .slogan {
    border-left: 1px solid #959595;
    display: block;
    padding-top: 2px;
  }
}
.marketing-footer .slogan > .tagline {
  font-size: 1.9em;
}
.marketing-footer .footer-menu {
  text-align: center;
}
.marketing-footer .footer-menu .nav-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.marketing-footer .footer-menu .nav-list .nav-item {
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 15px;
  text-transform: uppercase;
}
.marketing-footer .footer-menu .nav-list .nav-item .nav-item-link {
  padding: 20px 0;
}
.marketing-footer .footer-menu .nav-list .nav-item .nav-item-link:hover,
.marketing-footer .footer-menu .nav-list .nav-item .nav-item-link:focus {
  color: #00c9ee;
}
.marketing-footer .footer-menu .nav-list .nav-item .nav-item-link:hover {
  text-decoration: none;
}
.marketing-footer .footer-menu .nav-list .nav-item.gray .nav-item-link {
  color: #708c98;
}
.marketing-footer .footer-menu .nav-list .nav-item.gray .nav-item-link:hover,
.marketing-footer .footer-menu .nav-list .nav-item.gray .nav-item-link:focus {
  color: #8ea4ad;
}
@media (min-width: 480px) {
  .marketing-footer .footer-menu .nav-list .nav-item {
    display: inline-block;
    padding: 20px 15px;
  }
}
.marketing-footer .copyright-info .copyright {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 0.857em;
}
.admin-footer .footer-menu {
  text-align: center;
  margin-top: 20px;
}
.admin-footer .footer-menu .nav-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.admin-footer .footer-menu .nav-list .nav-item {
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 15px;
  text-transform: uppercase;
}
.admin-footer .footer-menu .nav-list .nav-item .nav-item-link {
  padding: 20px 0;
}
.admin-footer .footer-menu .nav-list .nav-item .nav-item-link:hover,
.admin-footer .footer-menu .nav-list .nav-item .nav-item-link:focus {
  color: #00c9ee;
}
.admin-footer .footer-menu .nav-list .nav-item .nav-item-link:hover {
  text-decoration: none;
}
.admin-footer .footer-menu .nav-list .nav-item.gray .nav-item-link {
  color: #708c98;
}
.admin-footer .footer-menu .nav-list .nav-item.gray .nav-item-link:hover,
.admin-footer .footer-menu .nav-list .nav-item.gray .nav-item-link:focus {
  color: #8ea4ad;
}
@media (min-width: 480px) {
  .admin-footer .footer-menu .nav-list .nav-item {
    display: inline-block;
    padding: 20px 15px;
  }
}
.admin-footer .copyright-info .copyright {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 0.857em;
}
.admin .admin-content,
.marketing .admin-content {
  transition: padding 0.3s ease;
  padding-left: 60px;
  position: relative;
}
.admin .sidebar-wrapper,
.marketing .sidebar-wrapper {
  transition: width 0.3s ease;
  -webkit-box-shadow: 10px 0 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: 10px 0 0 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  height: calc(100% - 60px + 20px);
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 80px;
  width: 60px;
  z-index: 300;
}
.admin .sidebar-header.side-user,
.marketing .sidebar-header.side-user {
  background-color: #1a232d;
  min-height: 120px;
  position: relative;
  top: 0;
}
.admin .sidebar-header.side-user .side-user-bg,
.marketing .sidebar-header.side-user .side-user-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  background-size: cover;
  background-position: center center;
}
.admin .sidebar-header.side-user .site-header,
.marketing .sidebar-header.side-user .site-header {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
.admin .sidebar-header.side-user .site-header span,
.marketing .sidebar-header.side-user .site-header span {
  color: #ffffff;
  text-shadow: #ffffff 0 0 32px;
}
.admin .sidebar-header.side-user .site-header a,
.marketing .sidebar-header.side-user .site-header a {
  color: #ffffff;
}
.admin .sidebar-header.side-user .site-header a .icon,
.marketing .sidebar-header.side-user .site-header a .icon {
  font-size: 1.6em;
}
.admin .content-wrapper,
.marketing .content-wrapper {
  min-width: 320px;
  position: relative;
  width: 100%;
}
.admin .side-nav,
.marketing .side-nav {
  position: relative;
}
.admin .sidebar-nav,
.marketing .sidebar-nav {
  border-radius: 0;
  box-shadow: none;
  display: none;
  margin: 0;
  top: 0;
  width: 315px;
  z-index: 10;
}
.admin .sidebar-nav li.list-group-item,
.marketing .sidebar-nav li.list-group-item {
  background: transparent;
  border: none;
  border-bottom: 1px solid #e6e7e8;
  border-radius: 0;
  clear: both;
  padding: 0;
  width: 100%;
}
.admin .sidebar-nav li a,
.marketing .sidebar-nav li a {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #666;
  display: block;
  font-size: 0.9em;
  letter-spacing: 0.2em;
  padding: 0;
  position: relative;
  text-decoration: none;
}
.admin .sidebar-nav li a .at-icon,
.marketing .sidebar-nav li a .at-icon {
  letter-spacing: normal;
  line-height: 60px;
  text-align: center;
}
.admin .sidebar-nav li a .nav-title,
.marketing .sidebar-nav li a .nav-title {
  line-height: 60px;
  padding-left: 23px;
  text-align: left;
}
.admin .sidebar-nav li a .nav-title .list-icon,
.marketing .sidebar-nav li a .nav-title .list-icon {
  padding-right: 12px;
  font-size: 1.35em;
}
.admin .sidebar-nav li a .caret-icon,
.marketing .sidebar-nav li a .caret-icon {
  float: right;
  padding-right: 23px;
}
.admin .sidebar-nav li a .caret-icon:before,
.marketing .sidebar-nav li a .caret-icon:before {
  content: "\e945";
}
.admin .sidebar-nav li a.collapsed .caret-icon:before,
.marketing .sidebar-nav li a.collapsed .caret-icon:before {
  content: "\e953";
}
.admin .sidebar-nav li a:focus,
.marketing .sidebar-nav li a:focus {
  color: #666;
}
.admin .sidebar-nav li a:hover,
.marketing .sidebar-nav li a:hover,
.admin .sidebar-nav li a.active,
.marketing .sidebar-nav li a.active {
  color: #009ebb;
}
.admin .sidebar-nav li a .site-title,
.marketing .sidebar-nav li a .site-title {
  display: inline-block;
  margin: 23px;
  text-align: left;
  text-transform: uppercase;
  color: #009ebb;
}
.admin .sidebar-nav li.dashboard-root a,
.marketing .sidebar-nav li.dashboard-root a {
  padding: 0;
}
.admin .sidebar-nav li.dashboard-root a .at-icon,
.marketing .sidebar-nav li.dashboard-root a .at-icon {
  font-size: 2em;
  line-height: 60px;
  padding-left: 23px;
}
.admin .sidebar-nav .submenu ul,
.marketing .sidebar-nav .submenu ul {
  background: #e6e7e8;
  list-style-type: none;
  margin-left: 0;
  padding: 0;
}
.admin .sidebar-nav .submenu ul li a,
.marketing .sidebar-nav .submenu ul li a {
  line-height: 48px;
  padding: 0 13px 0 23px;
  white-space: nowrap;
}
.admin .sidebar-nav .submenu ul li + li,
.marketing .sidebar-nav .submenu ul li + li {
  border-top: 1px solid #cfcfd0;
}
.admin .sidebar-nav .submenu ul li.active a,
.marketing .sidebar-nav .submenu ul li.active a {
  background: #009ebb;
  color: #ffffff;
}
.admin .menu-toggle,
.marketing .menu-toggle {
  line-height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 12;
}
.admin .menu-toggle a,
.marketing .menu-toggle a {
  color: #666;
  cursor: pointer;
  float: right;
  text-align: center;
  width: 60px;
}
.admin .menu-toggle a:hover,
.marketing .menu-toggle a:hover,
.admin .menu-toggle a:focus,
.marketing .menu-toggle a:focus {
  color: #959595;
}
.admin .menu-toggle a > span,
.marketing .menu-toggle a > span {
  transform: rotate(180deg);
}
.admin.sidebar-open .admin-content,
.marketing.sidebar-open .admin-content {
  padding-left: 315px;
}
.admin.sidebar-open .sidebar-wrapper,
.marketing.sidebar-open .sidebar-wrapper {
  width: 315px;
}
.admin.sidebar-open .sidebar-nav,
.marketing.sidebar-open .sidebar-nav {
  display: block;
}
.admin.sidebar-open .menu-toggle a > span,
.marketing.sidebar-open .menu-toggle a > span {
  transform: rotate(0);
}
.admin.has-notice .sidebar-wrapper,
.marketing.has-notice .admin-content .sidebar-wrapper {
  top: 163px;
}
.admin .workspace header,
.marketing .workspace header {
  padding: 1em 0;
}
.admin .workspace header h4,
.marketing .workspace header h4 {
  font-size: 1.3em;
  margin: 0;
}
.admin .workspace header .fa,
.marketing .workspace header .fa {
  color: #009ebb;
  margin-right: 0.3em;
}
.admin .workspace .panel,
.marketing .workspace .panel {
  z-index: 1;
}
.admin .workspace .user-header,
.marketing .workspace .user-header {
  padding: 30px 0;
}
.admin .workspace .user-header p,
.marketing .workspace .user-header p,
.admin .workspace .user-header select,
.marketing .workspace .user-header select {
  display: inline;
}
.admin .workspace .user-header p,
.marketing .workspace .user-header p {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.6em;
}
.admin .workspace .main-content,
.marketing .workspace .main-content {
  padding: 30px 0 0;
}
.admin .workspace .main-content > .row,
.marketing .workspace .main-content > .row {
  margin: 0 auto;
  max-width: 1200px;
}
.admin .daily-stats .panel-default,
.marketing .daily-stats .panel-default,
.admin .pending-publish .panel-default,
.marketing .pending-publish .panel-default {
  min-height: 500px;
}
.admin .activity-timeline .panel-default,
.marketing .activity-timeline .panel-default {
  min-height: 960px;
}
.admin .workspace .form-control {
  height: 34px;
  padding-left: 24px;
  max-width: none;
  margin-bottom: 8px;
}
.admin .workspace .form-control-feedback {
  background: #009ebb;
  cursor: pointer;
  height: 34px;
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}
.admin .workspace .form-control-feedback.fa-search:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}
.admin .workspace .sparkline path {
  stroke: #009ebb;
  stroke-width: 1px;
  fill: none;
}
.admin .workspace .sparkline .end-line {
  stroke: none;
  fill: #f00;
}
.admin .workspace .profile-round {
  border-radius: 50%;
}
.admin .workspace .main-content > .row.lg-row,
.marketing .workspace .main-content > .row {
  margin: 0 auto;
  max-width: 1800px;
}
.admin.has-notice .workspace .main-content,
.marketing.has-notice .admin-content .workspace .main-content {
  padding: 120px 0 0;
}
.admin .workspace .panel {
  -webkit-box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 2px;
}
.admin .workspace .panel.panel-transparent {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}
.admin .workspace .panel.panel-border {
  border-style: solid;
  border-width: 0;
}
.admin .workspace .panel.panel-border.top {
  border-top-width: 5px;
}
.admin .workspace .panel.panel-border.right {
  border-right-width: 5px;
}
.admin .workspace .panel.panel-border.bottom {
  border-bottom-width: 5px;
}
.admin .workspace .panel.panel-border.left {
  border-left-width: 5px;
}
.admin .workspace .panel.panel-border > .panel-heading {
  background-color: #fafafa;
  border-color: #e7e7e7;
  border-top: 1px solid transparent;
}
.admin .workspace .panel.panel-border > .panel-heading > .panel-title {
  color: inherit;
}
.admin .workspace .panel.panel-border.panel-default {
  border-color: #ddd;
}
.admin .workspace .panel.panel-border.panel-default > .panel-heading {
  border-top: 1px solid transparent;
}
.admin .workspace .panel-menu {
  background-color: #fafafa;
  padding: 12px;
}
.admin .workspace .panel-menu.dark {
  background-color: #f8f8f8;
}
.admin .workspace .panel-body .panel-menu {
  border-left: 0;
  border-right: 0;
}
.admin .workspace .panel-heading + .panel-menu,
.admin .workspace .panel-menu + .panel-body,
.admin .workspace .panel-body + .panel-menu,
.admin .workspace .panel-body + .panel-body {
  border-top: 0;
}
.admin .workspace .panel-body {
  position: relative;
  padding: 15px;
}
.admin .workspace .panel-body + .panel-footer {
  border-top: 0;
}
.admin .workspace .panel-heading {
  position: relative;
  color: inherit;
  padding: 0 8px;
}
.admin .workspace .panel-heading + .panel-body {
  border-top: 0;
  margin-top: 5px;
}
.admin .workspace .panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}
.admin .workspace .panel-heading .widget-menu .btn-group {
  margin-top: -3px;
}
.admin .workspace .panel-heading .widget-menu .form-control {
  margin-top: 6px;
  font-size: 11px;
  height: 27px;
  padding: 2px 10px;
  border-radius: 1px;
}
.admin .workspace .panel-heading .widget-menu .form-control.input-sm {
  margin-top: 9px;
  height: 22px;
}
.admin .workspace .panel-heading .widget-menu .progress {
  margin-top: 11px;
  margin-bottom: 0;
}
.admin .workspace .panel-heading .widget-menu .progress-bar-lg {
  margin-top: 10px;
}
.admin .workspace .panel-heading .widget-menu .progress-bar-sm {
  margin-top: 15px;
}
.admin .workspace .panel-heading .widget-menu .progress-bar-xs {
  margin-top: 17px;
}
.admin .workspace .panel-icon {
  padding-left: 5px;
}
.admin .workspace .panel-title {
  padding-left: 6px;
  margin-top: 0;
  margin-bottom: 0;
}
.admin .workspace .panel-title > .fa,
.admin .workspace .panel-title .fal,
.admin .workspace .panel-title .fas,
.admin .workspace .panel-title > .imoon {
  top: 2px;
  min-width: 22px;
  color: inherit;
  font-size: 14px;
}
.admin .workspace .panel-title > a {
  color: inherit;
}
.admin .workspace .panel-footer {
  padding: 10px 15px;
  background-color: #fafafa;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
.admin .workspace .panel > .list-group {
  margin-bottom: 0;
}
.admin .workspace .panel > .list-group .list-group-item {
  border-radius: 0;
}
.admin .workspace .panel > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.admin .workspace .panel > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
.admin .workspace .panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.admin .workspace .panel-body + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.admin .workspace .list-group + .panel-footer {
  border-top-width: 0;
}
.admin .workspace .panel > .table,
.admin .workspace .panel > .table-responsive > .table,
.admin .workspace .panel > .panel-collapse > .table {
  margin-bottom: 0;
}
.admin .workspace .panel > .table:first-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}
.admin .workspace .panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.admin .workspace .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.admin .workspace .panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.admin .workspace .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 1px;
}
.admin .workspace .panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.admin .workspace .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.admin .workspace .panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.admin .workspace .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.admin .workspace .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 1px;
}
.admin .workspace .panel > .table:last-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
.admin .workspace .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.admin .workspace .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.admin .workspace .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.admin .workspace .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 1px;
}
.admin .workspace .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.admin .workspace .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.admin .workspace .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.admin .workspace .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.admin .workspace .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 1px;
}
.admin .workspace .panel > .table > tbody:first-child > tr:first-child th,
.admin .workspace .panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}
.admin .workspace .panel > .table-bordered,
.admin .workspace .panel > .table-responsive > .table-bordered {
  border: 0;
}
.admin .workspace .panel > .table-bordered > thead > tr > th:first-child,
.admin .workspace .panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.admin .workspace .panel > .table-bordered > tbody > tr > th:first-child,
.admin .workspace .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.admin .workspace .panel > .table-bordered > tfoot > tr > th:first-child,
.admin .workspace .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.admin .workspace .panel > .table-bordered > thead > tr > td:first-child,
.admin .workspace .panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.admin .workspace .panel > .table-bordered > tbody > tr > td:first-child,
.admin .workspace .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.admin .workspace .panel > .table-bordered > tfoot > tr > td:first-child,
.admin .workspace .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.admin .workspace .panel > .table-bordered > thead > tr > th:last-child,
.admin .workspace .panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.admin .workspace .panel > .table-bordered > tbody > tr > th:last-child,
.admin .workspace .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.admin .workspace .panel > .table-bordered > tfoot > tr > th:last-child,
.admin .workspace .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.admin .workspace .panel > .table-bordered > thead > tr > td:last-child,
.admin .workspace .panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.admin .workspace .panel > .table-bordered > tbody > tr > td:last-child,
.admin .workspace .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.admin .workspace .panel > .table-bordered > tfoot > tr > td:last-child,
.admin .workspace .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.admin .workspace .panel > .table-bordered > thead > tr:first-child > td,
.admin .workspace .panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.admin .workspace .panel > .table-bordered > tbody > tr:first-child > td,
.admin .workspace .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.admin .workspace .panel > .table-bordered > thead > tr:first-child > th,
.admin .workspace .panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.admin .workspace .panel > .table-bordered > tbody > tr:first-child > th,
.admin .workspace .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}
.admin .workspace .panel > .table-bordered > tbody > tr:last-child > td,
.admin .workspace .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.admin .workspace .panel > .table-bordered > tfoot > tr:last-child > td,
.admin .workspace .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.admin .workspace .panel > .table-bordered > tbody > tr:last-child > th,
.admin .workspace .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.admin .workspace .panel > .table-bordered > tfoot > tr:last-child > th,
.admin .workspace .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}
.admin .workspace .panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}
.admin .workspace .panel-group {
  margin-bottom: 20px;
}
.admin .workspace .panel-group .panel-title {
  padding-left: 0;
}
.admin .workspace .panel-group .panel-heading,
.admin .workspace .panel-group .panel-heading a {
  position: relative;
  display: block;
  width: 100%;
}
.admin .workspace .panel-group.accordion-lg .panel + .panel {
  margin-top: 12px;
}
.admin .workspace .panel-group.accordion-lg .panel-heading {
  font-size: 14px;
  height: 54px;
  line-height: 52px;
}
.admin .workspace .panel-group .accordion-icon {
  padding-left: 35px;
}
.admin .workspace .panel-group .accordion-icon:after {
  position: absolute;
  content: "\f068";
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Brands";
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  color: #555;
  left: 10px;
  top: 0;
}
.admin .workspace .panel-group .accordion-icon.collapsed:after {
  content: "\f067";
}
.admin .workspace .panel-group .accordion-icon.icon-right {
  padding-left: 10px;
  padding-right: 30px;
}
.admin .workspace .panel-group .accordion-icon.icon-right:after {
  left: auto;
  right: 5px;
}
.admin .workspace .panel-group .panel {
  margin-bottom: 0;
  border-radius: 2px;
}
.admin .workspace .panel-group .panel + .panel {
  margin-top: 5px;
}
.admin .workspace .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
}
.admin .workspace .panel-group .panel-footer {
  border-top: 0;
}
.admin .workspace .panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #eee;
}
.admin .activity-timeline .fullcalendar .fc-header-toolbar {
  display: none;
}
.admin .activity-timeline .fullcalendar .fc-list-view {
  border: none;
}
.admin ol.timeline-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  margin-bottom: 25px;
}
.admin ol.timeline-list li.timeline-item {
  position: relative;
  padding: 5px 15px 15px 45px;
}
.admin ol.timeline-list li.timeline-item:hover .timeline-icon,
.admin ol.timeline-list li.timeline-item:focus .timeline-icon {
  opacity: 1;
}
.admin ol.timeline-list li.timeline-item:after {
  content: "";
  position: absolute;
  display: block;
  left: 18px;
  top: 0;
  height: 100%;
  width: 2px;
  background: #e6e7e8;
}
.admin ol.timeline-list li.timeline-item + .timeline-item {
  border-top: 1px solid #e6e7e8;
}
.admin ol.timeline-list li.timeline-item .timeline-user {
  font-size: 0.85em;
  font-weight: 600;
  color: #414042;
}
.admin ol.timeline-list li.timeline-item .timeline-icon {
  opacity: 0.85;
  z-index: 2;
  position: absolute;
  left: 5px;
  top: 33%;
  background: #414042;
  width: 30px;
  height: 30px;
  line-height: 26px;
  color: #fff;
  text-align: center;
  border-radius: 26px;
  border: 2px solid #e6e7e8;
}
.admin ol.timeline-list li.timeline-item .timeline-date {
  color: #009ebb;
  font-size: 0.85em;
}
.admin .tray-left ol.timeline-list li.timeline-item,
.admin .tray-right ol.timeline-list li.timeline-item {
  position: relative;
  padding: 15px 80px 15px 50px;
}
.admin .bookmark-manager {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
}
.admin .elastic-marquee {
  overflow: hidden;
  transition: 0.1s linear;
}
.admin .elastic-marquee:hover {
  transition: 0.1s linear;
  direction: rtl;
  text-indent: 18px;
}
.gapi-analytics-auth-styles-signinbutton {
  cursor: pointer;
}
.ga-embeds {
  opacity: 0.99;
  position: relative;
  z-index: 0;
}
.ga-embeds > div {
  position: relative;
  z-index: 1;
}
.ga-report {
  box-sizing: content-box;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  padding-right: 17px;
  position: relative;
  display: none;
}
.ga-report h4 {
  letter-spacing: normal;
}
.ga-report .temporary-text {
  font-style: italic;
  opacity: 0.8;
  text-align: center;
}
.ga-report.scrollable {
  max-height: 550px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}
.admin .sites .panel,
.marketing .sites .panel {
  background-color: rgba(255, 255, 255, 0.8);
}
.admin .sites .panel .panel-heading,
.marketing .sites .panel .panel-heading {
  background-color: rgba(255, 255, 255, 0.8);
}
.admin .sites .screenshot-container,
.marketing .sites .screenshot-container {
  height: 300px;
  overflow: hidden;
  padding: 0 !important;
  background-size: cover;
  background-position: top center;
}
.admin {
  /*!
 * FullCalendar v3.9.0
 * Docs & License: https://fullcalendar.io/
 * (c) 2018 Adam Shaw
 */
  /* Colors
--------------------------------------------------------------------------------------------------*/
  /* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
  /* Firefox has an annoying inner border */
  /* icons in buttons */
  /*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
  /* Buttons Groups
--------------------------------------------------------------------------------------------------*/
  /*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
  /* Popover
--------------------------------------------------------------------------------------------------*/
  /* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
  /* Tables
--------------------------------------------------------------------------------------------------*/
  /* Internal Nav Links
--------------------------------------------------------------------------------------------------*/
  /* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
  /* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
  /* highlighting cells & background event skeleton */
  /*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
  /* Scrolling Container
--------------------------------------------------------------------------------------------------*/
  /* TODO: move to agenda/basic */
  /* Global Event Styles
--------------------------------------------------------------------------------------------------*/
  /* resizer (cursor AND touch devices) */
  /* resizer (touch devices) */
  /* hit area */
  /* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
  /* Horizontal Events
--------------------------------------------------------------------------------------------------*/
  /* bigger touch area when selected */
  /* events that are continuing to/from another week. kill rounded corners and butt up against edge */
  /* resizer (cursor AND touch devices) */
  /* left resizer  */
  /* right resizer */
  /* resizer (mouse devices) */
  /* resizer (touch devices) */
  /* left resizer  */
  /* right resizer */
  /* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
  /* resizer (cursor devices) */
  /* left resizer  */
  /* right resizer */
  /* Event Limiting
--------------------------------------------------------------------------------------------------*/
  /* "more" link that represents hidden events */
  /* popover that appears when "more" link is clicked */
  /* Now Indicator
--------------------------------------------------------------------------------------------------*/
  /* Utilities
--------------------------------------------------------------------------------------------------*/
  /*
TODO: more distinction between this file and common.css
*/
  /* Colors
--------------------------------------------------------------------------------------------------*/
  /* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
  /*
Acceptable font-family overrides for individual icons:
  "Arial", sans-serif
  "Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
  /* Popover
--------------------------------------------------------------------------------------------------*/
  /* List View
--------------------------------------------------------------------------------------------------*/
  /* Colors
--------------------------------------------------------------------------------------------------*/
  /* Popover
--------------------------------------------------------------------------------------------------*/
  /* Global Event Styles
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
  /* Popover
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
  /* Popover
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
  /* Toolbar
--------------------------------------------------------------------------------------------------*/
  /* the things within each left/right/center section */
  /* the first thing within each left/center/right section */
  /* title text */
  /* button layering (for border precedence) */
  /* View Structure
--------------------------------------------------------------------------------------------------*/
  /* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
  /* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
  /* BasicView
--------------------------------------------------------------------------------------------------*/
  /* day row structure */
  /* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
  /* week and day number styling */
  /* when week/day number have own column */
  /* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
  /* divs within a cell within the fc-content-skeleton */
  /* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
  /* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
  /* short mode, where time and title are on the same line */
  /* resizer (cursor device) */
  /* resizer (touch device) */
  /* Now Indicator
--------------------------------------------------------------------------------------------------*/
  /* arrow on axis */
  /* List View
--------------------------------------------------------------------------------------------------*/
  /* possibly reusable */
  /* view wrapper */
  /* table resets */
  /* day headings with the list */
  /* event list items */
  /* make the dot closer to the event title */
  /* message when no events */
}
.admin .fc {
  direction: ltr;
  text-align: left;
}
.admin .fc-rtl {
  text-align: right;
}
.admin body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1em;
}
.admin .fc-highlight {
  /* when user is selecting cells */
  background: #bce8f1;
  opacity: 0.3;
}
.admin .fc-bgevent {
  /* default look for background events */
  background: #8fdf82;
  opacity: 0.3;
}
.admin .fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7;
}
.admin .fc button {
  /* force height to include the border and padding */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: 2.1em;
  padding: 0 0.6em;
  /* text & cursor */
  font-size: 1em;
  /* normalize */
  white-space: nowrap;
  cursor: pointer;
}
.admin .fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}
.admin .fc-state-default {
  /* non-theme */
  border: 1px solid;
}
.admin .fc-state-default.fc-corner-left {
  /* non-theme */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.admin .fc-state-default.fc-corner-right {
  /* non-theme */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.admin .fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 0.2em;
  vertical-align: middle;
}
.admin .fc-state-default {
  background-color: #e6e7e8;
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.admin .fc-state-hover,
.admin .fc-state-down,
.admin .fc-state-active,
.admin .fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6;
}
.admin .fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.admin .fc-state-down,
.admin .fc-state-active {
  background-color: #cccccc;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.admin .fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  box-shadow: none;
}
.admin .fc-button-group {
  display: inline-block;
}
.admin .fc .fc-button-group > * {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 0 0 -1px;
}
.admin .fc .fc-button-group > :first-child {
  /* same */
  margin-left: 0;
}
.admin .fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}
.admin .fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px;
}
.admin .fc-popover .fc-header .fc-title {
  margin: 0 2px;
}
.admin .fc-popover .fc-header .fc-close {
  cursor: pointer;
}
.admin .fc-ltr .fc-popover .fc-header .fc-title,
.admin .fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}
.admin .fc-rtl .fc-popover .fc-header .fc-title,
.admin .fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}
.admin .fc-divider {
  border-style: solid;
  border-width: 1px;
}
.admin hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0;
}
.admin .fc-clear {
  clear: both;
}
.admin .fc-bg,
.admin .fc-bgevent-skeleton,
.admin .fc-highlight-skeleton,
.admin .fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.admin .fc-bg {
  bottom: 0;
  /* strech bg to bottom edge */
}
.admin .fc-bg table {
  height: 100%;
  /* strech bg to bottom edge */
}
.admin .fc table {
  width: 100%;
  box-sizing: border-box;
  /* fix scrollbar issue in firefox */
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  /* normalize cross-browser */
}
.admin .fc th {
  text-align: center;
}
.admin .fc th,
.admin .fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}
.admin .fc td.fc-today {
  border-style: double;
  /* overcome neighboring borders */
}
.admin a[data-goto] {
  cursor: pointer;
}
.admin a[data-goto]:hover {
  text-decoration: underline;
}
.admin .fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0;
}
.admin .fc-row table {
  /* don't put left/right border on anything within a fake row.
     the outer tbody will worry about this */
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  /* no bottom borders on rows */
  border-bottom: 0 hidden transparent;
}
.admin .fc-row:first-child table {
  border-top: 0 hidden transparent;
  /* no top border on first row */
}
.admin .fc-row {
  position: relative;
}
.admin .fc-row .fc-bg {
  z-index: 1;
}
.admin .fc-row .fc-bgevent-skeleton,
.admin .fc-row .fc-highlight-skeleton {
  bottom: 0;
  /* stretch skeleton to bottom of row */
}
.admin .fc-row .fc-bgevent-skeleton table,
.admin .fc-row .fc-highlight-skeleton table {
  height: 100%;
  /* stretch skeleton to bottom of row */
}
.admin .fc-row .fc-highlight-skeleton td,
.admin .fc-row .fc-bgevent-skeleton td {
  border-color: transparent;
}
.admin .fc-row .fc-bgevent-skeleton {
  z-index: 2;
}
.admin .fc-row .fc-highlight-skeleton {
  z-index: 3;
}
.admin .fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
  /* matches the space above the events */
}
.admin .fc-row .fc-helper-skeleton {
  z-index: 5;
}
.admin .fc .fc-row .fc-content-skeleton table,
.admin .fc .fc-row .fc-content-skeleton td,
.admin .fc .fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  /* extra precedence to prevent theme-provided backgrounds */
  background: none;
  /* in case <td>s are globally styled */
  border-color: transparent;
}
.admin .fc-row .fc-content-skeleton td,
.admin .fc-row .fc-helper-skeleton td {
  /* don't put a border between events and/or the day number */
  border-bottom: 0;
}
.admin .fc-row .fc-content-skeleton tbody td,
.admin .fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0;
}
.admin .fc-scroller {
  -webkit-overflow-scrolling: touch;
}
.admin .fc-scroller > .fc-day-grid,
.admin .fc-scroller > .fc-time-grid {
  position: relative;
  /* re-scope all positions */
  width: 100%;
  /* hack to force re-sizing this inner element when scrollbars appear/disappear */
}
.admin .fc-event {
  position: relative;
  /* for resize handle and other inner positioning */
  display: block;
  /* make the <a> tag block */
  font-size: 0.85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad;
  /* default BORDER color */
}
.admin .fc-event,
.admin .fc-event-dot {
  background-color: #3a87ad;
  /* default BACKGROUND color */
}
.admin .fc-event,
.admin .fc-event:hover {
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
}
.admin .fc-event[href],
.admin .fc-event.fc-draggable {
  cursor: pointer;
  /* give events with links and draggable events a hand mouse pointer */
}
.admin .fc-not-allowed,
.admin .fc-not-allowed .fc-event {
  /* to override an event's custom cursor */
  cursor: not-allowed;
}
.admin .fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  background: #fff;
  opacity: 0.25;
}
.admin .fc-event .fc-content {
  position: relative;
  z-index: 2;
}
.admin .fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
}
.admin .fc-event .fc-resizer {
  display: none;
}
.admin .fc-event.fc-allow-mouse-resize .fc-resizer,
.admin .fc-event.fc-selected .fc-resizer {
  /* only show when hovering or selected (with touch) */
  display: block;
}
.admin .fc-event.fc-selected .fc-resizer:before {
  /* 40x40 touch area */
  content: "";
  position: absolute;
  z-index: 9999;
  /* user of this util can scope within a lower z-index */
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}
.admin .fc-event.fc-selected {
  z-index: 9999 !important;
  /* overcomes inline z-index */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.admin .fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}
.admin .fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  /* below resizers */
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
}
.admin .fc-ltr .fc-h-event.fc-not-start,
.admin .fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.admin .fc-ltr .fc-h-event.fc-not-end,
.admin .fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.admin .fc-ltr .fc-h-event .fc-start-resizer,
.admin .fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;
  /* overcome border */
}
.admin .fc-ltr .fc-h-event .fc-end-resizer,
.admin .fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;
  /* overcome border */
}
.admin .fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  /* overcome top border */
  bottom: -1px;
  /* overcome bottom border */
}
.admin .fc-h-event.fc-selected .fc-resizer {
  /* 8x8 little dot */
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* vertically center */
  top: 50%;
  margin-top: -4px;
}
.admin .fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.admin .fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px;
  /* centers the 8x8 dot on the left edge */
}
.admin .fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.admin .fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px;
  /* centers the 8x8 dot on the right edge */
}
.admin .fc-day-grid-event {
  margin: 1px 2px 0;
  /* spacing between events and edges */
  padding: 0 1px;
}
.admin tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px;
  /* a little bit more space before the first event */
}
.admin .fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  /* same z-index as fc-bg, behind text */
  /* overcome the borders */
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  /* darkening effect */
  background: #000;
  opacity: 0.25;
}
.admin .fc-day-grid-event .fc-content {
  /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden;
}
.admin .fc-day-grid-event .fc-time {
  font-weight: bold;
}
.admin .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.admin .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px;
  /* to the day cell's edge */
}
.admin .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.admin .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px;
  /* to the day cell's edge */
}
.admin a.fc-more {
  margin: 1px 3px;
  font-size: 0.85em;
  cursor: pointer;
  text-decoration: none;
}
.admin a.fc-more:hover {
  text-decoration: underline;
}
.admin .fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none;
}
.admin .fc-day-grid .fc-row {
  z-index: 1;
  /* make the "more" popover one higher than this */
}
.admin .fc-more-popover {
  z-index: 2;
  width: 220px;
}
.admin .fc-more-popover .fc-event-container {
  padding: 10px;
}
.admin .fc-now-indicator {
  position: absolute;
  border: 0 solid red;
}
.admin .fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.admin .fc-unthemed th,
.admin .fc-unthemed td,
.admin .fc-unthemed thead,
.admin .fc-unthemed tbody,
.admin .fc-unthemed .fc-divider,
.admin .fc-unthemed .fc-row,
.admin .fc-unthemed .fc-content,
.admin .fc-unthemed .fc-popover,
.admin .fc-unthemed .fc-list-view,
.admin .fc-unthemed .fc-list-heading td {
  border-color: #ddd;
}
.admin .fc-unthemed .fc-popover {
  background-color: #fff;
}
.admin .fc-unthemed .fc-divider,
.admin .fc-unthemed .fc-popover .fc-header,
.admin .fc-unthemed .fc-list-heading td {
  background: #eee;
}
.admin .fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666;
}
.admin .fc-unthemed td.fc-today {
  background: #fcf8e3;
}
.admin .fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: 0.3;
}
.admin .fc-icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.admin .fc-icon:after {
  position: relative;
}
.admin .fc-icon-left-single-arrow:after {
  content: "\2039";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}
.admin .fc-icon-right-single-arrow:after {
  content: "\203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}
.admin .fc-icon-left-double-arrow:after {
  content: "\AB";
  font-size: 160%;
  top: -7%;
}
.admin .fc-icon-right-double-arrow:after {
  content: "\BB";
  font-size: 160%;
  top: -7%;
}
.admin .fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%;
}
.admin .fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%;
}
.admin .fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%;
}
.admin .fc-icon-x:after {
  content: "\D7";
  font-size: 200%;
  top: 6%;
}
.admin .fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid;
}
.admin .fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: 0.9em;
  margin-top: 2px;
}
.admin .fc-unthemed .fc-list-item:hover td {
  background-color: #f5f5f5;
}
.admin .ui-widget .fc-disabled-day {
  background-image: none;
}
.admin .fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
  /* where they meet, let the header have the border */
}
.admin .ui-widget .fc-event {
  /* overpower jqui's styles on <a> tags. TODO: more DRY */
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
  /* undo ui-widget-header bold */
  font-weight: normal;
}
.admin .ui-widget td.fc-axis {
  font-weight: normal;
  /* overcome bold */
}
.admin .fc-time-grid .fc-slats .ui-widget-content {
  background: none;
  /* see through to fc-bg */
}
.admin .fc.fc-bootstrap3 a {
  text-decoration: none;
}
.admin .fc.fc-bootstrap3 a[data-goto]:hover {
  text-decoration: underline;
}
.admin .fc-bootstrap3 hr.fc-divider {
  border-color: inherit;
}
.admin .fc-bootstrap3 .fc-today.alert {
  border-radius: 0;
}
.admin .fc-bootstrap3 .fc-popover .panel-body {
  padding: 0;
}
.admin .fc-bootstrap3 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none;
}
.admin .fc.fc-bootstrap4 a {
  text-decoration: none;
}
.admin .fc.fc-bootstrap4 a[data-goto]:hover {
  text-decoration: underline;
}
.admin .fc-bootstrap4 hr.fc-divider {
  border-color: inherit;
}
.admin .fc-bootstrap4 .fc-today.alert {
  border-radius: 0;
}
.admin .fc-bootstrap4 a.fc-event:not([href]):not([tabindex]) {
  color: #fff;
}
.admin .fc-bootstrap4 .fc-popover.card {
  position: absolute;
}
.admin .fc-bootstrap4 .fc-popover .card-body {
  padding: 0;
}
.admin .fc-bootstrap4 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none;
}
.admin .fc-toolbar {
  text-align: center;
}
.admin .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em;
}
.admin .fc-toolbar.fc-footer-toolbar {
  margin-top: 1em;
}
.admin .fc-toolbar .fc-left {
  float: left;
}
.admin .fc-toolbar .fc-right {
  float: right;
}
.admin .fc-toolbar .fc-center {
  display: inline-block;
}
.admin .fc .fc-toolbar > * > * {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: 0.75em;
}
.admin .fc .fc-toolbar > * > :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0;
}
.admin .fc-toolbar h2 {
  margin: 0;
  letter-spacing: 0.1em;
}
.admin .fc-toolbar button {
  position: relative;
}
.admin .fc-toolbar .fc-state-hover,
.admin .fc-toolbar .ui-state-hover {
  z-index: 2;
}
.admin .fc-toolbar .fc-state-down {
  z-index: 3;
}
.admin .fc-toolbar .fc-state-active,
.admin .fc-toolbar .ui-state-active {
  z-index: 4;
}
.admin .fc-toolbar button:focus {
  z-index: 5;
}
.admin .fc-view-container *,
.admin .fc-view-container *:before,
.admin .fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.admin .fc-view,
.admin .fc-view > table {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1;
}
.admin .fc-basicWeek-view .fc-content-skeleton,
.admin .fc-basicDay-view .fc-content-skeleton {
  /* there may be week numbers in these views, so no padding-top */
  padding-bottom: 1em;
  /* ensure a space at bottom of cell for user selecting/clicking */
}
.admin .fc-basic-view .fc-body .fc-row {
  min-height: 4em;
  /* ensure that all rows are at least this tall */
}
.admin .fc-row.fc-rigid {
  overflow: hidden;
}
.admin .fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.admin .fc-day-top.fc-other-month {
  opacity: 0.3;
}
.admin .fc-basic-view .fc-week-number,
.admin .fc-basic-view .fc-day-number {
  padding: 2px;
}
.admin .fc-basic-view th.fc-week-number,
.admin .fc-basic-view th.fc-day-number {
  padding: 0 2px;
  /* column headers can't have as much v space */
}
.admin .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right;
}
.admin .fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
  float: left;
}
.admin .fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
  float: left;
  border-radius: 0 0 3px;
}
.admin .fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
  float: right;
  border-radius: 0 0 0 3px;
}
.admin .fc-basic-view .fc-day-top .fc-week-number {
  min-width: 1.5em;
  text-align: center;
  background-color: #f2f2f2;
  color: #808080;
}
.admin .fc-basic-view td.fc-week-number {
  text-align: center;
}
.admin .fc-basic-view td.fc-week-number > * {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em;
}
.admin .fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
  /* so the "more.." popover will be over the time grid */
}
.admin .fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
  /* all-day section will never get shorter than this */
}
.admin .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1em;
  /* give space underneath events for clicking/selecting days */
}
.admin .fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap;
}
.admin .fc-ltr .fc-axis {
  text-align: right;
}
.admin .fc-rtl .fc-axis {
  text-align: left;
}
.admin .fc-time-grid-container,
.admin .fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1;
}
.admin .fc-time-grid {
  min-height: 100%;
  /* so if height setting is 'auto', .fc-bg stretches to fill height */
}
.admin .fc-time-grid table {
  /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent;
}
.admin .fc-time-grid > .fc-bg {
  z-index: 1;
}
.admin .fc-time-grid .fc-slats,
.admin .fc-time-grid > hr {
  /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2;
}
.admin .fc-time-grid .fc-content-col {
  position: relative;
  /* because now-indicator lives directly inside */
}
.admin .fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}
.admin .fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1;
}
.admin .fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2;
}
.admin .fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3;
}
.admin .fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4;
}
.admin .fc-time-grid .fc-now-indicator-line {
  z-index: 5;
}
.admin .fc-time-grid .fc-helper-container {
  /* also is fc-event-container */
  position: relative;
  z-index: 6;
}
.admin .fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
  /* each cell is responsible for its top border */
}
.admin .fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}
.admin .fc-time-grid .fc-highlight-container {
  /* a div within a cell within the fc-highlight-skeleton */
  position: relative;
  /* scopes the left/right of the fc-highlight to be in the column */
}
.admin .fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */
}
.admin .fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px;
}
.admin .fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%;
}
.admin .fc-time-grid .fc-event,
.admin .fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  /* scope inner z-index's */
}
.admin .fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0;
}
.admin .fc-v-event.fc-not-start {
  /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.admin .fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.admin .fc-time-grid-event {
  overflow: hidden;
  /* don't let the bg flow over rounded corners */
}
.admin .fc-time-grid-event.fc-selected {
  /* need to allow touch resizers to extend outside event's bounding box */
  /* common fc-selected styles hide the fc-bg, so don't need this anyway */
  overflow: visible;
}
.admin .fc-time-grid-event.fc-selected .fc-bg {
  display: none;
  /* hide semi-white background, to appear darker */
}
.admin .fc-time-grid-event .fc-content {
  overflow: hidden;
  /* for when .fc-selected */
}
.admin .fc-time-grid-event .fc-time,
.admin .fc-time-grid-event .fc-title {
  padding: 0 1px;
}
.admin .fc-time-grid-event .fc-time {
  font-size: 0.85em;
  white-space: nowrap;
}
.admin .fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap;
}
.admin .fc-time-grid-event.fc-short .fc-time,
.admin .fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top;
}
.admin .fc-time-grid-event.fc-short .fc-time span {
  display: none;
  /* don't display the full time text... */
}
.admin .fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
  /* ...instead, display only the start time */
}
.admin .fc-time-grid-event.fc-short .fc-time:after {
  content: "\A0-\A0";
  /* seperate with a dash, wrapped in nbsp's */
}
.admin .fc-time-grid-event.fc-short .fc-title {
  font-size: 0.85em;
  /* make the title text the same size as the time */
  padding: 0;
  /* undo padding from above */
}
.admin .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}
.admin .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "=";
}
.admin .fc-time-grid-event.fc-selected .fc-resizer {
  /* 10x10 dot */
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* horizontally center */
  left: 50%;
  margin-left: -5px;
  /* center on the bottom edge */
  bottom: -5px;
}
.admin .fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0;
}
.admin .fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px;
  /* vertically center on top coordinate */
}
.admin .fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  /* triangle pointing right... */
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.admin .fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  /* triangle pointing left... */
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.admin .fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.admin .fc-rtl .fc-list-view {
  direction: rtl;
  /* unlike core views, leverage browser RTL */
}
.admin .fc-list-view {
  border-width: 1px;
  border-style: solid;
}
.admin .fc .fc-list-table {
  table-layout: auto;
  /* for shrinkwrapping cell content */
}
.admin .fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px;
}
.admin .fc-list-table tr:first-child td {
  border-top-width: 0;
}
.admin .fc-list-heading {
  border-bottom-width: 1px;
}
.admin .fc-list-heading td {
  font-weight: bold;
}
.admin .fc-ltr .fc-list-heading-main {
  float: left;
}
.admin .fc-ltr .fc-list-heading-alt {
  float: right;
}
.admin .fc-rtl .fc-list-heading-main {
  float: right;
}
.admin .fc-rtl .fc-list-heading-alt {
  float: left;
}
.admin .fc-list-item.fc-has-url {
  cursor: pointer;
  /* whole row will be clickable */
}
.admin .fc-list-item-marker,
.admin .fc-list-item-time {
  white-space: nowrap;
  width: 1px;
}
.admin .fc-ltr .fc-list-item-marker {
  padding-right: 0;
}
.admin .fc-rtl .fc-list-item-marker {
  padding-left: 0;
}
.admin .fc-list-item-title a {
  /* every event title cell has an <a> tag */
  text-decoration: none;
  color: inherit;
}
.admin .fc-list-item-title a[href]:hover {
  /* hover effect only on titles with hrefs */
  text-decoration: underline;
}
.admin .fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.admin .fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table;
}
.admin .fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.admin .fc-unthemed .fc-list-empty {
  /* theme will provide own background */
  background-color: #eee;
}
.admin .health h2,
.marketing .health h2,
.admin .health h5,
.marketing .health h5 {
  letter-spacing: normal;
}
.admin .health .panel-tile,
.marketing .health .panel-tile {
  position: relative;
  overflow: hidden;
}
.admin .health .panel-tile .panel-body,
.marketing .health .panel-tile .panel-body,
.admin .health .panel-tile .panel-footer,
.marketing .health .panel-tile .panel-footer {
  border: 0;
}
.admin .health .icon-bg,
.marketing .health .icon-bg {
  position: absolute;
  opacity: 0.25;
  right: 0;
  top: 0;
  line-height: 100px;
  font-size: 100px;
}
.admin .health .icon-bg.left,
.marketing .health .icon-bg.left {
  right: auto;
  left: 0;
}
.admin .health .panel-default .panel-body,
.marketing .health .panel-default .panel-body {
  padding: 0 0 30px;
}
.admin .health .panel-default .list-group,
.marketing .health .panel-default .list-group {
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.admin .health .panel-default .list-group .list-group-item,
.marketing .health .panel-default .list-group .list-group-item {
  border: 1px solid #cfcfd0;
  border-left: none;
  border-radius: 0;
  border-right: none;
  padding: 0;
}
.admin .health .panel-default .list-group .list-group-item .item,
.marketing .health .panel-default .list-group .list-group-item .item {
  height: 60px;
  padding: 0 20px 0 35px;
  overflow: hidden;
  position: relative;
}
.admin .health .panel-default .list-group .list-group-item .item.extra-height,
.marketing .health .panel-default .list-group .list-group-item .item.extra-height {
  height: 91.2px;
}
.admin .health .panel-default .list-group .list-group-item .item.extra-height > .item-stat,
.marketing .health .panel-default .list-group .list-group-item .item.extra-height > .item-stat {
  line-height: 91.2px;
}
.admin .health .panel-default .list-group .list-group-item .item .item-message,
.marketing .health .panel-default .list-group .list-group-item .item .item-message {
  float: left;
  font-size: 1em;
  line-height: 60px;
}
.admin .health .panel-default .list-group .list-group-item .item .item-message > .main,
.marketing .health .panel-default .list-group .list-group-item .item .item-message > .main {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 0.95em;
  line-height: 33.33333333px;
}
.admin .health .panel-default .list-group .list-group-item .item .item-message > .secondary,
.marketing .health .panel-default .list-group .list-group-item .item .item-message > .secondary {
  font-size: 0.8em;
  line-height: 27.27272727px;
}
.admin .health .panel-default .list-group .list-group-item .item .item-stat,
.marketing .health .panel-default .list-group .list-group-item .item .item-stat {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  float: right;
  font-size: 1.5em;
  line-height: 60px;
}
.admin .health .panel-default .list-group .list-group-item .item .item-stat.small-text,
.marketing .health .panel-default .list-group .list-group-item .item .item-stat.small-text {
  font-size: 0.95em;
}
.admin .health .panel-default .list-group .list-group-item .item.danger,
.marketing .health .panel-default .list-group .list-group-item .item.danger {
  border-left: 5px solid #FF3932;
}
.admin .health .panel-default .list-group .list-group-item .item.danger .item-stat,
.marketing .health .panel-default .list-group .list-group-item .item.danger .item-stat {
  color: #FF3932;
}
.admin .health .panel-default .list-group .list-group-item .item.dark,
.marketing .health .panel-default .list-group .list-group-item .item.dark {
  border-left: 5px solid #414042;
}
.admin .health .panel-default .list-group .list-group-item .item.dark .item-stat,
.marketing .health .panel-default .list-group .list-group-item .item.dark .item-stat {
  color: #414042;
}
.admin .health .panel-default .list-group .list-group-item .item.info,
.marketing .health .panel-default .list-group .list-group-item .item.info {
  border-left: 5px solid #33c3e0;
}
.admin .health .panel-default .list-group .list-group-item .item.info .item-stat,
.marketing .health .panel-default .list-group .list-group-item .item.info .item-stat {
  color: #33c3e0;
}
.admin .health .panel-default .list-group .list-group-item .item.primary,
.marketing .health .panel-default .list-group .list-group-item .item.primary {
  border-left: 5px solid #009ebb;
}
.admin .health .panel-default .list-group .list-group-item .item.primary .item-stat,
.marketing .health .panel-default .list-group .list-group-item .item.primary .item-stat {
  color: #009ebb;
}
.admin .health .panel-default .list-group .list-group-item .item.warning,
.marketing .health .panel-default .list-group .list-group-item .item.warning {
  border-left: 5px solid #FFB200;
}
.admin .health .panel-default .list-group .list-group-item .item.warning .item-stat,
.marketing .health .panel-default .list-group .list-group-item .item.warning .item-stat {
  color: #FFB200;
}
.admin .health .panel-default .list-group .list-group-item .item.success,
.marketing .health .panel-default .list-group .list-group-item .item.success {
  border-left: 5px solid #3DA323;
}
.admin .health .panel-default .list-group .list-group-item .item.success .item-stat,
.marketing .health .panel-default .list-group .list-group-item .item.success .item-stat {
  color: #3DA323;
}
#rolePermissionMatrix {
  overflow: hidden;
}
#rolePermissionMatrix > tbody > tr > td,
#rolePermissionMatrix > tbody > tr > th,
#rolePermissionMatrix > tfoot > tr > td,
#rolePermissionMatrix > tfoot > tr > th,
#rolePermissionMatrix > thead > tr > td,
#rolePermissionMatrix > thead > tr > th {
  padding: 3px;
  height: 0px;
}
#rolePermissionMatrix th {
  border-bottom: 1px solid #e6e7e8;
}
#rolePermissionMatrix td {
  border-bottom: 1px solid #e6e7e8;
  border-right: 1px solid #e6e7e8;
}
#rolePermissionMatrix tbody tr:hover {
  background-color: rgba(177, 209, 51, 0.1);
}
#rolePermissionMatrix tbody td,
#rolePermissionMatrix tbody th {
  position: relative;
}
#rolePermissionMatrix tbody td:hover::after {
  content: "";
  position: absolute;
  background-color: rgba(177, 209, 51, 0.1);
  left: 0;
  top: -5000px;
  height: 10000px;
  width: 100%;
  z-index: -1;
}
#rolePermissionMatrix tbody td:empty:hover::after,
#rolePermissionMatrix tbody th:empty:hover::after {
  background: none;
}
#rolePermissionMatrix .no-border {
  border: none;
}
#rolePermissionMatrix .permission-name {
  min-width: 300px;
  border-left: 1px solid #e6e7e8;
  border-right: 1px solid #e6e7e8;
  padding-left: 5px;
}
#rolePermissionMatrix .system-role {
  color: #44565e;
}
#rolePermissionMatrix .group {
  color: #414042;
}
#rolePermissionMatrix .header {
  height: 140px;
  white-space: nowrap;
  border: 0;
  background-color: #ffffff;
}
#rolePermissionMatrix .header > div {
  transform: rotate(315deg);
  width: 32px;
}
#rolePermissionMatrix .header > div > span {
  padding: 16px 8px;
}
.admin .groups .panel-default .selectize-control .selectize-input {
  -webkit-box-shadow: inset 3px 3px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 3px 3px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
  border: 0;
  cursor: default;
}
.admin .groups .panel-default .selectize-control .selectize-input .item {
  background-color: #009ebb;
  padding: 1px;
  margin: 1px;
  color: #ffffff;
  border-radius: 0;
  font-size: x-small;
}
.admin .groups .panel-default .selectize-control .selectize-input .item .remove {
  background-color: #0088a2;
}
.admin .groups .panel-default .selectize-control .selectize-input .item.active {
  background-color: #33c3e0;
  -webkit-box-shadow: inset 0px 0px 0px 1px #b1d133;
  -moz-box-shadow: inset 0px 0px 0px 1px #b1d133;
  box-shadow: inset 0px 0px 0px 1px #b1d133;
}
.admin .groups .panel-default .selectize-control .selectize-input .item.active .remove {
  background-color: #21bad9;
  -webkit-box-shadow: inset 0px 0px 0px 1px #b1d133;
  -moz-box-shadow: inset 0px 0px 0px 1px #b1d133;
  box-shadow: inset 0px 0px 0px 1px #b1d133;
}
.admin .groups .panel-default .selectize-dropdown-content {
  -webkit-box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: auto;
  left: auto;
  width: inherit;
  background-color: #ffffff;
}
.admin .admin-social .panel-default ul.providers {
  border-right: 1px solid #cfcfd0;
}
.admin .admin-social .panel-default ul.providers li > a {
  color: #666;
}
.admin .admin-social .panel-default ul.providers li > a:hover,
.admin .admin-social .panel-default ul.providers li > a:active,
.admin .admin-social .panel-default ul.providers li > a:focus {
  background: none;
}
.admin .admin-social .panel-default ul.providers li > a:hover {
  color: #009ebb;
}
.admin .admin-social .panel-default ul.providers li > a > span {
  vertical-align: middle;
}
.admin .admin-social .panel-default.social-workspace {
  min-height: 500px;
}
.admin .admin-social .panel-default.social-workspace ol {
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;
}
.admin .admin-social .panel-default.social-workspace ol > li {
  margin: 0;
  padding: 0 0 0 2em;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
  font-size: 100%;
}
.admin .admin-social .panel-default.social-workspace ol > li:before {
  display: inline-block;
  width: 1.5em;
  padding-right: 0.5em;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: right;
  content: counter(item) ".";
}
.admin .admin-social .panel-default.social-workspace h4.provider-title {
  letter-spacing: 0.1em;
  color: #666;
}
.admin .admin-social .panel-default.social-workspace div.instructions {
  border-left: 1px dotted #cfcfd0;
}
.admin .admin-social pre.json {
  border: 0;
  border-radius: 0;
  background: none;
}
.admin .admin-social pre.json .string {
  color: #009ebb;
}
.admin .admin-social pre.json .number {
  color: #FFB200;
}
.admin .admin-social pre.json .boolean-true {
  color: #3DA323;
}
.admin .admin-social pre.json .boolean-false {
  color: #FF3932;
}
.admin .admin-social pre.json .null {
  color: #e6e7e8;
}
.admin .admin-social pre.json .key {
  color: #44565e;
  font-weight: bold;
}
.admin .admin-social div.social-text {
  color: #333;
}
.admin .admin-social div.social-text .hashtag {
  color: #777f8c;
}
.admin .admin-social div.social-text .link {
  color: #009ebb;
}
.admin .admin-social div.media-row {
  background-size: cover;
  background-position: center center;
  width: 360px;
  height: 240px;
}
.admin .admin-social div.media-tags {
  width: 300px;
  min-width: 300px;
}
.admin .admin-social .selectize-control .selectize-input {
  -webkit-box-shadow: inset 3px 3px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 3px 3px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
  border: 0;
  cursor: default;
}
.admin .admin-social .selectize-control .selectize-input .item {
  background-color: #009ebb;
  padding: 3px 5px;
  margin: 2px;
  color: #ffffff;
  border-radius: 0;
}
.admin .admin-social .selectize-control .selectize-input .item .remove {
  background-color: #0088a2;
}
.admin .admin-social .selectize-control .selectize-input .item.active {
  background-color: #33c3e0;
  -webkit-box-shadow: inset 0px 0px 0px 1px #b1d133;
  -moz-box-shadow: inset 0px 0px 0px 1px #b1d133;
  box-shadow: inset 0px 0px 0px 1px #b1d133;
}
.admin .admin-social .selectize-control .selectize-input .item.active .remove {
  background-color: #21bad9;
  -webkit-box-shadow: inset 0px 0px 0px 1px #b1d133;
  -moz-box-shadow: inset 0px 0px 0px 1px #b1d133;
  box-shadow: inset 0px 0px 0px 1px #b1d133;
}
.brand-twitter {
  color: #1da1f2;
}
.brand-facebook {
  color: #3b5998;
}
.brand-google-plus {
  color: #dd4b39;
}
.brand-linkedin {
  color: #0077b5;
}
.brand-youtube {
  color: #cd201f;
}
.brand-analytics {
  color: #4285f4;
}
.brand-flickr {
  color: #ff0084;
}
.brand-pinterest {
  color: #bd081c;
}
.brand-vimeo {
  color: #1ab7ea;
}
.brand-instagram {
  color: #c13584;
}
@supports (-webkit-appearance: none) {
  /* HACK, this rule will apply a gradient only in browsers that support text-clipping */
  .brand-instagram:before {
    background: linear-gradient(135deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d, #f56040, #f77737, #fcaf45, #ffdc80);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.active span[class*="brand-"] {
  color: white;
}
.active span[class*="brand-"]:before {
  background: none;
  background-clip: initial;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}
.admin .page-content #eToolbar {
  min-width: 650px;
  display: none;
}
.admin .emergency-alerts .active-alert-panel {
  box-shadow: inset 6px 6px 0 0 #e5e5e5;
  border: 1px solid #cfcfd0;
  border-radius: 0;
  color: #009ebb;
  font-size: 1em;
  padding: 1em;
}
.admin .emergency-alerts .active-alert-panel .title {
  font-weight: 600;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 0.5em;
}
.admin .emergency-alerts .active-alert-panel .table tr > th {
  letter-spacing: 2px;
  width: 15%;
}
.admin .workspace ul.album-tag-list {
  list-style: none;
}
.admin .workspace ul.album-tag-list li {
  display: inline;
}
.admin .workspace ul.album-tag-list li:not(:last-child)::after {
  content: ', ';
}
.admin-media .show-on-select {
  display: none;
}
.admin-media .selected .show-on-select {
  display: inline-block;
}
.admin-media .bold {
  font-weight: bold;
}
.admin-media .tab-pane {
  margin: 2em 0;
  background: transparent;
}
.admin-media .tags,
.admin-media .details,
.admin-media .edit-focus,
.admin-media .edit-focus-preview {
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  height: 100%;
  margin-bottom: 15px;
  padding: 15px;
}
.admin-media .upload-panel .tags .btn,
.admin-media .collections-panel .tags .btn {
  display: block;
  width: 100%;
}
.admin-media .library-panel .tags h4 {
  float: left;
}
.admin-media .library-panel .tags .btn {
  float: right;
}
.admin-media .tag-list {
  clear: both;
  list-style-type: none;
  padding-left: 0;
  position: relative;
}
.admin-media .tag-list.form-group {
  max-width: none;
  width: 100%;
}
.admin-media .tag-list li {
  font-size: 1.2em;
  margin: 0.5em 0;
}
.admin-media .tag-list .form-control {
  padding-left: 0;
}
.admin-media .image-tag-list {
  position: relative;
}
.admin-media .image-tag-list .fa,
.admin-media .image-tag-list .fal {
  color: #006a7e;
  left: 12px;
  position: absolute;
  top: 12px;
}
.admin-media .media-library,
.admin-media .media-library .row {
  position: relative;
}
.admin-media .col section,
.admin-media .col aside {
  background: #ffffff;
}
.admin-media .library-view {
  position: relative;
}
.admin-media .library-items {
  margin: 0;
  padding: 4px;
  min-height: 400px;
  position: relative;
}
.admin-media .library-items > .col {
  padding: 0;
}
.admin-media .media-image-sort,
.admin-media .media-video-sort {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
}
.admin-media .media-image-sort > span,
.admin-media .media-video-sort > span {
  font-size: 1.2em;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  color: #33c3e0;
  display: none;
  opacity: 0;
  transition: opacity 0.3s linear;
}
.admin-media .media-image-sort > span + .tooltip .tooltip-inner,
.admin-media .media-video-sort > span + .tooltip .tooltip-inner {
  background-color: #009ebb;
}
.admin-media .media-image-sort > span + .tooltip.top .tooltip-arrow,
.admin-media .media-video-sort > span + .tooltip.top .tooltip-arrow {
  border-top-color: #009ebb;
}
.admin-media .media-image-sort > span + .tooltip.bottom .tooltip-arrow,
.admin-media .media-video-sort > span + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #009ebb;
}
.admin-media .media-image-sort > span + .tooltip.right .tooltip-arrow,
.admin-media .media-video-sort > span + .tooltip.right .tooltip-arrow {
  border-right-color: #009ebb;
}
.admin-media .media-image-sort > span + .tooltip.left .tooltip-arrow,
.admin-media .media-video-sort > span + .tooltip.left .tooltip-arrow {
  border-left-color: #009ebb;
}
.admin-media .media-image-sort > span:hover,
.admin-media .media-video-sort > span:hover,
.admin-media .media-image-sort > span:focus,
.admin-media .media-video-sort > span:focus,
.admin-media .media-image-sort > span:active,
.admin-media .media-video-sort > span:active {
  color: #009ebb;
}
.admin-media .media-image-sort.show-order > select,
.admin-media .media-video-sort.show-order > select {
  width: calc(100% -  30px);
  margin-left: 30px;
}
.admin-media .media-image-sort.show-order > span,
.admin-media .media-video-sort.show-order > span {
  background: #009ebb;
  display: block;
  height: 34px;
  left: 15px;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 30px;
}
.admin-media .media-image-sort.show-order > span.fa:before,
.admin-media .media-video-sort.show-order > span.fa:before,
.admin-media .media-image-sort.show-order > span.fal:before,
.admin-media .media-video-sort.show-order > span.fal:before {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.admin-media select > option[role="separator"] {
  font-size: 0.666em;
  color: rgba(0, 0, 0, 0.2);
}
.admin-media .media-list-loading {
  visibility: hidden;
  white-space: nowrap;
}
.admin-media .media-list-loading p,
.admin-media .media-list-loading h5,
.admin-media .media-list-loading h4,
.admin-media .media-list-loading h3,
.admin-media .media-list-loading h2,
.admin-media .media-list-loading h1 {
  display: inline-block;
  margin-top: 0;
}
.admin-media .media-list-loading.show-loading {
  visibility: visible;
}
.admin-media .media-list-controls {
  width: 100%;
  position: relative;
}
.admin-media .media-list-controls.full-width-toolbar {
  width: 95%;
  position: absolute;
}
.admin-media .media-list-controls .form-group {
  max-width: none;
  margin-bottom: 8px;
}
.admin-media .media-list-controls .form-group.select-wrapper .select-icon {
  height: 34px;
  width: 34px;
}
.admin-media .media-list-controls .form-control {
  margin-bottom: 8px;
  height: 34px;
  padding-left: 24px;
  max-width: none;
}
.admin-media .media-list-controls select.form-control {
  padding-left: 19px;
}
.admin-media .media-list-controls .form-control-feedback {
  background: #009ebb;
  cursor: pointer;
  height: 34px;
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}
.admin-media .media-list-controls .form-control-feedback.fa-search:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}
.admin-media .media-list-controls button {
  height: 34px;
  width: 100%;
}
.admin-media .media-list-container {
  margin-top: 40px;
}
.admin-media .media-item-controls {
  transition: opacity 0.3s ease;
  opacity: 0;
  height: 0;
  max-height: 0;
  line-height: 0;
  overflow: hidden;
  margin: 0;
}
.admin-media .media-item-controls.inline {
  display: inline-block;
  padding: 0 0 0 0.5em;
}
.admin-media .media-item-controls.inline .btn .at-icon {
  vertical-align: top;
  font-size: 1.2em;
}
.admin-media .media-item-controls .icon-link {
  display: inline;
  margin: 0 2px;
}
.admin-media .media-item-controls .icon-link .fa,
.admin-media .media-item-controls .icon-link .fal {
  width: 20px;
}
.admin-media .media-item-controls .icon-link button,
.admin-media .media-item-controls .icon-link a {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  text-align: left;
  color: #33c3e0;
}
.admin-media .media-item-controls .icon-link:hover,
.admin-media .media-item-controls .icon-link:focus,
.admin-media .media-item-controls .icon-link:active {
  color: #009ebb;
  outline: none;
}
.admin-media .media-item-controls .icon-spacer {
  color: #cfcfd0;
  display: inline;
  margin: 0 0.2em;
}
.admin-media .media-item-controls-selected {
  opacity: 1;
  height: auto;
  max-height: 2em;
  line-height: auto;
  overflow: initial;
}
.admin-media .item-control + .tooltip .tooltip-inner,
.admin-media .media-control + .tooltip .tooltip-inner {
  background-color: #009ebb;
}
.admin-media .item-control + .tooltip.top .tooltip-arrow,
.admin-media .media-control + .tooltip.top .tooltip-arrow {
  border-top-color: #009ebb;
}
.admin-media .item-control + .tooltip.bottom .tooltip-arrow,
.admin-media .media-control + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #009ebb;
}
.admin-media .item-control + .tooltip.right .tooltip-arrow,
.admin-media .media-control + .tooltip.right .tooltip-arrow {
  border-right-color: #009ebb;
}
.admin-media .item-control + .tooltip.left .tooltip-arrow,
.admin-media .media-control + .tooltip.left .tooltip-arrow {
  border-left-color: #009ebb;
}
.admin-media .media-see-more {
  margin: 8px 0 100px 8px;
  max-width: initial;
}
.admin-media .media-see-more button {
  margin-right: 4px;
}
.admin-media select.media-album-list-image,
.admin-media select.media-album-list-video,
.admin-media select.media-tag-list-image,
.admin-media select.media-tag-list-video {
  background: none;
  outline: none;
  border: 1px solid #ffffff;
  color: #44565e;
  white-space: normal;
  width: 100%;
  overflow: visible;
  height: 24px;
  display: inline-block;
}
.admin-media select.media-album-list-image option,
.admin-media select.media-album-list-video option,
.admin-media select.media-tag-list-image option,
.admin-media select.media-tag-list-video option {
  background-color: #ffffff;
  background-image: linear-gradient(#e6e7e8, #e6e7e8);
  border: 1px solid #ffffff;
  color: #44565e;
  -webkit-text-fill-color: #44565e;
  display: none;
  padding: 2px;
  margin-right: 0.5em;
}
.admin-media select.media-album-list-image option:checked,
.admin-media select.media-album-list-video option:checked,
.admin-media select.media-tag-list-image option:checked,
.admin-media select.media-tag-list-video option:checked {
  display: inline;
}
.admin-media .selectize-control .selectize-input {
  border-radius: 0;
  border: 0;
  cursor: default;
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}
.admin-media .selectize-control .selectize-input input:disabled::-webkit-input-placeholder {
  color: transparent;
}
.admin-media .selectize-control .selectize-input input:disabled:-moz-placeholder {
  color: transparent;
}
.admin-media .selectize-control .selectize-input input:disabled::-moz-placeholder {
  color: transparent;
}
.admin-media .selectize-control .selectize-input input:disabled:-ms-input-placeholder {
  color: transparent;
}
.admin-media .selectize-control .selectize-input.disabled {
  opacity: 1;
}
.admin-media .selectize-control .selectize-input.disabled .item .remove {
  display: none;
}
.admin-media .selectize-control .selectize-input .item {
  background-color: #009ebb;
  padding: 3px 5px;
  margin-left: 2px;
  margin-right: 2px;
  color: white;
  border-radius: 0;
}
.admin-media .selectize-control .selectize-input .item .remove {
  display: inline-block;
  background-color: #0088a2;
}
.admin-media .selectize-control .selectize-input .item.active {
  background-color: #33c3e0;
  -webkit-box-shadow: inset 0 0 0 1px #33c3e0;
  -moz-box-shadow: inset 0 0 0 1px #33c3e0;
  box-shadow: inset 0 0 0 1px #33c3e0;
}
.admin-media .selectize-control .selectize-input .item.active .remove {
  background-color: #21bad9;
  -webkit-box-shadow: inset 0 0 0 1px #33c3e0;
  -moz-box-shadow: inset 0 0 0 1px #33c3e0;
  box-shadow: inset 0 0 0 1px #33c3e0;
}
.admin-media .library-view .library-filter {
  margin-bottom: 15px;
}
.admin-media .library-view .col-sm-2 .item .item-hover .text {
  display: none;
}
.admin-media .library-view .item-wrapper {
  margin: 8px;
  position: relative;
  z-index: 0;
}
.admin-media .library-view .item-wrapper .item-preview {
  width: 180px;
  float: left;
}
.admin-media .library-view .item-wrapper .item-details {
  float: left;
  margin-right: -180px;
  padding-top: 2px;
  width: 77%;
}
.admin-media .library-view .item-wrapper .item-details table {
  width: 100%;
}
.admin-media .library-view .item-wrapper .item-details tr th {
  width: 8em;
  overflow: hidden;
  vertical-align: middle;
}
.admin-media .library-view .item-wrapper .item-preview .item {
  -webkit-box-shadow: inset 5px 5px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 5px 5px 0 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 2px solid #e6e7e8;
  height: 120px;
  width: calc(120px + 10px);
  overflow: hidden;
  position: relative;
  transition: border 0.3s ease;
}
.admin-media .library-view .item-wrapper .item-preview .item .image {
  background: #e6e7e8;
  height: 120px;
  position: relative;
  z-index: -1;
}
.admin-media .library-view .item-wrapper .item-preview .item .pos-img {
  display: block;
  opacity: 1;
  position: relative;
  transition: opacity 0.3s ease;
}
.admin-media .library-view .item-wrapper .item-preview .item .img-src {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.admin-media .library-view .item-wrapper .item-preview .item .img-loader {
  display: block;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -27px;
  position: absolute;
  top: 50%;
}
.admin-media .library-view .item-wrapper .item-check {
  background: #009ebb;
  border-radius: 24px;
  bottom: 12px;
  color: #ffffff;
  display: none;
  height: 24px;
  font-size: 12px;
  position: absolute;
  right: 12px;
  text-align: center;
  width: 24px;
}
.admin-media .library-view .item-wrapper .item-check span {
  line-height: 24px;
}
.admin-media .library-view .item-wrapper .item-hover {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  background: #333;
  bottom: 0;
  left: 0;
  letter-spacing: 0.15em;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity 0.3s ease;
  opacity: 0;
}
.admin-media .library-view .item-wrapper .item-hover a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}
.admin-media .library-view .item-wrapper .item-hover a:hover,
.admin-media .library-view .item-wrapper .item-hover a:active,
.admin-media .library-view .item-wrapper .item-hover a:focus {
  color: #009ebb;
}
.admin-media .library-view .item-wrapper:hover .item-hover {
  opacity: 1;
}
.admin-media .library-view .item-wrapper .item-hover-wrap {
  padding: 0 2em;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
}
.admin-media .library-view .item-wrapper:hover .item-preview .item {
  border: 2px solid #009ebb;
}
.admin-media .library-view .item-wrapper .media-warning {
  vertical-align: top;
  font-size: 1.2em;
  color: #ffcc00;
}
.admin-media .library-view .item-wrapper .media-warning + .tooltip .tooltip-inner {
  background-color: #009ebb;
}
.admin-media .library-view .item-wrapper .media-warning + .tooltip.top .tooltip-arrow {
  border-top-color: #009ebb;
}
.admin-media .library-view .item-wrapper .media-warning + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #009ebb;
}
.admin-media .library-view .item-wrapper .media-warning + .tooltip.right .tooltip-arrow {
  border-right-color: #009ebb;
}
.admin-media .library-view .item-wrapper .media-warning + .tooltip.left .tooltip-arrow {
  border-left-color: #009ebb;
}
.admin-media .library-view .item-wrapper .media-item-controls {
  padding-top: 6px;
}
.admin-media .library-view .item-wrapper .media-item-controls.inline {
  padding-top: 0;
}
.admin-media .library-view .item-wrapper.selected {
  z-index: 9999;
  -webkit-box-shadow: inset 5px 5px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 5px 5px 0 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #cfcfd0;
  transition: border 0.2s ease;
  margin: -2px -1px -4px;
  padding: 8px 6px 6px 8px;
}
.admin-media .library-view .item-wrapper.selected:before,
.admin-media .library-view .item-wrapper.selected:after {
  display: table;
  content: " ";
}
.admin-media .library-view .item-wrapper.selected:after {
  clear: both;
}
.admin-media .library-view .item-wrapper.selected .item-hover {
  display: none;
}
.admin-media .library-view .item-wrapper.selected .item-check {
  display: block;
}
.admin-media .library-view .item-wrapper.selected .item-preview .item {
  border: 5px solid #009ebb;
  box-shadow: none;
  cursor: auto;
}
.admin-media .library-view .item-wrapper.selected .item .pos-img {
  opacity: 0.5;
}
.admin-media .library-view .item-wrapper.selected .media-item-controls {
  opacity: 1;
  height: auto;
  max-height: 2em;
  line-height: auto;
  overflow: initial;
}
.admin-media .library-view .item-wrapper.selected .selectize-input {
  -webkit-box-shadow: inset 4px 4px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 4px 4px 0 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #cfcfd0;
  cursor: pointer;
}
.admin-media .library-view .item-wrapper.static {
  background: linear-gradient(to top, white 24px, transparent), repeating-linear-gradient(55deg, transparent, transparent 10px, rgba(0, 158, 187, 0.2) 10px, rgba(0, 158, 187, 0.15) 20px);
}
.admin-media .library-settings {
  bottom: 0;
  display: none;
  left: 15px;
  margin-top: 1em;
  padding: 15px 6px;
  position: absolute;
  width: 100%;
}
.admin-media .library-settings .form-group {
  margin: 0;
  padding: 0;
}
.admin-media .library-settings .form-group .input-group {
  max-width: 280px;
}
.admin-media .library-settings .selection-mode {
  float: right;
}
.admin-media .bootstrap-table .table:not(.table-condensed),
.admin-media .bootstrap-table .table:not(.table-condensed) > tbody > tr > th,
.admin-media .bootstrap-table .table:not(.table-condensed) > tfoot > tr > th,
.admin-media .bootstrap-table .table:not(.table-condensed) > thead > tr > td,
.admin-media .bootstrap-table .table:not(.table-condensed) > tbody > tr > td,
.admin-media .bootstrap-table .table:not(.table-condensed) > tfoot > tr > td {
  padding: 12px;
}
.admin-media .bootstrap-table .fixed-table-toolbar {
  position: absolute;
  right: 14px;
  top: -3.5em;
}
.admin-media .bootstrap-table .fixed-table-container .fixed-table-body .table {
  padding-bottom: 100px !important;
  margin-bottom: 100px !important;
}
.admin-media .document-list tr,
.admin-media .video-list tr {
  cursor: pointer;
}
.admin-media .document-list tr.selected,
.admin-media .video-list tr.selected {
  z-index: 9999;
  cursor: auto;
}
.admin-media .document-list tr.selected .media-item-controls,
.admin-media .video-list tr.selected .media-item-controls {
  opacity: 1;
  height: auto;
  max-height: 2em;
  line-height: auto;
  overflow: initial;
}
.admin-media .document-list tr.selected td,
.admin-media .video-list tr.selected td {
  border-top: none !important;
  -webkit-box-shadow: inset 0 5px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 5px 0 0 rgba(0, 0, 0, 0.2);
  padding: 15px !important;
}
.admin-media .document-list tr.selected td:first-child,
.admin-media .video-list tr.selected td:first-child {
  -webkit-box-shadow: inset 5px 5px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 5px 5px 0 0 rgba(0, 0, 0, 0.2);
}
.admin-media .document-list tr.selected .selectize-input,
.admin-media .video-list tr.selected .selectize-input {
  -webkit-box-shadow: inset 4px 4px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 4px 4px 0 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #cfcfd0;
  cursor: pointer;
}
.admin-media .edit-focus-preview .item {
  margin-bottom: 1em;
}
.admin-media .edit-focus-preview .item h5,
.admin-media .edit-focus-preview .item p {
  margin: 0;
}
.admin-media .edit-focus-preview .item h5 {
  color: #666;
  font-size: 0.8em;
}
.admin-media .edit-focus-preview .item p {
  font-size: 0.9em;
}
.admin-media .edit-focus,
.admin-media .edit-focus-preview {
  margin: 0;
}
.admin-media .edit-focus .item {
  border-radius: 3px;
  margin: 8px;
  overflow: hidden;
  position: relative;
}
.admin-media .edit-focus-preview .img-preview {
  display: inline-block;
  overflow: hidden;
}
.admin-media .edit-focus-preview .img-preview.preview-lg {
  height: 200px;
  width: 200px;
}
.admin-media .edit-focus-preview .img-preview.preview-md {
  height: 150px;
  width: 150px;
}
.admin-media .edit-focus-preview .img-preview.preview-sm {
  height: 100px;
  width: 100px;
}
.preview-media {
  display: none;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: 10000;
  height: 100vh;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.preview-media.open {
  opacity: 1;
  visibility: visible;
}
.preview-media:before {
  background: #000;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
}
.preview-media .image-wrapper,
.preview-media .video-wrapper {
  position: absolute;
  z-index: 2;
  top: 10%;
  max-width: 80vw;
  max-height: 80vh;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.preview-media .image,
.preview-media .player {
  margin: 8px 0 0 8px;
  position: relative;
}
.preview-media .image.image-horz,
.preview-media .player.image-horz {
  max-width: 100%;
}
.preview-media .image.image-vert,
.preview-media .player.image-vert {
  max-height: 100%;
}
.preview-media .player {
  height: calc(80% - 16px);
  width: calc(80% - 16px);
}
.preview-media .loader {
  display: block;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -27px;
  position: absolute;
  top: 50%;
}
.preview-media .header {
  left: 0;
  position: absolute;
  z-index: 3;
  text-align: center;
  top: 0;
  width: 100%;
  padding: 10px 62px;
  border: 1px solid #333;
  border-left: none;
  border-right: none;
  background: linear-gradient(to bottom, #414042, #343335);
}
.preview-media .header-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.preview-media .header-inner > * {
  width: 100%;
}
.preview-media .header-inner .title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 22px;
  line-height: 1;
  margin: 0;
}
.preview-media .header-inner .meta {
  color: #e6e7e8;
  font-size: 14px;
}
.preview-media .header-inner .dimensions::after {
  opacity: 0.4;
  content: '|';
  display: inline-block;
  margin: 0 8px;
}
.preview-media .close {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
  font-size: 22px;
  font-weight: normal;
  margin: 10px;
  opacity: 0.6;
  outline: none;
  padding: 10px;
  text-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.preview-media .close:focus,
.preview-media .close.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.preview-media .close:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.preview-media .close:active,
.preview-media .close.active,
.open > .dropdown-toggle.preview-media .close {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border-color: rgba(0, 0, 0, 0);
}
.preview-media .close:active:hover,
.preview-media .close.active:hover,
.open > .dropdown-toggle.preview-media .close:hover,
.preview-media .close:active:focus,
.preview-media .close.active:focus,
.open > .dropdown-toggle.preview-media .close:focus,
.preview-media .close:active.focus,
.preview-media .close.active.focus,
.open > .dropdown-toggle.preview-media .close.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.preview-media .close.disabled:hover,
.preview-media .close[disabled]:hover,
fieldset[disabled] .preview-media .close:hover,
.preview-media .close.disabled:focus,
.preview-media .close[disabled]:focus,
fieldset[disabled] .preview-media .close:focus,
.preview-media .close.disabled.focus,
.preview-media .close[disabled].focus,
fieldset[disabled] .preview-media .close.focus {
  background-color: transparent;
  border-color: transparent;
}
.preview-media .close .badge {
  color: transparent;
  background-color: #ffffff;
}
.preview-media .close:focus,
.preview-media .close:hover {
  opacity: 0.9;
}
.preview-media .close:active {
  opacity: 0.9;
}
.preview-media .close:active:hover,
.preview-media .close:active:focus {
  opacity: 1;
}
@media (min-width: 992px) {
  .admin-media .edit-focus-preview .img-preview {
    display: block;
  }
}
@media (min-width: 1200px) {
  .admin-media .tags,
  .admin-media .library-view .details {
    margin-bottom: 0;
  }
  .admin-media .library-settings {
    display: block;
  }
  .admin-media .details {
    min-height: 800px;
  }
  .admin-media .details .controls {
    bottom: 15px;
    left: 30px;
    position: absolute;
  }
}
.admin .error-page-wrapper .head-line,
.marketing .error-page-wrapper .head-line {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -1px;
  margin-bottom: 5px;
  color: #cfcfd0;
}
.admin .error-page-wrapper .subheader,
.marketing .error-page-wrapper .subheader {
  font-size: 36px;
  line-height: 46px;
  color: #333;
}
.admin .error-page-wrapper .hr,
.marketing .error-page-wrapper .hr {
  height: 1px;
  background-color: #e6e7e8;
  width: 60%;
  max-width: 250px;
  margin: 35px auto;
}
.admin .error-page-wrapper .context,
.marketing .error-page-wrapper .context {
  font-size: 18px;
  line-height: 27px;
  color: #959595;
}
.admin .error-page-wrapper .buttons-container a,
.marketing .error-page-wrapper .buttons-container a {
  text-indent: 0px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  background-color: #009ebb;
  border-radius: 99px;
  padding: 12px 0 13px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 20%;
  margin: 0 1.5%;
}
.form-note {
  font-weight: bold;
}
.required-mark:after {
  content: "\2217";
  color: #FF3932;
  display: inline;
}
.marketing-form .editor .form-group {
  max-width: none;
}
.admin .workspace input:disabled,
.admin .workspace textarea:disabled,
.admin .workspace select:disabled {
  background-color: rgba(0, 0, 0, 0.075);
}
.marketing .flexbox-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-flow: column;
  -moz-box-flex-flow: column;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  align-items: top;
}
@media (min-width: 768px) {
  .marketing .flexbox-center {
    -webkit-box-flex-flow: row;
    -moz-box-flex-flow: row;
    -webkit-flex-flow: row;
    -ms-flex-flow: row;
    flex-flow: row;
    align-items: center;
  }
}
.editor .btn:hover,
.editor .btn:active {
  box-shadow: none;
}
.editor .btn-primary {
  color: #ffffff;
  background-color: #009ebb;
  border-color: transparent;
  box-shadow: none !important;
  -webkit-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.editor .btn-primary:focus,
.editor .btn-primary.focus {
  color: #ffffff;
  background-color: #007388;
  border-color: rgba(0, 0, 0, 0);
}
.editor .btn-primary:hover {
  color: #ffffff;
  background-color: #007388;
  border-color: rgba(0, 0, 0, 0);
}
.editor .btn-primary:active,
.editor .btn-primary.active,
.open > .dropdown-toggle.editor .btn-primary {
  color: #ffffff;
  background-color: #007388;
  background-image: none;
  border-color: rgba(0, 0, 0, 0);
}
.editor .btn-primary:active:hover,
.editor .btn-primary.active:hover,
.open > .dropdown-toggle.editor .btn-primary:hover,
.editor .btn-primary:active:focus,
.editor .btn-primary.active:focus,
.open > .dropdown-toggle.editor .btn-primary:focus,
.editor .btn-primary:active.focus,
.editor .btn-primary.active.focus,
.open > .dropdown-toggle.editor .btn-primary.focus {
  color: #ffffff;
  background-color: #005564;
  border-color: rgba(0, 0, 0, 0);
}
.editor .btn-primary.disabled:hover,
.editor .btn-primary[disabled]:hover,
fieldset[disabled] .editor .btn-primary:hover,
.editor .btn-primary.disabled:focus,
.editor .btn-primary[disabled]:focus,
fieldset[disabled] .editor .btn-primary:focus,
.editor .btn-primary.disabled.focus,
.editor .btn-primary[disabled].focus,
fieldset[disabled] .editor .btn-primary.focus {
  background-color: #009ebb;
  border-color: transparent;
}
.editor .btn-primary .badge {
  color: #009ebb;
  background-color: #ffffff;
}
.editor .btn-primary:focus,
.editor .btn-primary.focus {
  background-color: #33c3e0;
}
.editor .btn-primary:hover {
  background-color: #33c3e0;
}
.editor .btn-primary:active,
.editor .btn-primary.active,
.open > .dropdown-toggle.editor .btn-primary {
  background-color: #33c3e0;
}
.editor .btn-primary:active:hover,
.editor .btn-primary.active:hover,
.open > .dropdown-toggle.editor .btn-primary:hover,
.editor .btn-primary:active:focus,
.editor .btn-primary.active:focus,
.open > .dropdown-toggle.editor .btn-primary:focus,
.editor .btn-primary:active.focus,
.editor .btn-primary.active.focus,
.open > .dropdown-toggle.editor .btn-primary.focus {
  background-color: #33c3e0;
}
.editor .btn-primary.link {
  background-color: #009ebb !important;
  font-size: 14px;
  letter-spacing: 2px;
  min-width: 150px;
  overflow: hidden;
  padding: 10px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
}
.editor .btn-primary.link .link-text {
  left: 0;
  position: relative;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.42s;
  transition-duration: 0.42s;
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.editor .btn-primary.link:before {
  background: #33c3e0;
  color: #ffffff;
  content: "\e91d";
  display: block;
  font-family: "ArchitechIcons";
  font-size: 26px;
  height: 100%;
  left: -100%;
  padding: 2px 10px 2px 14px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.42s;
  transition-duration: 0.42s;
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.editor .btn-primary.link:hover .link-text,
.editor .btn-primary.link:focus .link-text {
  left: 120%;
}
.editor .btn-primary.link:hover:before,
.editor .btn-primary.link:focus:before {
  left: 0;
}
.editor .btn-primary.link:active:before,
.editor .btn-primary.link.active:before {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
@media (min-width: 992px) {
  .btn-primary.link.desktop-only {
    display: inline-block;
  }
}
.marketing-bg-left,
.marketing-bg-right {
  position: relative;
  z-index: 50;
}
.marketing-bg-left.offset-head,
.marketing-bg-right.offset-head,
.marketing-bg-left.small-padding,
.marketing-bg-right.small-padding {
  padding: 80px / 2 0 30px;
}
.marketing-bg-left.no-overflow,
.marketing-bg-right.no-overflow {
  overflow: hidden;
}
.marketing-bg-left:before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e6e7e8+50,e6e7e8+50,ffffff+96&0+50,1+96 */
  background: -moz-linear-gradient(top, rgba(230, 231, 232, 0) 50%, #ffffff 96%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(230, 231, 232, 0) 50%, #ffffff 96%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(230, 231, 232, 0) 50%, #ffffff 96%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e6e7e8', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
}
.accent-after {
  position: relative;
}
.accent-after:after {
  border-left: 45px solid transparent;
  border-right: 45px solid transparent;
  border-top: 45px solid #ffffff;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  bottom: -45px;
  z-index: 10;
  transform: translate(-50%, 0);
}
.marketing a {
  color: #009ebb;
  text-decoration: none;
}
.marketing a:hover,
.marketing a:focus {
  color: #33c3e0;
  text-decoration: underline;
}
@media (min-width: 992px) {
  .marketing-bg-left {
    background: url("../img/admin/marketing/at-caret-left-large.png") no-repeat -782px -622px;
  }
  .marketing-bg-left.small-padding {
    padding: 80px 0;
  }
  .marketing-bg-right {
    background: url("../img/admin/marketing/at-caret-right-large.png") no-repeat;
    background-position: right -890px top -765px;
  }
}
.marketing .editor .contact-us-form {
  padding: 55px 15px 20px;
  position: relative;
}
.marketing .editor .contact-us-form.with-errors {
  padding-top: 13px;
}
.marketing .editor .contact-us-form .section-caption {
  margin-bottom: 30px;
}
.marketing .editor .contact-us-form .section-caption .description {
  margin-bottom: 10px;
}
.marketing .editor .account-form {
  padding: 80px 15px 20px;
  position: relative;
}
.marketing .editor .account-form .section-caption {
  margin-bottom: 20px;
}
.marketing .editor .validation-summary-errors ul {
  list-style-type: none;
  margin: 0 0 25px;
  padding: 0;
}
.marketing .editor form .form-group {
  margin-bottom: 30px;
}
.marketing .editor form .form-group textarea {
  max-width: 100%;
}
.marketing .editor form .form-group .required {
  font-size: 9px;
  margin-top: -3px;
}
.marketing .editor form .form-group .limit {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #666;
  padding: 6px 2px 0;
  text-transform: uppercase;
}
.marketing .editor form .form-group .checkbox {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #666;
  text-transform: uppercase;
}
.marketing .editor form .form-group .limit {
  font-size: 11px;
  letter-spacing: 3px;
}
.marketing .editor form .form-group.message-group {
  margin-bottom: 10px;
}
.marketing .editor form .notes {
  margin-bottom: 10px;
}
.marketing .editor form .notes .required {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.marketing .editor form .notes .required sup {
  font-size: 9px;
  margin-top: -3px;
}
.marketing .editor form .notes.errors {
  display: none;
}
.marketing .editor form .notes.errors .required {
  display: block;
  position: relative;
}
.marketing .editor form .notes.errors .icon,
.marketing .editor form .notes.errors .error-list {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.marketing .editor form .notes.errors .icon {
  font-size: 32px;
}
.marketing .editor form .notes.errors .error-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.marketing .editor form .clear-form {
  cursor: pointer;
  font-size: 14px;
  padding: 12px 20px;
  letter-spacing: 1px;
}
.marketing .editor form .label-placeholder textarea:focus + label,
.marketing .editor form .label-placeholder textarea:valid + label,
.marketing .editor form .label-placeholder textarea[readonly] + label,
.marketing .editor form .label-placeholder textarea[readonly="readonly"] + label {
  font-size: 10px;
  transform: translate(0, -22px);
}
.marketing .editor form .label-placeholder.invalid textarea:valid + label {
  font-size: 12px;
  transform: translate(0, 0);
}
.marketing .editor form .label-placeholder.invalid textarea:focus + label {
  font-size: 10px;
  transform: translate(0, -22px);
}
@media (min-width: 768px) {
  .marketing .editor .contact-us-form {
    padding-bottom: 50px;
    padding-top: 80px;
    position: relative;
  }
  .marketing .editor .contact-us-form.with-errors {
    padding-top: 38px;
  }
  .marketing .editor .account-form {
    padding-bottom: 50px;
    padding-top: 110px;
  }
}
.reveal-section {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.65s;
  transition-duration: 0.65s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.reveal-section.show-section {
  opacity: 1;
  visibility: visible;
}
.reveal-section .left-section .move-section,
.reveal-section .right-section .move-section {
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.55s;
  transition-duration: 0.55s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.reveal-section .left-section .move-section.quicker,
.reveal-section .right-section .move-section.quicker {
  -webkit-transition-duration: 0.65s;
  transition-duration: 0.65s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}
.reveal-section .left-section .move-section.delayed,
.reveal-section .right-section .move-section.delayed {
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.reveal-section .left-section.off .move-section {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  -o-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}
.reveal-section .right-section.off .move-section {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  -o-transform: translate(100%, 0);
  transform: translate(100%, 0);
}
.reveal-section .left-section.on .move-section,
.reveal-section .right-section.on .move-section {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.no-js .reveal-section {
  opacity: 1;
  visibility: visible;
}
.marketing .carousel.no-anim .carousel-inner .item {
  transition-property: none;
}
.marketing .carousel.no-anim .carousel-inner .item,
.marketing .carousel.no-anim .carousel-inner .active.left,
.marketing .carousel.no-anim .carousel-inner .active.right {
  opacity: 0;
}
.marketing .carousel.no-anim .carousel-inner .active,
.marketing .carousel.no-anim .carousel-inner .next.left,
.marketing .carousel.no-anim .carousel-inner .prev.right {
  opacity: 1;
}
.marketing .carousel.no-anim .carousel-inner .next,
.marketing .carousel.no-anim .carousel-inner .prev,
.marketing .carousel.no-anim .carousel-inner .active.left,
.marketing .carousel.no-anim .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.marketing .carousel.fader .carousel-inner .item {
  transition-property: opacity;
}
.marketing .carousel.fader .carousel-inner .item,
.marketing .carousel.fader .carousel-inner .active.left,
.marketing .carousel.fader .carousel-inner .active.right {
  opacity: 0;
}
.marketing .carousel.fader .carousel-inner .active,
.marketing .carousel.fader .carousel-inner .next.left,
.marketing .carousel.fader .carousel-inner .prev.right {
  opacity: 1;
}
.marketing .carousel.fader .carousel-inner .next,
.marketing .carousel.fader .carousel-inner .prev,
.marketing .carousel.fader .carousel-inner .active.left,
.marketing .carousel.fader .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.marketing .carousel.vertical .carousel-inner {
  height: 100%;
  width: auto;
}
.marketing .carousel.vertical .carousel-inner .item {
  transition: 0.6s ease-in-out top;
  width: auto;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .marketing .carousel.vertical .carousel-inner .item {
    transition: 0.6s ease-in-out;
  }
  .marketing .carousel.vertical .carousel-inner .item.next,
  .marketing .carousel.vertical .carousel-inner .item.active.right {
    top: 0;
    transform: translate3d(0, 100%, 0);
  }
  .marketing .carousel.vertical .carousel-inner .item.prev,
  .marketing .carousel.vertical .carousel-inner .item.active.left {
    top: 0;
    transform: translate3d(0, -100%, 0);
  }
  .marketing .carousel.vertical .carousel-inner .item.next.left,
  .marketing .carousel.vertical .carousel-inner .item.prev.right,
  .marketing .carousel.vertical .carousel-inner .item.active {
    top: 0;
    transform: translate3d(0, 0, 0);
  }
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .marketing .carousel.vertical.vertical-flip .carousel-inner .item {
    transition: 0.6s ease-in-out;
  }
  .marketing .carousel.vertical.vertical-flip .carousel-inner .item.next,
  .marketing .carousel.vertical.vertical-flip .carousel-inner .item.active.right {
    transform: translate3d(0, -100%, 0);
  }
  .marketing .carousel.vertical.vertical-flip .carousel-inner .item.prev,
  .marketing .carousel.vertical.vertical-flip .carousel-inner .item.active.left {
    transform: translate3d(0, 100%, 0);
  }
  .marketing .carousel.vertical.vertical-flip .carousel-inner .item.next.left,
  .marketing .carousel.vertical.vertical-flip .carousel-inner .item.prev.right,
  .marketing .carousel.vertical.vertical-flip .carousel-inner .item.active {
    top: 0;
    transform: translate3d(0, 0, 0);
  }
}
.marketing .carousel.vertical .carousel-inner .active,
.marketing .carousel.vertical .carousel-inner .next,
.marketing .carousel.vertical .carousel-inner .prev {
  display: block;
}
.marketing .carousel.vertical .carousel-inner .active {
  top: 0;
}
.marketing .carousel.vertical .carousel-inner .next,
.marketing .carousel.vertical .carousel-inner .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.marketing .carousel.vertical .carousel-inner .next {
  top: 100%;
}
.marketing .carousel.vertical .carousel-inner .prev {
  top: -100%;
}
.marketing .carousel.vertical .carousel-inner .next.left,
.marketing .carousel.vertical .carousel-inner .prev.right {
  top: 0;
}
.marketing .carousel.vertical .carousel-inner .active.left {
  top: -100%;
}
.marketing .carousel.vertical .carousel-inner .active.right {
  top: 100%;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .marketing .carousel.horizontal-flip .carousel-inner .item {
    transition: 0.6s ease-in-out;
  }
  .marketing .carousel.horizontal-flip .carousel-inner .item.next,
  .marketing .carousel.horizontal-flip .carousel-inner .item.active.right {
    transform: translate3d(-100%, 0, 0);
  }
  .marketing .carousel.horizontal-flip .carousel-inner .item.prev,
  .marketing .carousel.horizontal-flip .carousel-inner .item.active.left {
    transform: translate3d(100%, 0, 0);
  }
  .marketing .carousel.horizontal-flip .carousel-inner .item.next.left,
  .marketing .carousel.horizontal-flip .carousel-inner .item.prev.right,
  .marketing .carousel.horizontal-flip .carousel-inner .item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}
.device {
  position: relative;
}
.device:before,
.device:after {
  display: table;
  content: " ";
}
.device:after {
  clear: both;
}
.device .shadows:before,
.device .shadows:after {
  bottom: 0;
  content: "";
  display: block;
  left: -50px;
  position: absolute;
  right: -50px;
  width: calc(100% +  100px);
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  -o-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.device .shadows:before {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(102, 102, 102, 0.4) 0%, rgba(102, 102, 102, 0) 70%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(102, 102, 102, 0.4) 0%, rgba(102, 102, 102, 0) 70%);
  height: 86px;
}
.device .shadows:after {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(102, 102, 102, 0.9) 0%, rgba(102, 102, 102, 0) 72%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(102, 102, 102, 0.9) 0%, rgba(102, 102, 102, 0) 72%);
  height: 22px;
}
.device .shadows.lighter:before {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(149, 149, 149, 0.5) 0%, rgba(149, 149, 149, 0) 70%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(149, 149, 149, 0.5) 0%, rgba(149, 149, 149, 0) 70%);
}
.device .shadows.lighter:after {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(149, 149, 149, 0.9) 0%, rgba(149, 149, 149, 0) 72%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(149, 149, 149, 0.9) 0%, rgba(149, 149, 149, 0) 72%);
}
.device .shadows.smaller:before,
.device .shadows.smaller:after {
  left: -10px;
  right: -10px;
  width: calc(100% +  20px);
}
.device .shadows.smaller:before {
  height: 50px;
}
.device .shadows.circular {
  bottom: -12px;
}
.device .shadows.circular:before,
.device .shadows.circular:after {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}
.device .shadows.circular:after {
  width: 30%;
}
.device .shadows.circular:before {
  width: 75%;
  height: 50px;
  bottom: -12px;
}
.device .shadows.larger:before,
.device .shadows.larger:after {
  left: -70px;
  right: -70px;
  width: calc(100% +  140px);
}
.device.view-desktop {
  background: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 36px;
  position: relative;
}
.device.view-desktop .controls {
  left: 0;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.device.view-desktop .controls .detail {
  background: #ffffff;
  border-radius: 16px;
  border: 2px solid #d8d8d8;
  display: inline-block;
  height: 16px;
  vertical-align: middle;
  width: 16px;
}
.device.view-desktop .controls .detail + .detail {
  margin-left: 8px;
}
.laptop {
  position: relative;
  width: 75%;
  margin: 0 auto;
}
.laptop .body {
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  margin: 0 auto;
  max-width: 580px;
  padding: 3.825% 2.3% 4%;
  position: relative;
  width: 85.7%;
}
.laptop .body:after {
  background: #ffffff;
  border-radius: 15px 15px 75px 75px;
  bottom: 0;
  content: "";
  display: block;
  height: 6.75%;
  left: 50%;
  position: absolute;
  width: 123.6%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.laptop .body .screen {
  margin: 0 auto;
  width: 100%;
}
.laptop .body .screen .fill:before {
  content: "";
  display: block;
  padding-bottom: 62.34%;
}
@media (min-width: 992px) {
  .device.laptop {
    width: 100%;
  }
  .laptop .body {
    margin: 0 0 0 auto;
  }
}
.marketing-idea-row {
  overflow: hidden;
}
.marketing-idea-row .idea-row-container {
  padding: 55px 15px;
  position: relative;
}
.marketing-idea-row .idea-row-container > .container > .row {
  position: relative;
  z-index: 2;
}
.marketing-idea-row .iphone {
  position: relative;
  width: 260px;
  display: none;
  z-index: 2;
}
.marketing-idea-row .iphone .screen div {
  height: 100%;
}
.marketing-idea-row .iphone .body {
  background: #ffffff;
  border-radius: 25px;
  height: 503.1px;
  position: relative;
  width: 100%;
}
.marketing-idea-row .iphone .body:before {
  border-radius: 50%;
  border: 2px solid #d5d5d5;
  content: "";
  display: block;
  height: 1.5%;
  left: 33%;
  position: absolute;
  top: 5%;
  width: 3%;
}
.marketing-idea-row .iphone .body:after {
  border-radius: 10px;
  border: 2px solid #d5d5d5;
  content: "";
  display: block;
  height: 1.5%;
  left: 50%;
  position: absolute;
  top: 5%;
  width: 19%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing-idea-row .iphone .body.no-detail:before,
.marketing-idea-row .iphone .body.no-detail:after {
  content: none;
}
.marketing-idea-row .iphone .body .screen {
  background: #ffffff;
  color: #ffffff;
  height: 75%;
  left: 50%;
  position: absolute;
  top: 11.88%;
  width: 93.1%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing-idea-row .iphone .body .screen .carousel .item {
  height: 100%;
}
.marketing-idea-row .iphone:after {
  border-radius: 50%;
  border: 2px solid #d5d5d5;
  bottom: 1.42%;
  content: "";
  display: block;
  height: 8.85%;
  left: 50%;
  position: absolute;
  width: 16.95%;
  z-index: 5;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing-idea-row .view-desktop {
  z-index: 1;
}
.marketing-idea-row .idea-row-carousel-desktop .at-image-container,
.marketing-idea-row .idea-row-carousel-desktop .fill {
  height: 100%;
}
.marketing-idea-row .idea-row-carousel-desktop .fill {
  background-position: left top;
}
.marketing-idea-row .idea-row-carousel-desktop .item {
  height: 200px;
}
.marketing-idea-row .idea-row-carousel-laptop .fill {
  background-position: left top;
}
.marketing-idea-row .grid-item {
  position: relative;
}
.marketing-idea-row .grid-item .design-thumbnail {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 75%;
}
.marketing-idea-row .grid-item .shadows {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.marketing-idea-row .grid-item .shadows:before,
.marketing-idea-row .grid-item .shadows:after {
  bottom: 0;
  content: "";
  display: block;
  left: -50px;
  position: absolute;
  right: -50px;
  width: calc(100% +  100px);
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  -o-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.marketing-idea-row .grid-item .shadows:before {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(102, 102, 102, 0.4) 0%, rgba(102, 102, 102, 0) 70%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(102, 102, 102, 0.4) 0%, rgba(102, 102, 102, 0) 70%);
  height: 86px;
}
.marketing-idea-row .grid-item .shadows:after {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(102, 102, 102, 0.9) 0%, rgba(102, 102, 102, 0) 72%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(102, 102, 102, 0.9) 0%, rgba(102, 102, 102, 0) 72%);
  height: 22px;
}
.marketing-idea-row .grid-item .shadows.lighter:before {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(149, 149, 149, 0.5) 0%, rgba(149, 149, 149, 0) 70%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(149, 149, 149, 0.5) 0%, rgba(149, 149, 149, 0) 70%);
}
.marketing-idea-row .grid-item .shadows.lighter:after {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(149, 149, 149, 0.9) 0%, rgba(149, 149, 149, 0) 72%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(149, 149, 149, 0.9) 0%, rgba(149, 149, 149, 0) 72%);
}
.marketing-idea-row .grid-item .shadows.smaller:before,
.marketing-idea-row .grid-item .shadows.smaller:after {
  left: -10px;
  right: -10px;
  width: calc(100% +  20px);
}
.marketing-idea-row .grid-item .shadows.smaller:before {
  height: 50px;
}
.marketing-idea-row .grid-item .shadows.circular {
  bottom: -12px;
}
.marketing-idea-row .grid-item .shadows.circular:before,
.marketing-idea-row .grid-item .shadows.circular:after {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}
.marketing-idea-row .grid-item .shadows.circular:after {
  width: 30%;
}
.marketing-idea-row .grid-item .shadows.circular:before {
  width: 75%;
  height: 50px;
  bottom: -12px;
}
.marketing-idea-row .grid-item .shadows.larger:before,
.marketing-idea-row .grid-item .shadows.larger:after {
  left: -70px;
  right: -70px;
  width: calc(100% +  140px);
}
.marketing-idea-row .grid-item .thumbnail-image {
  border: 16px solid #ffffff;
  display: block;
  width: 100%;
}
.marketing-idea-row .grid-item .thumbnail-image.no-border {
  border: none;
}
.marketing-idea-row.idea-row-home .caption.section-caption .description {
  margin-bottom: 25px;
}
.marketing-idea-row.idea-row-home .mobile-only .link {
  margin: 30px auto 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing-idea-row.site-features .idea-row-container,
.marketing-idea-row.how-it-works .idea-row-container {
  padding-bottom: 0;
}
.marketing-idea-row.site-features .row,
.marketing-idea-row.how-it-works .row {
  padding-bottom: 60px;
}
.marketing-idea-row.site-features .row {
  padding-bottom: 55px;
}
.marketing-idea-row.site-features .grid-item {
  margin: 30px 0 55px;
  text-align: center;
}
.marketing-idea-row.site-features .grid-item .design-thumbnail {
  max-width: 500px;
  width: 75%;
}
.marketing-idea-row.site-features .grid-item .design-thumbnail-zoom {
  position: absolute;
  width: 65%;
}
.marketing-idea-row.site-features .grid-item .thumbnail-image-zoom {
  max-width: 100%;
}
.marketing-idea-row.site-features .grid-item .thumbnail-image-zoom.circular {
  border-radius: 50%;
  border: 16px solid #ffffff;
}
.marketing-idea-row.site-features .feature-inline-editing .grid-item .design-thumbnail {
  max-width: 550px;
  margin-bottom: 28px;
}
.marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail {
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail .shadows {
  bottom: 18px;
}
.marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail-zoom {
  bottom: 0;
  left: 22px;
  max-width: 480px;
}
.marketing-idea-row.site-features .feature-faculty-finder .grid-item .design-thumbnail {
  margin-bottom: 42px;
  left: -10%;
}
.marketing-idea-row.site-features .feature-faculty-finder .grid-item .design-thumbnail-zoom {
  bottom: 0;
  right: -5%;
  max-width: 420px;
}
.marketing-idea-row.site-features .feature-faculty-finder .grid-item .thumbnail-image {
  max-width: 500px;
}
.marketing-idea-row.site-features .feature-faculty-finder .grid-item .thumbnail-image-zoom {
  max-width: 190px;
}
.marketing-idea-row.site-features .feature-form-builder .grid-item .design-thumbnail {
  margin-bottom: 20px;
  left: -10%;
}
.marketing-idea-row.site-features .feature-form-builder .grid-item .design-thumbnail-zoom {
  bottom: 0;
  right: 5%;
  max-width: 420px;
}
.marketing-idea-row.site-features .feature-events-calendar .grid-item .design-thumbnail {
  margin-bottom: 28px;
}
.marketing-idea-row.site-features .feature-events-calendar .grid-item .design-thumbnail-zoom {
  bottom: 0;
  left: 10%;
  max-width: 150px;
}
.marketing-idea-row.site-features .feature-site-search .grid-item {
  margin-bottom: 55px;
}
.marketing-idea-row.site-features .feature-site-search .grid-item .design-thumbnail-zoom {
  left: 45%;
  max-width: 240px;
  top: -8%;
  width: 34%;
}
.marketing-idea-row.site-features .feature-related-content .grid-item .design-thumbnail {
  margin-bottom: 28px;
}
.marketing-idea-row.site-features .feature-related-content .grid-item .design-thumbnail-zoom {
  bottom: 0;
  left: 45%;
  max-width: 285px;
  width: 50%;
}
.marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail {
  padding-bottom: 20px;
}
.marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail-zoom {
  bottom: 0;
  left: 5%;
  max-width: 489px;
}
.marketing-idea-row.site-features .feature-responsive-design .grid-item {
  margin-top: 70px;
}
.marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail-phone {
  display: inline-block;
  height: 123%;
  position: absolute;
  right: 10%;
  top: -12%;
}
.marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail-phone img {
  height: 100%;
}
.marketing-idea-row.site-features .feature-image-galleries .grid-item .design-thumbnail {
  margin-bottom: 20px;
}
.marketing-idea-row.site-features .feature-image-galleries .grid-item .design-thumbnail-zoom {
  bottom: 0;
  left: 5%;
  max-width: 389px;
}
.marketing-idea-row.site-features .feature-user-engagement .grid-item .design-thumbnail {
  margin-bottom: 28px;
}
.marketing-idea-row.site-features .feature-user-engagement .grid-item .design-thumbnail-zoom {
  bottom: 0;
  left: 25%;
  max-width: 482px;
}
.marketing-idea-row.how-it-works .row:first-of-type {
  padding-bottom: 65px;
}
.marketing-idea-row.how-it-works .laptop .body {
  margin-left: 0;
}
.marketing-idea-row.our-story .section-caption .link {
  margin-top: 30px;
}
.marketing-idea-row.our-story .idea-row-container {
  padding-top: 0;
}
.marketing-idea-row.our-story .grid-item .thumbnail-image {
  background: #e6e7e8;
  margin-bottom: 30px;
  padding: 20px 30px 25px;
}
@media (min-width: 480px) {
  .marketing-idea-row .idea-row-carousel-desktop .item {
    height: 300px;
  }
  .marketing-idea-row.site-features .feature-events-calendar .grid-item .design-thumbnail-zoom {
    max-width: 234px;
  }
}
@media (min-width: 768px) {
  .marketing-idea-row .idea-row-container {
    padding: 80px 15px;
  }
  .marketing-idea-row .iphone {
    display: block;
  }
  .marketing-idea-row .view-desktop {
    margin-top: 40px;
    bottom: 135px;
    margin: 0;
    position: absolute;
    right: -80px;
    width: 50%;
  }
  .marketing-idea-row .idea-row-carousel-desktop .item {
    height: 500px;
  }
  .marketing-idea-row.idea-row-home {
    padding-top: 170px;
  }
  .marketing-idea-row.idea-row-home .idea-row-container {
    padding-top: 100px;
    padding-bottom: 75px;
  }
  .marketing-idea-row.idea-row-home .mobile-only {
    display: none;
  }
  .marketing-idea-row.idea-row-home .tablet-up.link {
    display: inline-block;
  }
  .marketing-idea-row.site-features .row,
  .marketing-idea-row.how-it-works .row {
    padding-bottom: 70px;
  }
  .marketing-idea-row.site-features .grid-item .design-thumbnail,
  .marketing-idea-row.how-it-works .grid-item .design-thumbnail {
    min-width: 450px;
    width: 100%;
  }
  .marketing-idea-row.site-features .image-left .grid-item .design-thumbnail,
  .marketing-idea-row.how-it-works .image-left .grid-item .design-thumbnail {
    float: right;
  }
  .marketing-idea-row.how-it-works .row:first-of-type {
    padding-bottom: 75px;
  }
  .marketing-idea-row.site-features .row {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .marketing-idea-row.site-features .row:first-of-type {
    padding-top: 0;
  }
  .marketing-idea-row.site-features .flexbox-center .grid-item.col-md-offset-1,
  .marketing-idea-row.site-features .flextbox-top .grid-item.col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .marketing-idea-row.site-features .feature-site-search .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-related-content .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-image-galleries .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-user-engagement .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-faculty-finder .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-form-builder .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-events-calendar .grid-item .design-thumbnail-zoom {
    width: 95%;
  }
  .marketing-idea-row.site-features .feature-site-search .grid-item .design-thumbnail-zoom {
    left: 210px;
  }
  .marketing-idea-row.site-features .feature-related-content .grid-item .design-thumbnail-zoom {
    left: auto;
    right: 0;
  }
  .marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail-phone {
    right: 0;
  }
  .marketing-idea-row.site-features .feature-user-engagement .grid-item .design-thumbnail-zoom {
    right: 0;
    left: auto;
  }
  .marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-image-galleries .grid-item .design-thumbnail-zoom {
    left: 0;
  }
  .marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail-zoom {
    left: -22px;
  }
  .marketing-idea-row.site-features .feature-faculty-finder .grid-item .design-thumbnail-zoom {
    right: -23.2%;
    left: auto;
  }
  .marketing-idea-row.site-features .feature-faculty-finder .grid-item .thumbnail-image-zoom {
    max-width: 275px;
  }
  .marketing-idea-row.site-features .feature-events-calendar .grid-item .design-thumbnail {
    margin-bottom: 32px;
  }
  .marketing-idea-row.site-features .feature-events-calendar .grid-item .design-thumbnail-zoom {
    bottom: 0;
    left: -5.47%;
  }
  .marketing-idea-row.our-story .flexbox-center .left-section {
    flex: none;
    padding-right: 5%;
    width: 50%;
  }
  .marketing-idea-row.our-story .flexbox-center .right-section {
    flex: none;
    margin: 0;
    width: 50%;
  }
  .marketing-idea-row.our-story .grid-item .design-thumbnail {
    margin: 0;
    width: 475px;
  }
  .marketing-idea-row.our-story .grid-item .thumbnail-image {
    padding: 35px 95px 40px;
  }
}
@media (min-width: 992px) {
  .marketing-idea-row .view-desktop {
    right: -20px;
  }
  .marketing-idea-row .image-left .grid-item {
    padding-right: 0;
  }
  .marketing-idea-row .image-right .grid-item {
    padding-left: 0;
  }
  .marketing-idea-row .grid-item .design-thumbnail,
  .marketing-idea-row .grid-item .design-thumbnail-zoom {
    width: 100%;
  }
  .marketing-idea-row.site-features .idea-row-container,
  .marketing-idea-row.how-it-works .idea-row-container {
    padding-top: 75px;
  }
  .marketing-idea-row.site-features .row,
  .marketing-idea-row.how-it-works .row {
    padding-bottom: 75px;
  }
  .marketing-idea-row.how-it-works .row:first-of-type {
    padding-bottom: 85px;
  }
  .marketing-idea-row.how-it-works .image-left .grid-item .design-thumbnail {
    float: none;
  }
  .marketing-idea-row.site-features .idea-row-container {
    padding-top: 100px;
  }
  .marketing-idea-row.site-features .row {
    padding-bottom: 60px;
  }
  .marketing-idea-row.site-features .image-left .grid-item .design-thumbnail {
    float: none;
  }
  .marketing-idea-row.site-features .grid-item {
    margin-bottom: 0;
    margin-top: 0;
  }
  .marketing-idea-row.site-features .feature-site-search .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-image-galleries .feature-related-content .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-user-engagement .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-inline-editing .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-faculty-finder .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-form-builder .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-events-calendar .grid-item .design-thumbnail {
    float: none;
    width: 100%;
  }
  .marketing-idea-row.site-features .feature-site-search .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-image-galleries .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-faculty-finder .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-form-builder .grid-item .design-thumbnail {
    margin-right: 0;
    left: auto;
  }
  .marketing-idea-row.site-features .feature-related-content .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-user-engagement .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-inline-editing .grid-item .design-thumbnail,
  .marketing-idea-row.site-features .feature-events-calendar .grid-item .design-thumbnail {
    margin-left: 0;
  }
  .marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail-zoom,
  .marketing-idea-row.site-features .feature-image-galleries .grid-item .design-thumbnail-zoom {
    left: 0;
  }
  .marketing-idea-row.site-features .feature-site-search .grid-item {
    margin-bottom: 0;
  }
  .marketing-idea-row.site-features .feature-site-search .grid-item .design-thumbnail-zoom {
    left: 260px;
  }
  .marketing-idea-row.site-features .feature-related-content .grid-item .design-thumbnail-zoom {
    right: -15px;
  }
  .marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail {
    margin-bottom: 28px;
  }
  .marketing-idea-row.site-features .feature-custom-itineraries .grid-item .design-thumbnail-zoom {
    bottom: 0;
    left: -15px;
  }
  .marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail-zoom {
    left: -22px;
  }
  .marketing-idea-row.site-features .feature-responsive-design .grid-item {
    margin-top: 0;
  }
  .marketing-idea-row.site-features .feature-responsive-design .grid-item .design-thumbnail-phone {
    right: -15px;
  }
  .marketing-idea-row.site-features .feature-image-galleries .grid-item .design-thumbnail {
    margin-bottom: 28px;
  }
  .marketing-idea-row.site-features .feature-image-galleries .grid-item .design-thumbnail-zoom {
    bottom: 0;
    left: -15px;
  }
  .marketing-idea-row.site-features .feature-form-builder .grid-item .design-thumbnail {
    margin-bottom: 32px;
  }
  .marketing-idea-row.site-features .feature-form-builder .grid-item .design-thumbnail-zoom {
    bottom: 0;
    right: -32px;
  }
  .marketing-idea-row.site-features .feature-custom-itineraries,
  .marketing-idea-row.site-features .feature-program-finder,
  .marketing-idea-row.site-features .feature-faculty-finder {
    padding-bottom: 80px;
  }
  .marketing-idea-row.site-features .feature-responsive-design {
    padding-top: 82px;
  }
  .marketing-idea-row.site-features .feature-user-engagement .grid-item .design-thumbnail {
    margin-bottom: 28px;
  }
  .marketing-idea-row.site-features .feature-user-engagement .grid-item .design-thumbnail-zoom {
    bottom: 0;
    right: -15px;
  }
  .marketing-idea-row.our-story .flexbox-center .left-section {
    padding-right: 10%;
  }
  .marketing-idea-row.our-story .grid-item .design-thumbnail {
    width: 100%;
  }
  .marketing-idea-row.our-story .grid-item .thumbnail-image {
    padding: 50px 75px 55px;
  }
}
@media (min-width: 1200px) {
  .marketing-idea-row.site-features .feature-program-finder .grid-item .design-thumbnail-zoom {
    left: 22px;
  }
  .marketing-idea-row.our-story .grid-item .thumbnail-image {
    padding: 65px 135px 75px;
  }
}
.section-caption {
  text-align: center;
}
.section-caption .section-title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
  margin-bottom: 15px;
  margin-top: 0;
}
.section-caption .section-title .icon {
  color: #009ebb;
  font-size: 30px;
  vertical-align: middle;
}
.section-caption .title {
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 40px;
  letter-spacing: normal;
  line-height: 1.2;
  margin-top: 0;
}
.section-caption .title:after {
  background: #b1d133;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 72px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media (min-width: 768px) {
  .section-caption .title {
    margin-bottom: 18px;
    padding-bottom: 20px;
  }
  .section-caption .title:after {
    left: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.section-caption .description {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 35px;
}
.how-it-works .section-caption .feature-list,
.site-features .section-caption .feature-list {
  text-align: left;
}
.how-it-works .section-caption .feature-list .icon,
.site-features .section-caption .feature-list .icon,
.how-it-works .section-caption .feature-list .feature-item-text,
.site-features .section-caption .feature-list .feature-item-text {
  display: inline-block;
  vertical-align: top;
}
.how-it-works .section-caption .feature-list .icon,
.site-features .section-caption .feature-list .icon {
  font-size: 30px;
}
.how-it-works .section-caption .feature-list .feature-item-text,
.site-features .section-caption .feature-list .feature-item-text {
  width: calc(100% - 55px);
}
.how-it-works .section-caption .feature-list-item,
.site-features .section-caption .feature-list-item {
  padding-bottom: 18px;
}
.how-it-works .feature-list {
  margin-bottom: 30px;
  display: inline-block;
}
.how-it-works .laptop-row .section-caption {
  margin-bottom: 30px;
}
.how-it-works .laptop-row .section-caption .title {
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}
.how-it-works .laptop-row .section-caption .title:after {
  background: #b1d133;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 72px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media (min-width: 992px) {
  .how-it-works .laptop-row .section-caption .title {
    margin-bottom: 18px;
    padding-bottom: 20px;
  }
  .how-it-works .laptop-row .section-caption .title:after {
    left: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.site-features .section-caption .title {
  padding-top: 0;
}
.our-story .section-caption {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .section-caption {
    text-align: left;
  }
  .section-caption .description:last-of-type,
  .section-caption .description:only-of-type {
    margin-bottom: 0;
  }
  .section-caption .description:last-of-type + .feature-list,
  .section-caption .description:only-of-type + .feature-list {
    margin-top: 35px;
  }
  .how-it-works .laptop-row .section-caption {
    text-align: center;
    margin-bottom: 0;
  }
  .how-it-works .laptop-row .section-caption .description:last-of-type,
  .how-it-works .laptop-row .section-caption .description:only-of-type {
    margin-bottom: 35px;
  }
  .how-it-works .laptop-row.flexbox-center {
    -webkit-box-flex-flow: column;
    -moz-box-flex-flow: column;
    -webkit-flex-flow: column;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .how-it-works .section-caption .feature-list {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .section-caption .description {
    margin-bottom: 30px;
  }
  .how-it-works .section-caption {
    margin-bottom: 0;
  }
  .how-it-works .section-caption .description {
    margin-bottom: 0;
  }
  .how-it-works .feature-list {
    margin-bottom: 0;
    display: block;
  }
  .how-it-works .laptop-row .section-caption .description:last-of-type,
  .how-it-works .laptop-row .section-caption .description:only-of-type {
    margin-bottom: 0;
  }
  .how-it-works .laptop-row.flexbox-center {
    -webkit-box-flex-flow: row;
    -moz-box-flex-flow: row;
    -webkit-flex-flow: row;
    -ms-flex-flow: row;
    flex-flow: row;
  }
  .how-it-works .laptop-row.flexbox-center .section-caption {
    text-align: left;
  }
  .how-it-works .laptop-row.flexbox-center .section-caption .title:after {
    left: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .how-it-works .section-caption .feature-list .icon {
    font-size: 35px;
  }
  .how-it-works .section-caption .feature-list .feature-item-text {
    width: calc(100% - 60px);
  }
  .our-story .section-caption {
    margin-bottom: 0;
  }
}
.morph-button {
  display: block;
  margin: 0 auto;
  position: relative;
}
.morph-button > button {
  overflow: hidden;
  padding: 0 1em;
  position: relative;
}
.morph-button .morph-content {
  background-color: #ffffff;
  color: #333;
  pointer-events: none;
}
.morph-button.open > button {
  pointer-events: none;
}
.morph-button.open .morph-content {
  pointer-events: auto;
}
.morph-button-fixed {
  height: 40px;
  width: 200px;
}
.morph-button-fixed > button {
  color: #ffffff;
  background-color: #009ebb;
  border-color: transparent;
  box-shadow: none !important;
  height: 100%;
  line-height: 40px;
  outline: none;
  width: 100%;
  z-index: 1000;
  -webkit-transition: background-color 0.2s, opacity 0.1s 0.5s;
  transition: background-color 0.2s, opacity 0.1s 0.5s;
}
.morph-button-fixed > button:focus,
.morph-button-fixed > button.focus {
  color: #ffffff;
  background-color: #007388;
  border-color: rgba(0, 0, 0, 0);
}
.morph-button-fixed > button:hover {
  color: #ffffff;
  background-color: #007388;
  border-color: rgba(0, 0, 0, 0);
}
.morph-button-fixed > button:active,
.morph-button-fixed > button.active,
.open > .dropdown-toggle.morph-button-fixed > button {
  color: #ffffff;
  background-color: #007388;
  background-image: none;
  border-color: rgba(0, 0, 0, 0);
}
.morph-button-fixed > button:active:hover,
.morph-button-fixed > button.active:hover,
.open > .dropdown-toggle.morph-button-fixed > button:hover,
.morph-button-fixed > button:active:focus,
.morph-button-fixed > button.active:focus,
.open > .dropdown-toggle.morph-button-fixed > button:focus,
.morph-button-fixed > button:active.focus,
.morph-button-fixed > button.active.focus,
.open > .dropdown-toggle.morph-button-fixed > button.focus {
  color: #ffffff;
  background-color: #005564;
  border-color: rgba(0, 0, 0, 0);
}
.morph-button-fixed > button.disabled:hover,
.morph-button-fixed > button[disabled]:hover,
fieldset[disabled] .morph-button-fixed > button:hover,
.morph-button-fixed > button.disabled:focus,
.morph-button-fixed > button[disabled]:focus,
fieldset[disabled] .morph-button-fixed > button:focus,
.morph-button-fixed > button.disabled.focus,
.morph-button-fixed > button[disabled].focus,
fieldset[disabled] .morph-button-fixed > button.focus {
  background-color: #009ebb;
  border-color: transparent;
}
.morph-button-fixed > button .badge {
  color: #009ebb;
  background-color: #ffffff;
}
.morph-button-fixed > button:focus,
.morph-button-fixed > button.focus {
  background-color: #33c3e0;
}
.morph-button-fixed > button:hover {
  background-color: #33c3e0;
}
.morph-button-fixed > button:active,
.morph-button-fixed > button.active {
  background-color: #33c3e0;
}
.morph-button-fixed > button:active:hover,
.morph-button-fixed > button.active:hover,
.morph-button-fixed > button:active:focus,
.morph-button-fixed > button.active:focus,
.morph-button-fixed > button:active.focus,
.morph-button-fixed > button.active.focus {
  background-color: #33c3e0;
}
.morph-button-fixed .morph-content {
  background-color: #009ebb;
  height: 40px;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  width: 200px;
  z-index: 900;
  -webkit-transition: background-color 0.5s 0.2s, opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s, box-shadow 0.4s 0.1s;
  transition: background-color 0.5s 0.2s, opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s, box-shadow 0.4s 0.1s;
}
.morph-button-fixed .morph-content > div {
  height: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
  transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
}
.morph-button-fixed .morph-content-dialog {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.morph-button-fixed.open > button {
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}
.morph-button-fixed.open .morph-content {
  -webkit-box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  opacity: 1;
}
.morph-button-fixed.open .morph-content > div {
  height: auto;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0.5s;
  transition: opacity 0.3s 0.5s;
}
.morph-button-fixed.open .morph-content-dialog {
  opacity: 1;
  -webkit-transition: opacity 0.4s 0.5s;
  transition: opacity 0.4s 0.5s;
}
.morph-button-fixed.active > button {
  z-index: 2000;
}
.morph-button-fixed.active .morph-content {
  z-index: 1900;
}
.morph-button-modal:before {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 800;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.morph-button-modal.open:before {
  opacity: 1;
  pointer-events: auto;
}
.morph-button-modal.open .morph-content {
  height: 100%;
  left: 0% !important;
  margin: 0;
  overflow: hidden;
  top: 0% !important;
  width: 100%;
  -webkit-transition: background-color 0.4s 0.1s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, box-shadow 0.4s 0.1s;
  transition: background-color 0.4s 0.1s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, box-shadow 0.4s 0.1s;
}
.morph-button-modal.open .morph-content > div {
  height: 100%;
  -webkit-transition: opacity 0.3s 0.3s;
  transition: opacity 0.3s 0.3s;
}
.morph-button-modal.active:before {
  z-index: 1800;
}
.morph-button .morph-content,
.morph-button.open .morph-content {
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}
.noscroll {
  overflow: hidden;
}
@media (min-width: 992px) {
  .morph-button-fixed {
    height: 69px;
    width: 69px;
  }
  .morph-button-fixed > button {
    line-height: 69px;
  }
  .morph-button-fixed .morph-content {
    height: 69px;
    width: 69px;
  }
  .morph-button-modal:before {
    height: 100vh;
  }
  .morph-button-modal.open .morph-content {
    height: 328px;
    left: 50% !important;
    margin: -164px 0 0 -180px;
    overflow: hidden;
    top: 50vh !important;
    width: 360px;
    -webkit-transition: background-color 0.4s 0.1s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s, box-shadow 0.4s 0.1s;
    transition: background-color 0.4s 0.1s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s, box-shadow 0.4s 0.1s;
  }
  .morph-button-modal.open .morph-content.stage-2 {
    height: 430px;
    margin-top: -215px;
  }
}
.marketing .navbar {
  z-index: 1040;
}
.marketing .navbar .navbar-nav {
  height: auto;
  margin: 0;
}
.marketing.nav-open .navbar {
  z-index: 1039;
}
.marketing .navbar .navbar-header .navbar-brand {
  height: auto;
  padding: 19px 20px;
}
.marketing .navbar .navbar-header .navbar-brand img.logo {
  height: auto;
  margin: 0;
  padding: 0;
  width: 186px;
}
.marketing.modal-open .navbar-header {
  z-index: auto;
}
.marketing .navbar-inverse {
  background: transparent;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.marketing .navbar-inverse.scrolling {
  background: #414042;
  margin-top: 1px !important;
}
.marketing.marketing-header-bg .navbar-inverse {
  background: #414042;
}
.marketing .navbar-toggle {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
  border: none;
  margin: 15px;
  opacity: 0.6;
  padding: 10px;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.marketing .navbar-toggle:focus,
.marketing .navbar-toggle.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.marketing .navbar-toggle:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.marketing .navbar-toggle:active,
.marketing .navbar-toggle.active,
.open > .dropdown-toggle.marketing .navbar-toggle {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border-color: rgba(0, 0, 0, 0);
}
.marketing .navbar-toggle:active:hover,
.marketing .navbar-toggle.active:hover,
.open > .dropdown-toggle.marketing .navbar-toggle:hover,
.marketing .navbar-toggle:active:focus,
.marketing .navbar-toggle.active:focus,
.open > .dropdown-toggle.marketing .navbar-toggle:focus,
.marketing .navbar-toggle:active.focus,
.marketing .navbar-toggle.active.focus,
.open > .dropdown-toggle.marketing .navbar-toggle.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.marketing .navbar-toggle.disabled:hover,
.marketing .navbar-toggle[disabled]:hover,
fieldset[disabled] .marketing .navbar-toggle:hover,
.marketing .navbar-toggle.disabled:focus,
.marketing .navbar-toggle[disabled]:focus,
fieldset[disabled] .marketing .navbar-toggle:focus,
.marketing .navbar-toggle.disabled.focus,
.marketing .navbar-toggle[disabled].focus,
fieldset[disabled] .marketing .navbar-toggle.focus {
  background-color: transparent;
  border-color: transparent;
}
.marketing .navbar-toggle .badge {
  color: transparent;
  background-color: #ffffff;
}
.marketing .navbar-toggle:focus,
.marketing .navbar-toggle:hover {
  opacity: 0.9;
}
.marketing .navbar-toggle:active {
  opacity: 0.9;
}
.marketing .navbar-toggle:active:hover,
.marketing .navbar-toggle:active:focus {
  opacity: 1;
}
.marketing .navbar-toggle .icon-bar {
  height: 1px;
  width: 26px;
}
.marketing .navbar-toggle .icon-bar + .icon-bar {
  margin-top: 7px;
}
.marketing .navbar-collapse {
  background: rgba(37, 37, 37, 0.99);
  height: 100%;
  left: 0;
  padding: 60px 0;
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(50px, 50px);
  -ms-transform: translate(50px, 50px);
  -o-transform: translate(50px, 50px);
  transform: translate(50px, 50px);
}
.marketing .navbar-collapse.open {
  opacity: 1;
  visibility: visible;
  z-index: 1040;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.marketing .navbar-collapse.animating {
  -webkit-transition-property: opacity, transform, visibility;
  transition-property: opacity, transform, visibility;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.marketing .close {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
  font-size: 22px;
  font-weight: normal;
  margin: 10px;
  opacity: 0.6;
  outline: none;
  padding: 10px;
  text-shadow: none;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.marketing .close:focus,
.marketing .close.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.marketing .close:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.marketing .close:active,
.marketing .close.active,
.open > .dropdown-toggle.marketing .close {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border-color: rgba(0, 0, 0, 0);
}
.marketing .close:active:hover,
.marketing .close.active:hover,
.open > .dropdown-toggle.marketing .close:hover,
.marketing .close:active:focus,
.marketing .close.active:focus,
.open > .dropdown-toggle.marketing .close:focus,
.marketing .close:active.focus,
.marketing .close.active.focus,
.open > .dropdown-toggle.marketing .close.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.marketing .close.disabled:hover,
.marketing .close[disabled]:hover,
fieldset[disabled] .marketing .close:hover,
.marketing .close.disabled:focus,
.marketing .close[disabled]:focus,
fieldset[disabled] .marketing .close:focus,
.marketing .close.disabled.focus,
.marketing .close[disabled].focus,
fieldset[disabled] .marketing .close.focus {
  background-color: transparent;
  border-color: transparent;
}
.marketing .close .badge {
  color: transparent;
  background-color: #ffffff;
}
.marketing .close:focus,
.marketing .close:hover {
  opacity: 0.9;
}
.marketing .close:active {
  opacity: 0.9;
}
.marketing .close:active:hover,
.marketing .close:active:focus {
  opacity: 1;
}
.marketing .navbar-collapse-header {
  margin-bottom: 40px;
  text-align: center;
  width: 100%;
}
.marketing .navbar-collapse-header .logo {
  width: 120px;
}
.marketing .navbar-collapse-header .close {
  float: none;
  position: absolute;
  right: 0;
  top: 0;
}
.marketing .navbar .nav-item .nav-item-link {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 3px;
  padding: 20px 15px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
}
.marketing .navbar .nav-item .nav-item-link > span {
  padding-bottom: 8px;
  position: relative;
}
.marketing .navbar .nav-item .nav-item-link > span:after {
  background: #b1d133;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  right: 50%;
  visibility: hidden;
  -webkit-transition-property: left, right, visibility;
  transition-property: left, right, visibility;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.marketing .navbar .nav-item .nav-item-link:hover > span:after,
.marketing .navbar .nav-item .nav-item-link:focus > span:after {
  left: 0;
  right: 0;
  visibility: visible;
}
.marketing .login-button {
  margin-top: 40px;
}
.marketing .login-button > button.nav-item-button {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 0;
  text-transform: uppercase;
}
.marketing .login-button > button.nav-item-button .at-icon {
  font-size: 32px;
}
.marketing .login-button .icon {
  display: none;
}
.marketing .morph-content {
  outline: none;
}
.marketing .morph-content .morph-content-dialog {
  position: relative;
  height: 100%;
  z-index: 20;
}
.marketing .morph-content .morph-content-header {
  text-align: center;
  width: 100%;
}
.marketing .morph-content .morph-content-header .logo {
  margin-bottom: 15px;
  width: 120px;
}
.marketing .morph-content .morph-content-header .close {
  color: #009ebb;
  float: none;
  opacity: 1 !important;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.marketing .morph-content .morph-content-header .close:focus,
.marketing .morph-content .morph-content-header .close:hover {
  color: #33c3e0;
}
.marketing .morph-content .morph-content-header .close:active {
  color: #33c3e0;
}
.marketing .morph-content .morph-content-header .close:active:hover,
.marketing .morph-content .morph-content-header .close:active:focus {
  color: #33c3e0;
}
.marketing .morph-content .morph-content-header .close .icon {
  display: inline-block;
}
.marketing .morph-content .morph-content-header .close.back {
  color: #44565e;
  left: 0;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.marketing .morph-content .morph-content-header .close.back .title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 3px;
  margin-top: 3px;
  text-transform: uppercase;
  vertical-align: middle;
}
.marketing .morph-content .morph-content-header .close.back .icon {
  color: #009ebb;
  vertical-align: middle;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.marketing .morph-content .morph-content-header .close.back:hover {
  color: #33c3e0;
}
.marketing .morph-content .morph-content-header .close.back:hover .icon {
  color: #33c3e0;
}
.marketing .morph-content .morph-content-body .form-body,
.marketing .morph-content .morph-content-body .heading,
.marketing .morph-content .morph-content-body .description {
  padding: 0 20px;
}
.marketing .morph-content .morph-content-body .form-group,
.marketing .morph-content .morph-content-body .support-options {
  margin: 20px auto;
}
.marketing .morph-content .morph-content-body .heading,
.marketing .morph-content .morph-content-body .description {
  max-width: 461px;
}
.marketing .morph-content .morph-content-body .form-group,
.marketing .morph-content .morph-content-body .support-options {
  max-width: 421px;
}
.marketing .morph-content .morph-content-body .heading {
  font-size: 20px;
  letter-spacing: normal;
  margin: 5px auto 15px;
  text-align: center;
}
.marketing .morph-content .morph-content-body .description {
  font-size: 18px;
  margin: 0 auto 10px;
}
.marketing .morph-content .morph-content-body .form-group {
  font-size: 18px;
  line-height: 1.6;
  margin: 15px auto 0;
  text-align: center;
}
.marketing .morph-content .morph-content-body .form-control {
  height: 60px;
}
.marketing .morph-content .morph-content-body .support-options {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.marketing .morph-content .morph-content-body .support-options > li {
  display: inline-block;
  padding: 8px 15px;
}
.marketing .morph-content .morph-content-body .support-options > li + li {
  border-left: 1px solid #cfcfd0;
}
.marketing .morph-content .morph-content-body .support-options > li:first-child {
  padding-left: 0;
}
.marketing .morph-content .morph-content-body .support-options > li:last-child {
  padding-right: 0;
}
.marketing .morph-content .morph-content-body .support-options + .support-options {
  margin: 0;
}
.marketing .morph-content .morph-content-body .support-options.stacked > li {
  display: block;
}
.marketing .morph-content .morph-content-body .support-options.stacked > li + li {
  border-left: 0;
  padding-left: 0;
}
.marketing .morph-content .morph-content-body .form-submit {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
.marketing .morph-content .password-form,
.marketing .morph-content .login-form {
  height: 100%;
  padding: 60px 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.marketing .morph-content .login-form {
  left: 0;
  -webkit-transition-property: left, visibility;
  transition-property: left, visibility;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.marketing .morph-content .login-form.out {
  left: -100%;
  visibility: hidden;
}
.marketing .morph-content .password-form {
  right: -100%;
  visibility: hidden;
  -webkit-transition-property: right, visibility;
  transition-property: right, visibility;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.marketing .morph-content .password-form.in {
  right: 0;
  visibility: visible;
}
@media (min-width: 480px) {
  .marketing .navbar .navbar-header .navbar-brand {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .marketing .navbar .navbar-header .navbar-brand img.logo {
    width: 220px;
  }
}
@media (min-width: 992px) {
  .marketing .navbar-collapse {
    background: transparent;
    opacity: 1;
    padding: 0;
    position: absolute;
    visibility: visible;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  .marketing .navbar-collapse.open {
    z-index: auto;
  }
  .marketing .navbar-collapse-header {
    display: none;
  }
  .marketing .navbar .navbar-header .navbar-brand {
    padding-bottom: 18px;
    padding-top: 18px;
  }
  .marketing .navbar .navbar-header .navbar-brand img.logo {
    width: 220px;
  }
  .marketing .navbar .nav-item .nav-item-link {
    font-size: 14px;
    letter-spacing: 3px;
    padding: 25px 15px;
  }
  .marketing .login-button {
    margin: 0;
  }
  .marketing .login-button .title {
    display: none;
  }
  .marketing .login-button .icon {
    display: block;
  }
  .marketing .morph-content .morph-content-header .logo,
  .marketing .morph-content .morph-content-header .close.back {
    display: none;
  }
  .marketing .morph-content .morph-content-body .heading,
  .marketing .morph-content .morph-content-body .form-group,
  .marketing .morph-content .morph-content-body .support-options {
    margin-left: 0;
    margin-right: 0;
  }
  .marketing .morph-content .morph-content-body .heading {
    text-align: left;
    color: #333;
  }
  .marketing .password-form .morph-content-header .close.back {
    display: inline-block;
  }
}
.modal-popup {
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1900;
}
.modal-popup:before {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
}
.modal-popup.animating {
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.modal-popup.open {
  opacity: 1;
  visibility: visible;
}
.modal-popup .modal-popup-dialog {
  -webkit-box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  left: 50%;
  padding: 60px 0 20px;
  position: absolute;
  top: 50%;
  width: 365px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.modal-popup .modal-popup-header .close {
  color: #009ebb;
  float: none;
  position: absolute;
  right: 0;
  top: 0;
}
.modal-popup .modal-popup-header .close:active:focus {
  color: #009ebb;
}
.modal-popup .modal-popup-header .close .icon {
  display: inline-block;
}
.modal-popup .modal-popup-body .form-body {
  position: relative;
}
.modal-popup .modal-popup-body .heading,
.modal-popup .modal-popup-body .description,
.modal-popup .modal-popup-body .form-group,
.modal-popup .modal-popup-body .support-options,
.modal-popup .modal-popup-body .modal-popup-controls {
  margin: 20px auto;
  padding: 0 20px;
}
.modal-popup .modal-popup-body .modal-popup-controls {
  margin-bottom: 0;
}
.modal-popup .modal-popup-body .heading {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0;
}
.modal-popup .modal-popup-body .description {
  line-height: 1.6;
  white-space: pre-line;
}
.marketing .hero-container .carousel .at-image-container,
.marketing .hero-container .carousel .fill {
  height: 100%;
}
.marketing .hero-container .carousel .fill {
  background-position: center bottom;
}
.marketing .hero-container.marketing-features .carousel .fill {
  background-position: center 35%;
}
.marketing .hero-container .hero-bg .at-image-container .fill,
.marketing .hero-container .hero-image .at-image-container .fill {
  height: calc(100% +  10px * 2);
  left: -10px;
  position: absolute;
  top: -10px;
  width: calc(100% +  10px * 2);
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
.marketing .hero-container .hero-bg {
  background: #666;
}
.marketing .hero-container .hero-bg .item {
  height: 600px;
}
.marketing .hero-container .hero-bg .at-image-container {
  overflow: hidden;
}
.marketing .hero-container .hero-bg .at-image-container:after {
  background: #000000;
  bottom: -10px;
  content: "";
  display: block;
  left: -10px;
  opacity: 0.5;
  position: absolute;
  right: -10px;
  top: -10px;
}
.marketing .hero-container .hero-bg .caption .heading {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.marketing .hero-container .hero-large .item,
.marketing .hero-container .hero-large-caption .item {
  height: 475px;
}
.marketing .hero-container .hero-bg .link,
.marketing .hero-container .hero-large-caption .link {
  width: 192px;
}
.marketing .hero-container .hero-small {
  float: left;
  width: 50%;
}
.marketing .hero-container .hero-small .item {
  height: 200px;
}
.marketing .hero-container .hero-small .carousel-inner:after {
  border: 1px solid #ffffff;
  border-bottom: none;
  bottom: 0;
  content: "";
  display: block;
  left: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.marketing .hero-container .hero-image .item {
  height: 600px;
}
.marketing .hero-container .hero-image .at-image-container:before {
  background: #000000;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.marketing .hero-container .hero-image .fill {
  background-position: center center;
}
.marketing .hero-container .gallery-caption-wrapper {
  bottom: 0;
  font-size: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.marketing .hero-container .gallery-caption-wrapper:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.marketing .hero-container .gallery-caption-wrapper .gallery-caption {
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: middle;
  width: 100%;
  z-index: 2;
}
.marketing .hero-container .caption .caption-inner {
  padding: 30px;
  text-align: center;
}
.marketing .hero-container .caption .heading {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 1;
  margin: 0 0 30px;
  position: relative;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.75);
}
.marketing .hero-container .caption .title,
.marketing .hero-container .caption .subtitle {
  display: block;
}
.marketing .hero-container .caption .title {
  font-size: 60px;
}
.marketing .hero-container .caption .subtitle {
  font-size: 45px;
}
.marketing .hero-container .caption .description {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 20px;
}
.marketing .hero-container .caption .description.small-margin {
  margin-bottom: 15px;
}
.marketing .hero-container .caption .description a {
  color: #ffffff;
}
.marketing .hero-container .caption .link .link-text {
  font-size: 14px;
}
.marketing .hero-container .hero-large-caption {
  left: 0;
  position: absolute;
  top: 36px;
  width: 100%;
}
.marketing .hero-container .hero-large-caption .caption .caption-inner {
  padding-top: 145px;
}
.marketing .hero-container .hero-large-caption .caption .heading {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.marketing .hero-container .hero-large-caption .caption .heading:before {
  background-image: -webkit-radial-gradient(center center, ellipse farthest-corner, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 70%);
  background-image: radial-gradient(ellipse farthest-corner at center center, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 70%);
  bottom: -60px;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: -60px;
  z-index: -1;
}
.marketing .hero-container .hero-large-caption {
  z-index: 20;
}
.marketing .hero-container .hero-image .caption .caption-inner {
  padding: 45px 15px 30px;
}
.marketing .hero-container .carousel-overlay {
  display: none;
  left: 0;
  padding: 0 15px 100px;
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 115px;
  z-index: 2;
}
.marketing .hero-container .hero-image .gallery-caption-wrapper {
  margin: 0 auto;
}
.marketing .hero-container .hero-image .item {
  height: 500px;
}
.marketing .hero-container .hero-image .heading {
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.2;
}
.marketing .hero-container .hero-image .heading:after {
  background: #b1d133;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 72px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing .hero-container .hero-image .heading .title {
  font-size: 42px;
}
.marketing .hero-container .hero-image .description {
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 30px;
}
@media (min-width: 480px) {
  .marketing .hero-container .hero-image .heading .title {
    font-size: 50px;
  }
  .marketing .hero-container .hero-image .description {
    font-size: 16px;
    line-height: 1.7;
  }
}
@media (min-width: 768px) {
  .marketing .hero-container .hero-bg .gallery-caption-wrapper {
    display: none;
  }
  .marketing .hero-container .hero-bg .item {
    height: 720px;
  }
  .marketing .hero-container .hero-bg .at-image-container:after {
    opacity: 0.75;
  }
  .marketing .hero-container .carousel-overlay {
    display: block;
  }
  .marketing .hero-container .hero-image .item {
    height: 600px;
  }
  .marketing .hero-container .hero-image .heading .title {
    font-size: 70px;
  }
  .marketing .hero-container .hero-image .description {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .marketing .hero-container .caption .heading {
    line-height: 1.1;
  }
  .marketing .hero-container .caption .description {
    margin-bottom: 25px;
  }
  .marketing .hero-container .caption .description.small-margin {
    margin-bottom: 20px;
  }
}
.marketing-spotlight {
  padding: 0 15px 60px;
}
.marketing-spotlight .title {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}
.marketing-spotlight .link {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing-spotlight .caption.section-caption .description {
  margin-bottom: 30px;
}
.marketing-spotlight .feature-list-item {
  display: block;
  margin: 0 auto;
  max-width: 280px;
  line-height: 38px;
}
.marketing-spotlight.our-story {
  padding-bottom: 30px;
}
.marketing-spotlight.how-it-works {
  padding-top: 0;
  padding-bottom: 0;
}
.marketing-spotlight.how-it-works .title {
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
  margin-bottom: 35px;
  max-width: 710px;
  text-align: center;
}
.marketing-spotlight.how-it-works .title:after {
  background: #b1d133;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 72px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media (min-width: 992px) {
  .marketing-spotlight.how-it-works .title {
    margin-bottom: 18px;
    padding-bottom: 20px;
  }
}
.marketing-spotlight.how-it-works .feature-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: block;
  padding: 0 15px;
}
.marketing-spotlight.how-it-works .feature-item.tablet-hidden,
.marketing-spotlight.how-it-works .feature-item.desktop-hidden {
  display: none;
}
.marketing-spotlight.how-it-works .feature-item.tablet-hidden .feature-frame,
.marketing-spotlight.how-it-works .feature-item.desktop-hidden .feature-frame,
.marketing-spotlight.how-it-works .feature-item.tablet-hidden .feature-caption,
.marketing-spotlight.how-it-works .feature-item.desktop-hidden .feature-caption {
  opacity: 0;
}
.marketing-spotlight.how-it-works .feature-item.tablet-hidden.show-feature,
.marketing-spotlight.how-it-works .feature-item.desktop-hidden.show-feature {
  display: block;
}
.marketing-spotlight.how-it-works .feature-item.tablet-hidden.show-feature.animate-in .feature-frame,
.marketing-spotlight.how-it-works .feature-item.desktop-hidden.show-feature.animate-in .feature-frame {
  opacity: 1;
}
.marketing-spotlight.how-it-works .feature-item.tablet-hidden.show-feature:hover.animate-in .feature-caption,
.marketing-spotlight.how-it-works .feature-item.desktop-hidden.show-feature:hover.animate-in .feature-caption {
  opacity: 1;
}
.marketing-spotlight.how-it-works .feature-item .feature-frame,
.marketing-spotlight.how-it-works .feature-item .feature-caption {
  -webkit-transition-property: left, opacity, top;
  transition-property: left, opacity, top;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.marketing-spotlight.how-it-works .feature-item .feature-caption {
  background: #009ebb;
  color: #ffffff;
  left: 15px;
  height: 0;
  position: absolute;
  top: 0;
  width: 0;
  opacity: 0;
  width: calc(100% -  30px);
  min-height: calc(100% -  30px);
}
.marketing-spotlight.how-it-works .feature-item .feature-caption .caption-icon {
  color: rgba(255, 255, 255, 0.1);
  display: block;
  font-size: 7em;
  left: 50%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.marketing-spotlight.how-it-works .feature-item .feature-caption .caption {
  font-size: 14px;
  left: 50%;
  padding: 20px 15px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.marketing-spotlight.how-it-works .feature-item.in-focus .feature-caption,
.marketing-spotlight.how-it-works .feature-item:hover .feature-caption {
  left: 30px;
  opacity: 1;
  top: 15px;
}
.marketing-spotlight.how-it-works .feature-frame {
  border: 1px solid #009ebb;
  margin-bottom: 30px;
  padding: 30px 10px;
  text-align: center;
}
.marketing-spotlight.how-it-works .feature-frame .icon {
  color: #009ebb;
  font-size: 35px;
  height: 35px;
  margin-bottom: 20px;
  position: relative;
  width: 35px;
}
.marketing-spotlight.how-it-works .feature-frame .icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.marketing-spotlight.how-it-works .feature-frame .description {
  margin-bottom: 0;
}
.marketing-spotlight.how-it-works .feature-frame.show-more .icon {
  color: #ffffff;
  position: relative;
  -webkit-transition-property: color, font-size;
  transition-property: color, font-size;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.marketing-spotlight.how-it-works .feature-frame.show-more .icon:before {
  z-index: 2;
}
.marketing-spotlight.how-it-works .feature-frame.show-more .icon:after {
  background: #b1d133;
  border-radius: 50%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.marketing-spotlight.how-it-works .feature-frame.show-more:hover {
  cursor: pointer;
}
.marketing-spotlight.how-it-works .feature-frame.show-more:hover .icon {
  font-size: 18px;
}
.marketing-spotlight .client-list {
  text-align: left;
}
.marketing-spotlight .client-list .client-row {
  margin: 0 0 75px;
}
.marketing-spotlight .client-list .client-row:last-child {
  margin: 0;
}
.marketing-spotlight .client-list .client-logo-container {
  width: 100%;
  padding: 0 15px;
}
.marketing-spotlight .client-list .title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  letter-spacing: normal;
  margin: 0 0 30px;
  max-width: none;
  font-size: 40px;
  line-height: 1.2;
}
.marketing-spotlight .client-list .has-border {
  border-bottom: 1px solid #cbced0;
  position: relative;
}
.marketing-spotlight .client-list .has-border:last-of-type {
  border-bottom-width: 0;
}
.marketing-spotlight .client-list .client-logo {
  max-width: 100%;
  width: 100%;
  padding: 10px;
}
.marketing-spotlight.marketing-spotlight-home .section-caption {
  text-align: center;
}
.marketing-spotlight.marketing-spotlight-home .section-caption .title {
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}
.marketing-spotlight.marketing-spotlight-home .section-caption .title:after {
  background: #b1d133;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 72px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media (min-width: 992px) {
  .marketing-spotlight.marketing-spotlight-home .section-caption .title {
    margin-bottom: 18px;
    padding-bottom: 20px;
  }
  .marketing-spotlight.marketing-spotlight-home .section-caption .title:after {
    left: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.marketing-spotlight.marketing-spotlight-home .flexbox-center {
  -webkit-box-flex-flow: column;
  -moz-box-flex-flow: column;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
}
.marketing-spotlight.marketing-spotlight-home .mobile-only .link {
  margin-top: 20px;
}
@media (min-width: 480px) {
  .marketing-spotlight {
    padding-bottom: 80px;
  }
  .marketing-spotlight.how-it-works .feature-item {
    width: 50%;
    float: left;
  }
  .marketing-spotlight .client-list .client-logo-container {
    width: 50%;
  }
  .marketing-spotlight .client-list .has-border:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: #cbced0;
  }
  .marketing-spotlight .client-list .has-border:nth-child(2n + 2):after {
    content: none;
  }
  .marketing-spotlight .client-list .has-border:last-of-type:after {
    content: none;
  }
  .marketing-spotlight .client-list .has-border:nth-last-child(2) {
    border-bottom-width: 0;
  }
}
@media (min-width: 768px) {
  .marketing-spotlight.how-it-works .feature-item {
    width: calc(100% /  3);
    float: left;
  }
  .marketing-spotlight .feature-list-item {
    display: list-item;
    max-width: none;
  }
  .marketing-spotlight .client-list .client-logo-container {
    width: 33.3333333%;
  }
  .marketing-spotlight .client-list .has-border:nth-child(2n + 2):after {
    content: "";
  }
  .marketing-spotlight .client-list .has-border:nth-last-child(2) {
    border-bottom-width: 0;
  }
  .marketing-spotlight .client-list .has-border:nth-child(3n + 3):after {
    content: none;
  }
}
@media (min-width: 992px) {
  .marketing-spotlight {
    padding-bottom: 80px;
  }
  .marketing-spotlight .link.desktop-only {
    display: inline-block;
    left: auto;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .marketing-spotlight .caption.section-caption .description {
    margin-bottom: 25px;
  }
  .marketing-spotlight .caption.section-caption .title:after {
    left: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .marketing-spotlight.marketing-spotlight-home .section-caption {
    text-align: left;
  }
  .marketing-spotlight.marketing-spotlight-home .flexbox-center {
    -webkit-box-flex-flow: row;
    -moz-box-flex-flow: row;
    -webkit-flex-flow: row;
    -ms-flex-flow: row;
    flex-flow: row;
  }
  .marketing-spotlight.how-it-works {
    padding-bottom: 0;
  }
  .marketing-spotlight.how-it-works .title {
    margin-bottom: 35px;
  }
  .marketing-spotlight.how-it-works .feature-item {
    width: 25%;
    float: left;
  }
  .marketing-spotlight.how-it-works .feature-item.tablet-hidden {
    display: block;
  }
  .marketing-spotlight.how-it-works .feature-item.tablet-hidden .feature-frame {
    opacity: 1;
  }
  .marketing-spotlight.how-it-works .feature-item.tablet-hidden .feature-caption {
    opacity: 0;
  }
  .marketing-spotlight.how-it-works .feature-item.tablet-hidden:hover .feature-caption {
    opacity: 1;
  }
  .marketing-spotlight.how-it-works .feature-item.desktop-hidden .feature-frame,
  .marketing-spotlight.how-it-works .feature-item.desktop-hidden .feature-caption {
    opacity: 0;
  }
  .marketing-spotlight.how-it-works .feature-item.desktop-hidden.show-feature {
    display: block;
  }
  .marketing-spotlight.how-it-works .feature-item.desktop-hidden.show-feature.animate-in .feature-frame {
    opacity: 1;
  }
  .marketing-spotlight.how-it-works .feature-item.desktop-hidden.show-feature.animate-in:hover .feature-caption {
    opacity: 1;
  }
  .marketing-spotlight.how-it-works .caption.section-caption .title:after {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .marketing-spotlight.our-story {
    padding-bottom: 80px / 2;
  }
  .marketing-spotlight .client-list .client-logo-container {
    width: 25%;
  }
  .marketing-spotlight .client-list .has-border:nth-last-child(2) {
    border-bottom-width: 0;
  }
  .marketing-spotlight .client-list .has-border:nth-child(3n + 3):after {
    content: "";
  }
  .marketing-spotlight .client-list .has-border:nth-child(4n + 4):after {
    content: none;
  }
  .marketing-spotlight .client-list .has-border:nth-last-child(3),
  .marketing-spotlight .client-list .has-border:nth-last-child(4) {
    border-bottom-width: 0;
  }
}
.editor .marketing-tabs.nav-tabs {
  border-bottom: none;
  position: absolute;
  top: 410px;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
}
.editor .marketing-tabs.nav-tabs > li {
  width: 50%;
}
.editor .marketing-tabs.nav-tabs > li.active > a:hover,
.editor .marketing-tabs.nav-tabs > li:not(.active) > a:hover,
.editor .marketing-tabs.nav-tabs > li.active > a:focus,
.editor .marketing-tabs.nav-tabs > li:not(.active) > a:focus {
  border: none;
}
.editor .marketing-tabs.nav-tabs > li > a {
  height: 90px;
  font-size: 14px;
  font-weight: 700;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  background: linear-gradient(to right, #b3b2b2 0, #cfcfd0 100%);
  border-radius: 0;
  border: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.editor .marketing-tabs.nav-tabs > li > a .at-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #666;
  color: #666;
  font-size: 21px;
  text-align: center;
  line-height: 45px;
  margin: 0 100%;
  display: block;
  flex-shrink: 0;
  transition: all 0.125s ease;
}
.editor .marketing-tabs.nav-tabs > li > a .at-icon::before {
  display: inline-block;
}
.editor .marketing-tabs.nav-tabs > li > a.marketing-tab--higher-ed .at-icon::before {
  transform: translateX(1px) translateY(-1px);
}
.editor .marketing-tabs.nav-tabs > li > a.marketing-tab--tourism .at-icon {
  font-size: 19px;
}
.editor .marketing-tabs.nav-tabs > li > a.marketing-tab--tourism .at-icon::before {
  transform: translateX(2px) translateY(-1px);
}
.editor .marketing-tabs.nav-tabs > li > a:hover .at-icon {
  background-color: #009ebb;
  color: #ffffff;
  border-color: #009ebb;
}
.editor .marketing-tabs.nav-tabs > li.active > a {
  background: #e6e7e8;
  color: #009ebb;
}
.editor .marketing-tabs.nav-tabs > li.active > a .at-icon {
  background-color: #009ebb;
  color: #ffffff;
  border-color: #009ebb;
}
@media (min-width: 480px) {
  .editor .marketing-tabs.nav-tabs > li > a {
    flex-wrap: nowrap;
  }
  .editor .marketing-tabs.nav-tabs > li > a .at-icon {
    margin: 0 12px 0 0;
  }
}
@media (min-width: 768px) {
  .editor .marketing-tabs.nav-tabs {
    top: 510px;
  }
  .editor .marketing-tabs.nav-tabs > li {
    width: 340px;
  }
  .editor .marketing-tabs.nav-tabs > li > a .at-icon {
    border: 2px solid #666;
    width: 64px;
    height: 64px;
    font-size: 30px;
    line-height: 64px;
  }
  .editor .marketing-tabs.nav-tabs > li > a .at-icon::before {
    display: inline-block;
  }
  .editor .marketing-tabs.nav-tabs > li > a.marketing-tab--tourism .at-icon {
    font-size: 27px;
  }
  .editor .marketing-tabs.nav-tabs > li + li > a {
    margin-left: 10px;
  }
}
.feature-list {
  font-size: 20px;
  list-style: none;
  padding-left: 0;
}
.feature-list .feature-list-item {
  padding: 0 15px 40px 0;
}
.feature-list .icon {
  color: #009ebb;
  font-size: 1.9em;
  margin-right: 15px;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .feature-list .feature-list-item:last-child {
    padding-bottom: 0;
  }
}
@media (min-width: 992px) {
  .feature-list .icon {
    vertical-align: top;
  }
  .feature-list {
    font-size: 18px;
  }
  .feature-list .feature-list-item {
    padding: 0 0 60px;
  }
  .marketing-spotlight-home .feature-list-container:first-of-type .feature-list:after {
    content: "";
    display: block;
    height: 118%;
    width: 1px;
    background: #afafaf;
    position: absolute;
    top: 0;
    right: 30px;
  }
}
@media (min-width: 1200px) {
  .feature-list {
    font-size: 20px;
  }
}
.jumbotron,
.container .jumbotron {
  background: transparent;
  text-align: center;
  margin-bottom: 0;
  padding: 0 0 40px;
}
.jumbotron .heading,
.container .jumbotron .heading {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 40px;
  letter-spacing: normal;
  line-height: 1.2;
  margin: 0 auto;
  max-width: 285px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
}
.jumbotron .heading:after,
.container .jumbotron .heading:after {
  background: #b1d133;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 72px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media (min-width: 992px) {
  .jumbotron .heading,
  .container .jumbotron .heading {
    margin-bottom: 18px;
    padding-bottom: 20px;
  }
}
.jumbotron .summary,
.container .jumbotron .summary {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
  position: relative;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
@media (min-width: 480px) {
  .jumbotron .heading,
  .container .jumbotron .heading {
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .jumbotron,
  .container .jumbotron {
    padding-bottom: 0;
    padding-top: 20px;
  }
  .jumbotron .heading,
  .container .jumbotron .heading {
    max-width: none;
  }
  .jumbotron .summary,
  .container .jumbotron .summary {
    margin-bottom: 25px;
  }
}
.pricing {
  overflow: hidden;
  padding: 55px 15px;
}
.pricing .plans {
  position: relative;
}
.pricing .caption {
  margin-bottom: 30px;
}
.pricing .caption:last-of-type {
  margin-bottom: 0;
}
.pricing .caption-inner {
  height: 100%;
}
.pricing .caption-inner .title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
  font-size: 40px;
  letter-spacing: normal;
  line-height: 1;
  margin: 0 0 10px;
}
.pricing .caption-inner .title:after {
  background: #b1d133;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 72px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.pricing .caption-inner .title .brand,
.pricing .caption-inner .title .plan {
  display: block;
}
.pricing .caption-inner .summary {
  font-size: 18px;
  line-height: 1.6;
}
.pricing .caption-inner .subtitle {
  color: #009ebb;
  font-size: 20px;
  margin-bottom: 30px;
}
.pricing .caption-inner .description {
  font-size: 16px;
  margin: 0;
}
.pricing .caption-inner .view-inner {
  height: 100%;
}
.pricing .caption-inner .view-inner .view-bg {
  background: #ffffff;
  height: 100%;
  padding: 60px 30px;
  position: relative;
  text-align: center;
}
@media (min-width: 480px) {
  .pricing .caption-inner .title .brand,
  .pricing .caption-inner .title .plan {
    display: inline;
  }
}
@media (min-width: 768px) {
  .pricing .caption {
    margin-bottom: 0;
  }
  .pricing .caption-inner .title .brand,
  .pricing .caption-inner .title .plan {
    display: block;
  }
  .pricing .caption-inner .view-inner .view-bg {
    padding: 60px 66px;
  }
}
@media (min-width: 992px) {
  .pricing {
    padding-bottom: 80px;
    padding-top: 75px;
  }
  .pricing .caption-inner .title .brand,
  .pricing .caption-inner .title .plan {
    display: inline;
  }
}
.statement-carousel {
  position: relative;
  background: #000;
}
.statement-carousel:before {
  border-left: 45px solid transparent;
  border-right: 45px solid transparent;
  border-top: 45px solid #ffffff;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 0;
  z-index: 1;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.statement-carousel .at-image-container {
  height: 400px;
}
.statement-carousel .at-image-container .fill {
  height: calc(250% +  10px * 2);
  left: -10px;
  position: absolute;
  top: -10px;
  width: calc(100% +  10px * 2);
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
.statement-carousel .gallery-caption-wrapper {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  font-size: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.statement-carousel .gallery-caption-wrapper:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.statement-carousel .gallery-caption-wrapper .gallery-caption {
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
.statement-carousel .gallery-caption-wrapper .caption-link {
  margin: 0 auto;
  width: 100%;
}
.statement-carousel .caption-inner {
  padding: 15px 30px;
}
.statement-carousel .caption {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  width: 100%;
}
.statement-carousel .caption .title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 1em;
  line-height: 1.15;
  margin-bottom: 30px;
}
.statement-carousel .caption.testimonial-caption .title:before,
.statement-carousel .caption.testimonial-caption .title:after {
  color: #b1d133;
  display: inline;
  font-size: 40px;
  line-height: 0;
  position: relative;
}
.statement-carousel .caption.testimonial-caption .title:before {
  content: "\201c";
  top: 8px;
}
.statement-carousel .caption.testimonial-caption .title:after {
  content: "\201d";
  top: 10px;
  transform: scale(-1);
}
.statement-carousel .caption .citation,
.statement-carousel .caption .link {
  font-size: 0.75em;
  line-height: 1.1;
  margin-bottom: 0;
}
.statement-carousel .caption .citation {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.statement-carousel .caption .link {
  font-style: italic;
  color: #ffffff;
  position: relative;
  display: inline-block;
}
.statement-carousel .caption .link:after {
  background: #ffffff;
  bottom: -6px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  right: 50%;
  visibility: hidden;
  -webkit-transition-property: left, right, visibility;
  transition-property: left, right, visibility;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.statement-carousel .caption .link:hover,
.statement-carousel .caption .link:focus {
  color: #ffffff;
  text-decoration: none;
}
.statement-carousel .caption .link:hover:after,
.statement-carousel .caption .link:focus:after {
  left: 0;
  right: 0;
  visibility: visible;
}
.statement-carousel .carousel-indicators {
  margin: 0;
  width: auto;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.statement-carousel .carousel-indicators .indicator {
  background: #ffffff;
  border: 0;
  height: 16px;
  margin: 0;
  width: 16px;
}
.statement-carousel .carousel-indicators .indicator.active {
  background: #009ebb;
}
.statement-carousel .carousel-indicators .indicator + .indicator {
  margin-left: 8px;
}
@media (min-width: 480px) {
  .statement-carousel .caption .title {
    font-size: 1.3em;
  }
  .statement-carousel .caption .citation,
  .statement-carousel .caption .link {
    font-size: 1em;
  }
  .statement-carousel .caption.testimonial-caption .title:after {
    top: 5px;
  }
}
@media (min-width: 768px) {
  .statement-carousel .caption .title {
    font-size: 1.6em;
  }
  .statement-carousel .gallery-caption-wrapper .caption-link {
    max-width: 630px;
  }
}
@media (min-width: 992px) {
  .statement-carousel .gallery-caption-wrapper .caption-link {
    max-width: 850px;
  }
  .statement-carousel .at-image-container {
    height: 500px;
  }
  .statement-carousel .caption {
    font-size: 20px;
  }
  .statement-carousel .caption .title {
    font-size: 2em;
    line-height: 1.125;
    margin-bottom: 25px;
  }
  .statement-carousel .caption.testimonial-caption .title:before,
  .statement-carousel .caption.testimonial-caption .title:after {
    font-size: 100px;
  }
  .statement-carousel .caption.testimonial-caption .title:before {
    top: 40px;
  }
  .statement-carousel .caption.testimonial-caption .title:after {
    top: 34px;
  }
}
@media (min-width: 1200px) {
  .statement-carousel .gallery-caption-wrapper .caption-link {
    max-width: 1050px;
  }
}
.marketing-designs {
  padding: 0 15px;
  position: relative;
}
.marketing-designs .container {
  padding: 80px 0;
}
.marketing-designs .grid-item {
  float: left;
  padding: 0 15px;
  position: relative;
  width: 100%;
}
.marketing-designs .grid-item .grid-frame {
  background: #ffffff;
  border-radius: 10px;
  display: block;
  margin-bottom: 30px;
  padding: 15px;
  -webkit-box-shadow: 0px 12px 50px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 12px 50px -20px rgba(0, 0, 0, 0.75);
}
.marketing-designs .grid-item .grid-placeholder {
  background: #cfcfd0;
  text-align: center;
  position: relative;
  padding: 20px;
  display: none;
}
.marketing-designs .grid-item .grid-placeholder .title {
  font-size: 40px;
  font-weight: 300;
  width: 100%;
  line-height: 1;
  margin-bottom: 15px;
}
.marketing-designs .grid-item .grid-placeholder .inner {
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.marketing-designs .grid-item .grid-placeholder:after {
  content: "";
  display: block;
  padding-bottom: 27%;
}
.marketing-designs .grid-item .grid-placeholder .icon {
  color: #009ebb;
  font-size: 35px;
}
.marketing-designs .grid-item .design-thumbnail {
  overflow: hidden;
  position: relative;
}
.marketing-designs .grid-item .design-thumbnail:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  -webkit-box-shadow: inset 0px 0px 50px -15px #000000;
  box-shadow: inset 0px 0px 50px -15px #000000;
}
.marketing-designs .grid-item .design-thumbnail:after {
  content: "";
  display: block;
  padding-bottom: 61.3%;
}
.marketing-designs .grid-item .design-thumbnail .thumbnail-image {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.marketing-designs .grid-item .design-thumbnail .overlay-link {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.marketing-designs .grid-item .design-thumbnail .overlay-link .link {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.marketing-designs .grid-item .design-thumbnail:hover .overlay-link {
  opacity: 1;
}
.marketing-designs .grid-item .design-thumbnail.zoom .thumbnail-image {
  max-width: 115%;
  width: 115%;
}
@media (min-width: 560px) {
  .marketing-designs .grid-item {
    width: 50%;
  }
  .marketing-designs .grid-item.grid-item-2-col {
    width: 100%;
  }
  .marketing-designs .grid-item .grid-placeholder {
    display: block;
  }
}
@media (min-width: 992px) {
  .marketing-designs .grid-item {
    width: 33.33333333%;
  }
  .marketing-designs .grid-item.grid-item-2-col {
    width: 66.66666667%;
  }
}
.marketing-steps-section {
  padding: 0 15px;
}
.marketing-steps {
  list-style: none;
  padding: 0;
}
.marketing-steps .icon-container {
  font-size: 35px;
  margin: 0 auto 20px;
}
.marketing-steps .icon {
  border-radius: 50%;
  border: 2px solid #009ebb;
  color: #009ebb;
  height: 100px;
  padding: 28px;
  width: 100px;
}
.marketing-steps .step-item {
  position: relative;
  text-align: center;
  width: 50%;
  float: left;
  margin-bottom: 20px;
}
.marketing-steps .step-item:nth-child(3),
.marketing-steps .step-item:last-of-type {
  margin-bottom: 0;
}
.marketing-steps .step-item-3 .icon {
  font-size: 38px;
}
.marketing-steps .step-item-4 .icon {
  font-size: 40px;
}
@media (min-width: 768px) {
  .marketing-steps .icon-container {
    margin: 0 auto 25px;
  }
  .marketing-steps .step-item {
    width: 25%;
    margin-bottom: 0;
  }
  .marketing-steps .step-item:after {
    background: #cccccc;
    content: "";
    display: block;
    height: 2px;
    left: calc(50% +  50px);
    position: absolute;
    top: 50px;
    width: calc(100% -  100px);
  }
  .marketing-steps .step-item:last-of-type:after {
    content: none;
  }
}
.design-detail .hero-container .hero-image .heading {
  font-size: 14px;
}
.design-detail .hero-container .hero-image .heading .view-type {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.design-detail .hero-container .hero-image .view-buttons .view-btn {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
  background: none;
  border: 2px solid #ffffff;
  border-radius: 68px;
  height: 68px;
  position: relative;
  opacity: 0.5;
  outline: none;
  text-align: center;
  vertical-align: top;
  width: 68px;
  -webkit-transition-property: background-color, border-color, opacity;
  transition-property: background-color, border-color, opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.design-detail .hero-container .hero-image .view-buttons .view-btn:focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.design-detail .hero-container .hero-image .view-buttons .view-btn:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.design-detail .hero-container .hero-image .view-buttons .view-btn:active,
.design-detail .hero-container .hero-image .view-buttons .view-btn.active,
.open > .dropdown-toggle.design-detail .hero-container .hero-image .view-buttons .view-btn {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border-color: rgba(0, 0, 0, 0);
}
.design-detail .hero-container .hero-image .view-buttons .view-btn:active:hover,
.design-detail .hero-container .hero-image .view-buttons .view-btn.active:hover,
.open > .dropdown-toggle.design-detail .hero-container .hero-image .view-buttons .view-btn:hover,
.design-detail .hero-container .hero-image .view-buttons .view-btn:active:focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn.active:focus,
.open > .dropdown-toggle.design-detail .hero-container .hero-image .view-buttons .view-btn:focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn:active.focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn.active.focus,
.open > .dropdown-toggle.design-detail .hero-container .hero-image .view-buttons .view-btn.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.design-detail .hero-container .hero-image .view-buttons .view-btn.disabled:hover,
.design-detail .hero-container .hero-image .view-buttons .view-btn[disabled]:hover,
fieldset[disabled] .design-detail .hero-container .hero-image .view-buttons .view-btn:hover,
.design-detail .hero-container .hero-image .view-buttons .view-btn.disabled:focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn[disabled]:focus,
fieldset[disabled] .design-detail .hero-container .hero-image .view-buttons .view-btn:focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn.disabled.focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn[disabled].focus,
fieldset[disabled] .design-detail .hero-container .hero-image .view-buttons .view-btn.focus {
  background-color: transparent;
  border-color: transparent;
}
.design-detail .hero-container .hero-image .view-buttons .view-btn .badge {
  color: transparent;
  background-color: #ffffff;
}
.design-detail .hero-container .hero-image .view-buttons .view-btn:hover,
.design-detail .hero-container .hero-image .view-buttons .view-btn:focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn.active,
.design-detail .hero-container .hero-image .view-buttons .view-btn:hover:focus,
.design-detail .hero-container .hero-image .view-buttons .view-btn.active:hover,
.design-detail .hero-container .hero-image .view-buttons .view-btn.active:focus {
  border-color: #009ebb;
  background: #009ebb;
  opacity: 1;
}
.design-detail .hero-container .hero-image .view-buttons .view-btn .at-icon {
  display: inline;
  font-size: 34px;
  line-height: 64px;
  position: relative;
}
.design-detail .hero-container .hero-image .view-buttons .view-btn + .view-btn {
  margin-left: 15px;
}
.design-detail .hero-container .hero-image .caption .caption-inner {
  padding-top: 0;
}
.design-detail .marketing-bg-left {
  background-position: -765px -622px;
}
.design-detail .marketing-bg-left:before {
  content: none;
}
.marketing-design-row .design-row-container {
  margin-top: -160px;
  overflow: hidden;
  padding: 0 15px;
  position: relative;
  width: 100%;
}
.marketing-design-row .design-row-container > .container {
  height: 600px;
  position: relative;
}
.marketing-design-row .design-row-container .view-wrapper {
  height: 0;
  left: 15px;
  position: absolute;
  top: 0;
  width: calc(100% - 30px);
}
.marketing-design-row .view-desktop,
.marketing-design-row .view-mobile {
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.marketing-design-row .view-desktop .heading,
.marketing-design-row .view-mobile .heading {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  display: block;
  font-size: 18px;
  left: 50%;
  letter-spacing: 1px;
  position: absolute;
  top: 18px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.marketing-design-row .iphone {
  position: relative;
  width: 310px;
}
.marketing-design-row .iphone .screen div {
  height: 100%;
}
.marketing-design-row .iphone .body {
  background: #ffffff;
  border-radius: 25px;
  height: 599.85px;
  position: relative;
  width: 100%;
}
.marketing-design-row .iphone .body:before {
  border-radius: 50%;
  border: 2px solid #d5d5d5;
  content: "";
  display: block;
  height: 1.5%;
  left: 33%;
  position: absolute;
  top: 5%;
  width: 3%;
}
.marketing-design-row .iphone .body:after {
  border-radius: 10px;
  border: 2px solid #d5d5d5;
  content: "";
  display: block;
  height: 1.5%;
  left: 50%;
  position: absolute;
  top: 5%;
  width: 19%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing-design-row .iphone .body.no-detail:before,
.marketing-design-row .iphone .body.no-detail:after {
  content: none;
}
.marketing-design-row .iphone .body .screen {
  background: #ffffff;
  color: #ffffff;
  height: 75%;
  left: 50%;
  position: absolute;
  top: 11.88%;
  width: 93.1%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing-design-row .iphone .body .screen .carousel .item {
  height: 100%;
}
.marketing-design-row .iphone:after {
  border-radius: 50%;
  border: 2px solid #d5d5d5;
  bottom: 1.42%;
  content: "";
  display: block;
  height: 8.85%;
  left: 50%;
  position: absolute;
  width: 16.95%;
  z-index: 5;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.marketing-design-row .view-mobile .heading {
  top: 5.94%;
}
.marketing-design-row .design-row-carousel .item {
  height: auto;
  max-height: 475px;
  overflow: auto;
}
.marketing-design-row .design-row-carousel .at-image-container {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.marketing-design-row .design-row-carousel .scroll-image {
  max-width: 100%;
  width: 100%;
}
.marketing-design-row .view-desktop .design-row-carousel.maintain-size .scroll-image {
  min-width: 600px;
}
.marketing-design-row .carousel-indicators {
  margin: 0;
  width: auto;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  bottom: -50px;
}
.marketing-design-row .carousel-indicators .indicator {
  background: #ffffff;
  border: 0;
  height: 16px;
  margin: 0;
  width: 16px;
}
.marketing-design-row .carousel-indicators .indicator.active {
  background: #009ebb;
}
.marketing-design-row .carousel-indicators .indicator + .indicator {
  margin-left: 8px;
}
.marketing-design-row .carousel-indicators .indicator {
  background: #959595;
}
.marketing-design-row .carousel-indicators .indicator.active {
  background: #009ebb;
}
.marketing-design-row .view-mobile .carousel-indicators {
  bottom: calc(-25% -  30px);
}
.marketing-design-row .carousel-control {
  color: #ffffff;
  background-color: #009ebb;
  border-color: transparent;
  box-shadow: none !important;
  -webkit-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  background-image: none;
  height: 60px;
  margin-top: -30px;
  opacity: 1;
  text-shadow: none;
  top: 50%;
  width: 30px;
}
.marketing-design-row .carousel-control:focus,
.marketing-design-row .carousel-control.focus {
  color: #ffffff;
  background-color: #007388;
  border-color: rgba(0, 0, 0, 0);
}
.marketing-design-row .carousel-control:hover {
  color: #ffffff;
  background-color: #007388;
  border-color: rgba(0, 0, 0, 0);
}
.marketing-design-row .carousel-control:active,
.marketing-design-row .carousel-control.active,
.open > .dropdown-toggle.marketing-design-row .carousel-control {
  color: #ffffff;
  background-color: #007388;
  background-image: none;
  border-color: rgba(0, 0, 0, 0);
}
.marketing-design-row .carousel-control:active:hover,
.marketing-design-row .carousel-control.active:hover,
.open > .dropdown-toggle.marketing-design-row .carousel-control:hover,
.marketing-design-row .carousel-control:active:focus,
.marketing-design-row .carousel-control.active:focus,
.open > .dropdown-toggle.marketing-design-row .carousel-control:focus,
.marketing-design-row .carousel-control:active.focus,
.marketing-design-row .carousel-control.active.focus,
.open > .dropdown-toggle.marketing-design-row .carousel-control.focus {
  color: #ffffff;
  background-color: #005564;
  border-color: rgba(0, 0, 0, 0);
}
.marketing-design-row .carousel-control.disabled:hover,
.marketing-design-row .carousel-control[disabled]:hover,
fieldset[disabled] .marketing-design-row .carousel-control:hover,
.marketing-design-row .carousel-control.disabled:focus,
.marketing-design-row .carousel-control[disabled]:focus,
fieldset[disabled] .marketing-design-row .carousel-control:focus,
.marketing-design-row .carousel-control.disabled.focus,
.marketing-design-row .carousel-control[disabled].focus,
fieldset[disabled] .marketing-design-row .carousel-control.focus {
  background-color: #009ebb;
  border-color: transparent;
}
.marketing-design-row .carousel-control .badge {
  color: #009ebb;
  background-color: #ffffff;
}
.marketing-design-row .carousel-control:focus,
.marketing-design-row .carousel-control.focus {
  background-color: #33c3e0;
}
.marketing-design-row .carousel-control:hover {
  background-color: #33c3e0;
}
.marketing-design-row .carousel-control:active,
.marketing-design-row .carousel-control.active,
.open > .dropdown-toggle.marketing-design-row .carousel-control {
  background-color: #33c3e0;
}
.marketing-design-row .carousel-control:active:hover,
.marketing-design-row .carousel-control.active:hover,
.open > .dropdown-toggle.marketing-design-row .carousel-control:hover,
.marketing-design-row .carousel-control:active:focus,
.marketing-design-row .carousel-control.active:focus,
.open > .dropdown-toggle.marketing-design-row .carousel-control:focus,
.marketing-design-row .carousel-control:active.focus,
.marketing-design-row .carousel-control.active.focus,
.open > .dropdown-toggle.marketing-design-row .carousel-control.focus {
  background-color: #33c3e0;
}
.marketing-design-row .carousel-control .at-icon {
  font-size: 30px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.marketing-design-row .carousel-control.left {
  left: -15px;
}
.marketing-design-row .carousel-control.right {
  right: -15px;
}
.marketing-design-row.view-desktop .view-wrapper-desktop {
  height: auto;
}
.marketing-design-row.view-desktop .view-desktop {
  opacity: 1;
  visibility: visible;
}
.marketing-design-row.view-mobile .view-wrapper-phone {
  height: auto;
}
.marketing-design-row.view-mobile .view-mobile {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 480px) {
  .design-detail .hero-container .hero-image .heading .view-type {
    font-size: 13px;
  }
}
@media (min-width: 768px) {
  .design-detail .hero-container .hero-image .heading .view-type {
    font-size: 14px;
  }
  .marketing-design-row .design-row-container {
    margin-top: -200px;
  }
  .marketing-design-row .design-row-container > .container {
    height: 740px;
    padding: 0 60px;
  }
  .marketing-design-row .design-row-container .view-wrapper {
    left: 60px;
    width: calc(100% - 120px);
  }
  .marketing-design-row .view-desktop .heading {
    font-size: 20px;
  }
  .marketing-design-row .design-row-carousel .item {
    max-height: 600px;
  }
  .marketing-design-row .carousel-control {
    height: 60px;
    margin-top: 0;
    top: 94px;
    width: 60px;
  }
  .marketing-design-row .carousel-control .at-icon {
    font-size: 36px;
  }
  .marketing-design-row .carousel-control.left {
    left: -60px;
  }
  .marketing-design-row .carousel-control.right {
    right: -60px;
  }
}
.legal {
  max-width: 768px;
}
.legal h3,
.legal h4 {
  letter-spacing: normal;
  font-weight: 600;
}
.legal h3 {
  font-size: 18px;
}
.legal h4 {
  font-size: 16px;
}
.legal p + h3,
.legal p + h4,
.legal ul + h3,
.legal ul + h4,
.legal .indent-section + h3,
.legal .indent-section + h4 {
  padding-top: 10px;
}
.legal .indent-section {
  margin-left: 2em;
}
.legal.terms h3 {
  text-transform: uppercase;
}
